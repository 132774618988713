/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Subscription = {
  active?: boolean;
  paymentId?: string;
  interval?: Subscription.interval;
  start?: string;
  ends?: boolean;
};

export namespace Subscription {
  export enum interval {
    MONTH = "month",
    YEAR = "year",
  }
}
