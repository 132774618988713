import { ThemeOptions, createTheme } from "@mui/material/styles";
import { Shadows } from "@mui/material/styles/shadows";

const themeOptions: ThemeOptions = {
  shadows: Array(25).fill("none") as Shadows,
  palette: {
    primary: {
      main: "#1565D8",
      dark: "#3E8DFF",
      contrastText: "#FFF",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
  },
  typography: {
    allVariants: {
      color: "#696F79",
    },
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "36px",
      fontWeight: 700,
      color: "#373636",
    },
    h2: {
      fontSize: "24px",
      fontWeight: 700,
      color: "#373636",
    },
    h3: {
      fontSize: "18px",
      fontWeight: 400,
      color: "#373636",
    },
    h4: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#373636",
    },
    h5: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#373636",
    },
    h6: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#373636",
    },
    body2: {
      fontSize: "14px",
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          overflow: "hidden",
          pb: "0.5px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#F5F5F5",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "16px",
        },
      },
      defaultProps: {
        size: "small",
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: "small",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFab: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          ".MuiMenu-paper": {
            border: "1px solid #c4c4c4",
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: "dense",
      },
      styleOverrides: {
        root: {
          fontSize: "14px",
          backgroundColor: "#fff",
          "&:placeholder": {
            fontSize: "14px",
          },
          ".MuiSelect-select p": {
            fontSize: "14px",
          },
          ".MuiSelect-select": {
            padding: "16px 14px",
          },
          "&.Mui-focused": {
            boxShadow: "0px 4px 10px 3px #0000001C",
          },
          "&.Mui-disabled": {
            backgroundColor: "#f9f8f5",
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiRadio: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: "sm",
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiList: {
      defaultProps: {
        dense: false,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // TODO: most likely this gets overriden by some styles from MUIv4
        // try removing when MUIv4 is gone
        endAdornment: {
          top: "inherit",
        },
        // TODO: remove when this gets merged https://github.com/mui/material-ui/issues/28465
        root: {
          ".MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            paddingRight: "39px",
            // or padding: theme.spacing(X) if you want to be more precise & already defined your theme
          },
        },
        paper: {
          marginTop: "2px",
          border: "1px solid #C5C3C5",
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
