import { Paper } from "@mui/material";
import React from "react";

import StorageHeader from "./StorageHeader/StorageHeader";
import StorageMedia from "./StorageMedia/StorageMedia";

const AdminStorage = () => {
  return (
    <Paper>
      <StorageHeader />
      <StorageMedia />
    </Paper>
  );
};

export default AdminStorage;
