import { Box, ButtonBase, Divider, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { UsersType } from "api/users/types";
import UserAvatar from "components/UserAvatar";
import { getUserRole } from "utils/user/userRole";

const User: FC<UsersType> = (user) => {
  const history = useHistory();
  const { _id, name } = user;
  return (
    <>
      <Stack
        component={ButtonBase}
        onClick={() => {
          history.push(`/user/${_id}`);
        }}
        sx={{ px: 3, pt: 2, pb: 1.5 }}
        direction="row"
        spacing={1}
        width="100%"
        justifyContent="start"
      >
        <UserAvatar
          name={name}
          size={40}
          sx={{
            textAlign: "center",
            backgroundColor: (theme) => theme.palette.primary.main,
            mr: 1,
          }}
        />
        <Box sx={{ textAlign: "left" }}>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: 1.2,
              mb: 0.5,
              color: "#000",
              fontWeight: "bold",
            }}
          >
            {user.name}
          </Typography>
          <Typography
            sx={{
              lineHeight: 1.2,
            }}
            variant="body2"
          >
            {getUserRole(user)}
          </Typography>
        </Box>
      </Stack>
      <Divider sx={{ mx: 3 }} />
    </>
  );
};

export default User;
