import { AppBar, Box, LinearProgress, Stack, Toolbar } from "@mui/material";
// COMPONENTS:
import { useIsFetching } from "@tanstack/react-query";
import React from "react";

import UserDropdown from "components/layouts/DefaultLayout/Topbar/UserDropdown";

interface TopbarProps {
  isMobile: boolean;
}

const Topbar = ({ isMobile }: TopbarProps) => {
  const isFetching = useIsFetching();

  return (
    <AppBar
      color="inherit"
      position={isMobile ? "fixed" : "sticky"}
      elevation={0}
      sx={{
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
      }}
    >
      <Toolbar disableGutters>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            px: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: (theme) => theme.palette.primary.main,
              fontWeight: "bold",
            }}
          ></Box>

          <Stack direction="row" alignItems="center">
            <Box>
              <UserDropdown dark />
            </Box>
          </Stack>
        </Box>
      </Toolbar>
      {isFetching ? (
        <LinearProgress color="primary" />
      ) : (
        <Box height="4px" sx={{ background: "#f5f5f5" }} />
      )}
    </AppBar>
  );
};

export default Topbar;
