import { People as PeopleIcon } from "@mui/icons-material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import TableHeader from "components/Table/components/TableHeader";

// UI LOGIC:
// import { Button } from "@mui/material";
// import { PartnersType } from "api/partners/types";
// import { useModal } from "utils/hooks/useModal";
// import NewPartnerModal from "../UsersTable/components/NewPartnerModal";
// import { hasPermissions } from "components/stores/UserStore";

const UsersHeader: FC = () => {
  // const { isOpen, handleClose, handleOpen } = useModal<PartnersType>();

  return (
    <>
      <TableHeader
        icon={PeopleIcon}
        label={<FormattedMessage id="ADMIN_USERS.TABLE.TITLE" />}
      ></TableHeader>
    </>
  );
};

export default UsersHeader;
