import { ArrowBackIos as ArrowBackIosIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

interface IBackButton {
  onClick?: () => void;
}
const BackButton: FC<IBackButton> = ({ onClick }) => {
  const history = useHistory();

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ cursor: "pointer" }}
      onClick={() => (onClick ? onClick() : history.goBack())}
    >
      <ArrowBackIosIcon sx={{ fontSize: "15px", color: "#6A6C71" }} />
      <Typography>
        <FormattedMessage id="REGISTER.FORM.BACK" />
      </Typography>
    </Stack>
  );
};

export default BackButton;
