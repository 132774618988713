import { Paper, Typography } from "@mui/material";
import React from "react";

const AdsBanner = () => {
  return (
    <Paper
      sx={{
        backgroundColor: "#e6e6e6",
        height: "270px",
        mb: 2,
        display: "flex;",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{ color: "#fff", fontSize: "60px", opacity: 0.8 }}
        fontWeight="bold"
      >
        Ad
      </Typography>
    </Paper>
  );
};

export default AdsBanner;
