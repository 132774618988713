import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Formik, Form as FormikForm } from "formik";
import { FC } from "react";
import * as yup from "yup";

import FormikInputField from "components/forms/FormikInputField";

type PrivacySettingsFormValues = {};

interface IPrivacySettingsForm {
  initialValues?: PrivacySettingsFormValues;
  submitStatus?: MutationStatus;
  handleSubmit: (values: PrivacySettingsFormValues) => void;
}

const PrivacySettingsForm: FC<IPrivacySettingsForm> = ({
  initialValues = {
    password: "",
    newPassword: "",
  },
  submitStatus,
  handleSubmit,
}) => {
  const schema = yup.object({
    password: yup.string().required().min(4),
    newPassword: yup.string().required().min(4),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        <FormikForm>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <FormikInputField
                type="password"
                labelTitle="Old Password"
                placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;"
                name="password"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikInputField
                type="password"
                labelTitle="New Password"
                placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;"
                name="newPassword"
              />
            </Grid>
          </Grid>
          <LoadingButton
            sx={{ py: 1, px: 4, mt: 3 }}
            type="submit"
            variant="contained"
            loading={false}
          >
            Save
          </LoadingButton>
        </FormikForm>
      </Formik>
    </>
  );
};

export default PrivacySettingsForm;
