/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Subscription } from "./Subscription";

export type User = {
  /**
   * User ID.
   * @example 507f191e810c19729de860ea
   */
  _id: string;
  /**
   * User Role.
   * @example organisation-owner
   */
  role: User.role;
  /**
   * User Confirmation Status.
   * @example true
   */
  confirmed: boolean;
  /**
   * User Permissions.
   * @example ['read:users', 'write:users']
   */
  permissions: Array<string>;
  /**
   * User Email.
   * @example user@gmail.com
   */
  email: string;
  /**
   * User Password Encrypted.
   */
  password: string;
  /**
   * User Full Name.
   * @example John Johnson
   */
  name: string;
  /**
   * User Followers.
   */
  followers: Array<string>;
  /**
   * User Following.
   */
  following: Array<string>;
  /**
   * User Pinned Posts.
   */
  pinnedPosts?: Array<string>;
  /**
   * User Address.
   */
  address?: {
    /**
     * User Address Street line
     */
    line?: string;
    /**
     * User Address State
     */
    state?: string;
    /**
     * User Address Zip
     */
    zip?: string;
  };
  /**
   * User Phone Number.
   */
  phone?: string;
  /**
   * Company User Administrator.
   */
  companyAdministrator?: string;
  /**
   * Company User Industry.
   */
  industry?: string;
  /**
   * Company User Type.
   */
  companyType?: string;
  /**
   * User Stripe ID.
   */
  stripeId?: string;
  /**
   * User Subscription.
   */
  subscription?: Subscription;
};

export namespace User {
  /**
   * User Role.
   * @example organisation-owner
   */
  export enum role {
    ADMIN = "admin",
    BUSINESS = "business",
  }
}
