import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import BackButton from "containers/register/components/BackButton";

interface IPaymentForm {
  handlePreviousStep: () => void;
  handleLogin: () => void;
}

const PaymentForm: FC<IPaymentForm> = ({ handlePreviousStep, handleLogin }) => {
  return (
    <>
      <Box sx={{ maxWidth: "680px", width: "100%", mb: 4 }}>
        <BackButton onClick={handlePreviousStep} />
      </Box>
      <Box
        sx={{
          maxWidth: "420px",
          width: "100%",
          minHeight: "600px",
        }}
      >
        <Stack direction="column" sx={{ height: "100%" }}>
          <Box>
            <Box>
              <Typography variant="h1" sx={{ fontSize: "30px", mb: 2 }}>
                <FormattedMessage id="REGISTER_BUSINESS.PAYMENT_FORM.TITLE" />
              </Typography>
              <Typography sx={{ fontSize: "18px" }}>
                <FormattedMessage id="REGISTER_BUSINESS.PAYMENT_FORM.DESCRIPTION" />
              </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box>
              <Typography>
                Congratulations, your payment was successfull!
              </Typography>
              <Button
                onClick={handleLogin}
                variant="contained"
                fullWidth
                sx={{ py: 1.5, mt: 2 }}
              >
                Login
              </Button>
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default PaymentForm;
