import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { useUserStore } from "components/stores/UserStore";
import UserAvatar from "components/UserAvatar";
import { getUserRole } from "utils/user/userRole";

const UserProfile = () => {
  const [user] = useUserStore((s) => [s.user]);

  return (
    <Paper
      sx={{
        overflow: "hidden",
        mb: 2,
      }}
    >
      <Box
        height="68px"
        sx={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundImage: "url('/media/login-info-bg.png')",
        }}
      ></Box>
      <UserAvatar
        name={user?.name ?? ""}
        size={86}
        sx={{
          mt: "-43px",
          textAlign: "center",
          mx: "auto",
          border: "2px solid #fff",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
        variant="circular"
      />
      <Box sx={{ px: 2, pt: 1 }} textAlign="center">
        <Typography
          sx={{ fontSize: "18px", color: "#000", fontWeight: "bold" }}
        >
          {user?.name}
        </Typography>
        <Typography variant="body2">{user && getUserRole(user)}</Typography>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ py: 1 }}
      >
        {user?.confirmed ? (
          <>
            <CheckCircleIcon
              sx={{
                fontSize: "18px",
                mr: 0.5,
                color: (theme) => theme.palette.primary.main,
              }}
            />
            <Typography variant="body2">Verified</Typography>
          </>
        ) : (
          <Typography variant="body2">Unverified</Typography>
        )}
      </Stack>

      <Divider sx={{ mt: 1 }} />
      <Box sx={{ px: 2, py: 1.5 }}>
        <Stack
          component={RouterLink}
          to={`/user/${user?._id}/followers`}
          direction="row"
          justifyContent="space-between"
          sx={{ textDecoration: "none" }}
        >
          <Typography variant="body2">Followers</Typography>
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {user?.followers.length}
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box sx={{ px: 2, py: 1.5 }}>
        <Stack
          component={RouterLink}
          to={`/user/${user?._id}/following`}
          direction="row"
          justifyContent="space-between"
          sx={{ textDecoration: "none" }}
        >
          <Typography variant="body2">Following</Typography>
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {user?.following.length}
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box sx={{ px: 2, py: 1.5 }}>
        <Stack
          component={RouterLink}
          to={`/pinned-posts`}
          direction="row"
          justifyContent="space-between"
          sx={{ textDecoration: "none" }}
        >
          <Typography variant="body2">Pinned Posts</Typography>
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {user?.pinnedPosts?.length}
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box sx={{ px: 2, py: 1.5 }}>
        <Stack
          component={RouterLink}
          to={`/recent-submissions`}
          direction="row"
          justifyContent="space-between"
          sx={{ textDecoration: "none" }}
        >
          <Typography variant="body2">Recent Submissions</Typography>
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {user?.recentSubmissions}
          </Typography>
        </Stack>
      </Box>
    </Paper>
  );
};

export default UserProfile;
