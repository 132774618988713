import { Grid, Paper } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import { User as UserGenerated } from "generated-types";

import UserProfile from "../UserProfile";
import UserProfileLoading from "../UserProfileLoading";
import { useUser } from "../useUser";
import NotificationSettingsForm from "./components/NotificationSettingsForm";
import SettingsTabs from "./SettingsTabs";

const NotificationSettings = () => {
  const { userId } = useParams<{ userId: string }>();
  const { isMyProfile, user, isLoading } = useUser(userId);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* Users about */}
        {isLoading ? (
          <UserProfileLoading />
        ) : (
          user && (
            <UserProfile
              activeTab="settings"
              {...user}
              isMyProfile={isMyProfile}
            />
          )
        )}
      </Grid>

      <Grid item xs={12} md={4} lg={3}>
        <SettingsTabs
          activeTab="notification-settings"
          userId={user?._id || ""}
          isBusinessUser={user?.role === UserGenerated.role.BUSINESS}
        />
      </Grid>

      <Grid item xs={12} md={8} lg={9}>
        <Paper sx={{ py: 2, px: 2 }}>
          <NotificationSettingsForm handleSubmit={() => {}} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default NotificationSettings;
