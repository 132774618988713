import {
  AttachFile as AttachFileIcon,
  CameraAlt as CameraAltIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Image as ImageIcon,
  Mic as MicIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import FormikCheckboxField from "components/forms/FormikCheckboxField";
import FormikInputField from "components/forms/FormikInputField";
import FormikSelectField from "components/forms/FormikSelectField";
import SearchLocationModal from "components/layouts/DefaultLayout/Topbar/SearchLocationModal";
import { useUserStore } from "components/stores/UserStore";
import UserAvatar from "components/UserAvatar";
import { renderMedia } from "utils/media/renderMedia";
import { getUserRole } from "utils/user/userRole";

import CreatePostAttachment from "./CreatePostAttachment";
import { useCreatePost } from "./useCreatePost";

interface ICreatePostDefault {
  setVariant: Dispatch<SetStateAction<"default" | "new-post">>;
}

const CreatePostDefault: FC<ICreatePostDefault> = ({ setVariant }) => {
  const [user] = useUserStore((s) => [s.user]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ py: 1, px: 2 }}
      spacing={2}
    >
      <UserAvatar
        name={user?.name ?? ""}
        size={40}
        sx={{
          textAlign: "center",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      />

      <Button
        variant="text"
        fullWidth
        sx={{
          backgroundColor: "#EEE",
          border: "none",
          borderRadius: 2,
          justifyContent: "start",
          px: 1.5,
          fontSize: "14px",
          py: "9px",
          color: "#969797",
          fontWeight: "400",
          "&:hover": {
            backgroundColor: "#e6e6e6",
          },
        }}
        onClick={() => setVariant("new-post")}
      >
        Write new post
      </Button>
    </Stack>
  );
};

interface ICreatePostNewPost {
  setVariant: Dispatch<SetStateAction<"default" | "new-post">>;
}
const CreatePostNewPost: FC<ICreatePostNewPost> = ({ setVariant }) => {
  const {
    categoriesOptions,
    interactionsOptions,
    user,
    initialValues,
    schema,
    handleSubmit,
    isOpenLocationSearch,
    handleCloseLocationSearch,
    handleOpenLocationSearch,
    location,
    setLocation,
    files,
    setFiles,
  } = useCreatePost(setVariant);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const { values, setFieldValue } = formik;

  return (
    <Box sx={{ px: 2 }}>
      <SearchLocationModal
        open={isOpenLocationSearch}
        handleClose={handleCloseLocationSearch}
        setLocation={(location) => {
          setLocation(location);
        }}
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 1 }}
      >
        <Typography sx={{ fontWeight: "bold" }}>New Post</Typography>
        <IconButton onClick={() => setVariant("default")}>
          <CloseIcon sx={{ color: "#696F79" }} />
        </IconButton>
      </Stack>
      <Divider />
      <Stack direction="row" sx={{ py: 2 }} spacing={2}>
        <UserAvatar
          name={user?.name ?? ""}
          size={40}
          sx={{
            textAlign: "center",
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        />
        <FormikProvider value={formik}>
          <FormikForm style={{ width: "100%" }}>
            <Box width="100%">
              <Stack
                direction="row"
                alignItems="start"
                justifyContent="space-between"
                sx={{ mb: 1.5 }}
              >
                <Box>
                  <Typography sx={{ fontSize: "16px", color: "#000" }}>
                    {user?.name}
                  </Typography>
                  <Typography variant="body2">
                    {user && getUserRole(user)}
                  </Typography>
                </Box>
                <Box sx={{ maxWidth: "60%" }} textAlign="right">
                  {location.address ? (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#2F80ED",
                        cursor: "pointer",
                      }}
                      onClick={handleOpenLocationSearch}
                    >
                      {location.address}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#000",
                        mt: 0.5,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={handleOpenLocationSearch}
                    >
                      Add location
                    </Typography>
                  )}
                </Box>
              </Stack>

              <Box>
                <FormikSelectField
                  name="category"
                  placeholder="Category"
                  sx={{
                    py: 0,
                    width: "100%",
                    backgroundColor: "#EEE",
                    border: "none",
                    borderRadius: 2,
                    "&.Mui-focused": {
                      boxShadow: "none",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    ".MuiInputBase-input": {
                      border: "none",
                      py: "10px",
                    },
                  }}
                  options={categoriesOptions}
                  onChange={(e, value) => {
                    //@ts-expect-error
                    setFieldValue("category", value.props.value);
                    setFieldValue("interaction", undefined);
                  }}
                />
              </Box>

              {(values.category === "employee-interaction" ||
                values.category === "customer-interaction") && (
                <>
                  <Box>
                    <FormikSelectField
                      name="interaction"
                      placeholder="Interaction"
                      sx={{
                        py: 0,
                        width: "100%",
                        backgroundColor: "#EEE",
                        border: "none",
                        borderRadius: 2,
                        "&.Mui-focused": {
                          boxShadow: "none",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        ".MuiInputBase-input": {
                          border: "none",
                          py: "10px",
                        },
                      }}
                      options={interactionsOptions}
                    />
                  </Box>
                </>
              )}
              {values.category === "employee-interaction" && (
                <Divider
                  sx={{
                    bgcolor: "transparent",
                    fontSize: "14px",
                    lineHeight: 2,
                  }}
                >
                  Employee Details
                </Divider>
              )}
              {values.category === "customer-interaction" && (
                <Divider
                  sx={{
                    bgcolor: "transparent",
                    fontSize: "14px",
                    lineHeight: 2,
                  }}
                >
                  Customer Details
                </Divider>
              )}

              {(values.category === "employee-interaction" ||
                values.category === "customer-interaction") && (
                <Grid container spacing={1} sx={{ mt: 0.5 }}>
                  <Grid item xs={12}>
                    <FormikInputField
                      name="participant.name"
                      placeholder="Name"
                      sx={{
                        py: 0,
                        width: "100%",
                        backgroundColor: "#EEE",
                        border: "none",
                        borderRadius: 2,
                        "&.Mui-focused": {
                          boxShadow: "none",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        ".MuiInputBase-input": {
                          border: "none",
                          py: "10px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormikInputField
                      name="participant.address"
                      placeholder="Address"
                      sx={{
                        py: 0,
                        width: "100%",
                        backgroundColor: "#EEE",
                        border: "none",
                        borderRadius: 2,
                        "&.Mui-focused": {
                          boxShadow: "none",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        ".MuiInputBase-input": {
                          border: "none",
                          py: "10px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormikInputField
                      name="participant.city"
                      placeholder="City"
                      sx={{
                        py: 0,
                        width: "100%",
                        backgroundColor: "#EEE",
                        border: "none",
                        borderRadius: 2,
                        "&.Mui-focused": {
                          boxShadow: "none",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        ".MuiInputBase-input": {
                          border: "none",
                          py: "10px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormikInputField
                      name="participant.state"
                      placeholder="State"
                      sx={{
                        py: 0,
                        width: "100%",
                        backgroundColor: "#EEE",
                        border: "none",
                        borderRadius: 2,
                        "&.Mui-focused": {
                          boxShadow: "none",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        ".MuiInputBase-input": {
                          border: "none",
                          py: "10px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormikInputField
                      name="participant.zip"
                      placeholder="ZIP"
                      sx={{
                        py: 0,
                        width: "100%",
                        backgroundColor: "#EEE",
                        border: "none",
                        borderRadius: 2,
                        "&.Mui-focused": {
                          boxShadow: "none",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        ".MuiInputBase-input": {
                          border: "none",
                          py: "10px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikCheckboxField
                      name="recommend"
                      label="Would Recommend"
                      onChange={(e, value) => {
                        setFieldValue("recommend", value);
                      }}
                      controlProps={{
                        sx: {
                          "& .MuiTypography-root": {
                            fontSize: "14px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              )}

              <Box sx={{ mt: 1.5, mb: 1.5 }}>
                <FormikInputField
                  name="content"
                  placeholder="Details of incident"
                  sx={{
                    py: 0,
                    width: "100%",
                    backgroundColor: "#EEE",
                    border: "none",
                    borderRadius: 2,
                    "&.Mui-focused": {
                      boxShadow: "none",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    ".MuiInputBase-input": {
                      border: "none",
                      py: "10px",
                    },
                  }}
                  minRows={6}
                  multiline
                />
              </Box>

              <ImageList
                variant="masonry"
                cols={2}
                sx={{
                  width: "480px",
                  maxWidth: "100%",
                  height: "100%",
                  // maxHeight: "500px",
                }}
              >
                {files.map((file, index) => (
                  <ImageListItem
                    key={file.name}
                    sx={{
                      border: "2px solid #EDEDED",
                    }}
                  >
                    {renderMedia(file)}
                    <ImageListItemBar
                      position="bottom"
                      title={file.name}
                      subtitle={file.type}
                      actionIcon={
                        <IconButton
                          color="error"
                          aria-label={`delete item ${file.name}`}
                          onClick={() => {
                            const newFiles = [...files];
                            newFiles.splice(index, 1);

                            setFiles(newFiles);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                      sx={{
                        alignItems: "center",
                        backgroundColor: "#ededed",
                        ".MuiImageListItemBar-title": {
                          color: "#000",
                        },
                        ".MuiImageListItemBar-subtitle": {
                          lineHeight: 1.2,
                          color: (theme) => theme.palette.primary.main,
                        },
                      }}
                    />
                  </ImageListItem>
                ))}
              </ImageList>

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="end"
              >
                <Box>
                  <Typography variant="body2">Attach</Typography>
                  <Stack direction="row" sx={{ mt: 1 }} spacing={1}>
                    <CreatePostAttachment
                      title="Images"
                      icon={ImageIcon}
                      setFiles={setFiles}
                      accept="image/*"
                    />
                    <CreatePostAttachment
                      title="Video"
                      icon={CameraAltIcon}
                      setFiles={setFiles}
                      accept="video/*"
                    />
                    <CreatePostAttachment
                      title="Audio"
                      icon={MicIcon}
                      setFiles={setFiles}
                      accept="audio/*"
                    />
                    <CreatePostAttachment
                      title="Doc"
                      icon={AttachFileIcon}
                      setFiles={setFiles}
                      accept=".pdf,.doc,.docx,.xml"
                    />
                  </Stack>
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ fontSize: "14px", borderRadius: "20px", py: 0, px: 3 }}
                >
                  Post
                </Button>
              </Stack>
            </Box>
          </FormikForm>
        </FormikProvider>
      </Stack>
    </Box>
  );
};

const CreatePost = () => {
  const history = useHistory();
  const { hash } = history.location;
  const [variant, setVariant] = useState<"default" | "new-post">("default");

  useEffect(() => {
    if (hash === "#createpost") {
      setVariant("new-post");
      history.push({
        ...history.location,
        hash: "",
      });
    }
  }, [hash, history]);

  const variantsDisplay = {
    default: <CreatePostDefault setVariant={setVariant} />,
    "new-post": <CreatePostNewPost setVariant={setVariant} />,
  };

  return <Paper id="createpost">{variantsDisplay[variant]}</Paper>;
};

export default CreatePost;
