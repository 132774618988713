import {
  Box,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const UserStatsLoading = () => {
  return (
    <Paper>
      <Box sx={{ px: 2, py: 1.5 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ textDecoration: "none" }}
        >
          <Typography variant="body2">Followers</Typography>
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            <Skeleton width="15px" />
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box sx={{ px: 2, py: 1.5 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ textDecoration: "none" }}
        >
          <Typography variant="body2">Following</Typography>
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            <Skeleton width="15px" />
          </Typography>
        </Stack>
      </Box>
    </Paper>
  );
};

export default UserStatsLoading;
