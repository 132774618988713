import request, { APICall } from "../request";

export const getLocation = (
  address: string
): APICall<{ data: { lat: number; lng: number } }> =>
  request({
    url: `/location`,
    params: {
      address,
    },
    method: "GET",
  });

export const getSearchLocation = (
  address: string
): APICall<{
  data: { address: string; location: { lat: number; lng: number } }[];
}> =>
  request({
    url: `/location/search`,
    params: {
      address,
    },
    method: "GET",
  });

export const getCordinatesAddress = (
  lat: string | number,
  lng: string | number
): APICall<{ data: string }> =>
  request({
    url: `/location/address`,
    params: {
      lat,
      lng,
    },
    method: "GET",
  });
