import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  Typography,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { PopupState as IPopupState } from "material-ui-popup-state/core";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";

interface IUserDropdownItem {
  popupState: IPopupState;
  title: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  link: string;
  isAdminPageLink?: boolean;
}

const UserDropdownItem: FC<IUserDropdownItem> = ({
  popupState,
  title,
  icon: Icon,
  link,
  isAdminPageLink,
}) => {
  const history = useHistory();
  const isAdminPage = history.location.pathname.includes("/admin");

  if (isAdminPageLink && !isAdminPage) return null;
  if (isAdminPageLink === false && isAdminPage) return null;

  return (
    <ListItem
      disablePadding
      onClick={() => {
        history.push(link);
        popupState.close();
      }}
      key={title}
    >
      <ListItemButton>
        <ListItemIcon sx={{ minWidth: "40px" }}>
          <Icon />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2" sx={{ textAlign: "left" }}>
            {title}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default UserDropdownItem;
