import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";

import UserAvatar from "components/UserAvatar";

const UserProfileLoading = () => {
  return (
    <Paper sx={{ position: "relative", px: 5, py: 3 }}>
      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          height: "50%",
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundImage: "url('/media/login-info-bg.png')",
        }}
      ></Box>

      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="end" spacing={1.5}>
          <UserAvatar
            name={""}
            size={120}
            sx={{
              textAlign: "center",
              backgroundColor: (theme) => theme.palette.primary.main,
              border: "2px solid #fff",
            }}
          />
          <Box>
            <Typography
              sx={{
                fontSize: "20px",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              <Skeleton width="100px" />
            </Typography>
            <Typography sx={{ mb: 0.5, lineHeight: 1.2 }}>
              <Skeleton width="80px" />
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default UserProfileLoading;
