import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { Box, Stack, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React, { FC, FunctionComponent, ReactNode, SVGProps } from "react";
import { Link as RouterLink } from "react-router-dom";

import { ReactComponent as HexagonIcon } from "assets/icons/hexagon.svg";

interface IRegisterLinkIcon {
  onClick?: () => void;
  link?: string;
  title: ReactNode | string;
  description: ReactNode | string;
  icon:
    | FunctionComponent<
        SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >
    | (OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
      });
}

const RegisterLinkIcon: FC<IRegisterLinkIcon> = ({
  onClick,
  link,
  title,
  description,
  icon: Icon,
}) => {
  return (
    <Stack
      component={link ? RouterLink : "div"}
      to={link}
      onClick={onClick}
      direction="row"
      sx={{
        boxShadow: "0px 2px 14px 1px #0000000F",
        mb: 3,
        py: 3,
        px: 4,
        borderRadius: 2,
        textDecoration: "none",
        border: "1px solid transparent",
        cursor: "pointer",
        transition:
          "0.2s ease-in-out background-color, 0.2s ease-in-out border-color",
        "&:hover": {
          borderColor: "#1565D8",
          backgroundColor: "#f5f9ff",
        },
        "&:hover .arrow svg": {
          transform: "translateX(0)",
        },
        "&:hover .arrow": {
          opacity: 1,
        },
        "&:hover .svg-outline": {
          fill: "#1565D8",
        },
        "&:hover .svg-icon": {
          color: "#fff",
        },
      }}
      alignItems="center"
    >
      <Box
        sx={{
          position: "relative",
          mr: 3,
          ".svg-outline": {
            transition: "0.2s ease-in-out fill",
            fill: "white",
          },
        }}
      >
        <HexagonIcon className="svg-outline" width="52" height="52" />
        <Icon
          className="svg-icon"
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            color: "#1565D8",
          }}
        />
      </Box>
      <Box>
        <Typography sx={{ color: "#000000" }}>{title}</Typography>
        <Typography variant="body2">{description}</Typography>
      </Box>
      <Box
        className="arrow"
        sx={{
          opacity: 0,
          transition: "0.2s ease-in-out opacity",
          svg: {
            transition: "0.2s ease-in-out transform",
            transform: "translateX(-5px)",
          },
        }}
      >
        <ArrowForwardIcon sx={{ color: "#1565D8" }} />
      </Box>
    </Stack>
  );
};

export default RegisterLinkIcon;
