import { useQuery } from "@tanstack/react-query";

import { getNotifications } from "api/notifications";
import { notificationsKeys } from "api/notifications/queries";
import { NotificationsType } from "api/notifications/types";
import { QueryParamsType } from "api/utils";

export const useNotifications = () => {
  const notificationsFilter: QueryParamsType<NotificationsType> = {
    filter: [
      {
        field: "opened",
        operator: "eq",
        value: "false",
      },
    ],
    sort: {
      field: "createdAt",
      order: "desc",
    },
    limit: 4,
  };

  const { data: notificationsData } = useQuery(
    notificationsKeys.listFiltered(notificationsFilter),
    async () => {
      const { data: res } = await getNotifications(notificationsFilter);
      return res;
    }
  );

  return {
    notifications: notificationsData?.data,
    notificationsCount: notificationsData?.meta.pagination.totalResults,
  };
};
