import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import { NotificationsType } from "./types";

export const getNotifications = (
  queryParams?: QueryParamsType<NotificationsType>
): APICall<Paginated<NotificationsType[]>> =>
  request({
    url: `/notification${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });
