import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";

import UserAvatar from "components/UserAvatar";

const UserLoading = () => {
  return (
    <>
      <Stack
        sx={{ px: 3, pt: 2, pb: 1.5 }}
        direction="row"
        spacing={1}
        width="100%"
        justifyContent="start"
      >
        <UserAvatar
          name={""}
          size={40}
          sx={{
            textAlign: "center",
            backgroundColor: (theme) => theme.palette.primary.main,
            mr: 1,
          }}
        />
        <Box sx={{ textAlign: "left" }}>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: 1.2,
              mb: 0.5,
              color: "#000",
              fontWeight: "bold",
            }}
          >
            <Skeleton width="80px" />
          </Typography>
          <Typography
            sx={{
              lineHeight: 1.2,
            }}
            variant="body2"
          >
            <Skeleton width="50px" />
          </Typography>
        </Box>
      </Stack>
      <Divider sx={{ mx: 3 }} />
    </>
  );
};

export default UserLoading;
