import MUICodeInput from "react-verification-code-input";

type Props = {
  type?: "text" | "number";
  onChange?: (val: string) => void;
  onComplete?: (val: string) => void;
  fields?: number;
  loading?: boolean;
  title?: string;
  fieldWidth?: number;
  fieldHeight?: number;
  autoFocus?: boolean;
  className?: string;
  values?: string[];
  disabled?: boolean;
  required?: boolean;
  placeholder?: string[];
};

const VerificationCodeField = ({ ...rest }: Props) => {
  return (
    <>
      <MUICodeInput className="verification-code" fields={4} {...rest} />
    </>
  );
};

export default VerificationCodeField;
