import {
  Box,
  Collapse,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";

import { SearchTypes } from "components/layouts/DefaultLayout/Topbar/Search/useSearchSuggestion";
import Post from "containers/dashboard/components/Post/Post";

import { useSearch } from "./useSearch";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Search = () => {
  const query = useQuery();
  const {
    q,
    type,
    // location, radius
  } = {
    q: query.get("q"),
    type: query.get("type"),
    // location: query.get("location"),
    // radius: query.get("radius"),
  };

  const { posts, searchType, setSearchType } = useSearch(
    q || "",
    type as SearchTypes | null
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2} lg={3}></Grid>
      <Grid item xs={12} md={8} lg={6}>
        <Stack direction="column" spacing={2}>
          <Paper sx={{ py: 3 }}>
            <Typography variant="h2" sx={{ mx: 3 }}>
              Search {q ? `"${q}"` : ""}
            </Typography>
          </Paper>
          <Paper>
            <Tabs
              value={searchType}
              onChange={(e, value) => {
                setSearchType(value);
              }}
            >
              {Object.values(SearchTypes).map((type, index) => (
                <Tab
                  key={index}
                  label={type}
                  value={type}
                  sx={{ textTransform: "capitalize" }}
                />
              ))}
            </Tabs>
          </Paper>

          <Box>
            <Stack component={TransitionGroup} spacing={2}>
              {posts?.map((post) => (
                <Collapse key={post._id}>
                  <Post {...post} />
                </Collapse>
              ))}
            </Stack>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Search;
