import { UsersType } from "api/users/types";
import { User as GeneratedUser } from "generated-types";

export const getUserRole = (user: UsersType) => {
  // if (user.role === GeneratedUser.role.PARTICIPANT) {
  //   return "Participant";
  // } else
  if (user.role === GeneratedUser.role.BUSINESS) {
    return user.companyType || "Business";
  } else if (user.role === GeneratedUser.role.ADMIN) {
    return "Admin";
  }
};
