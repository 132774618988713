import { Grid, Paper } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";

import { updateProfile as updateProfileAPI } from "api/user";
import { userKeys } from "api/user/queries";
import { User as UserGenerated } from "generated-types";

import UserProfile from "../UserProfile";
import UserProfileLoading from "../UserProfileLoading";
import { useUser } from "../useUser";
import GeneralSettingsForm, {
  GeneralSettingsFormValues,
} from "./components/GeneralSettingsForm";
import SettingsTabs from "./SettingsTabs";

const UserSettings = () => {
  const { userId } = useParams<{ userId: string }>();
  const { isMyProfile, user, isLoading } = useUser(userId);
  const queryClient = useQueryClient();

  const { mutateAsync: updateProfile, status } = useMutation(
    async (data: GeneralSettingsFormValues) => {
      const res = await updateProfileAPI(data);
      await queryClient.invalidateQueries(userKeys.me());
      return res.data;
    }
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* Users about */}
        {isLoading ? (
          <UserProfileLoading />
        ) : (
          user && (
            <UserProfile
              activeTab="settings"
              {...user}
              isMyProfile={isMyProfile}
            />
          )
        )}
      </Grid>

      <Grid item xs={12} md={4} lg={3}>
        <SettingsTabs
          activeTab={"general-settings"}
          userId={user?._id || ""}
          isBusinessUser={user?.role === UserGenerated.role.BUSINESS}
        />
      </Grid>

      <Grid item xs={12} md={8} lg={9}>
        <Paper sx={{ py: 2, px: 2 }}>
          <GeneralSettingsForm
            initialValues={{
              name: user?.name || "",
              email: user?.email || "",
              role: user?.role || null,
              phone: user?.phone || "",
              line: user?.address?.line,
              state: user?.address?.state,
              zip: user?.address?.zip,
              // @ts-expect-error
              lat: user?.address?.location?.lat,
              // @ts-expect-error
              lng: user?.address?.location?.lng,
            }}
            handleSubmit={updateProfile}
            submitStatus={status}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UserSettings;
