import { Collapse, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { TransitionGroup } from "react-transition-group";

import Post from "containers/dashboard/components/Post/Post";
import UserPostLoading from "containers/dashboard/components/Post/PostLoading";

import { useRecentSubmissions } from "./useRecentSubmissions";

const RecentSubmissions = () => {
  const { recentSubmissions, isLoading } = useRecentSubmissions();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2} lg={3}></Grid>
      <Grid item xs={12} md={8} lg={6}>
        <Stack spacing={2}>
          <Paper sx={{ py: 3 }}>
            <Typography variant="h2" sx={{ mx: 3 }}>
              Recent Submissions
            </Typography>
          </Paper>
          {isLoading ? (
            <Stack spacing={2}>
              <UserPostLoading />
              <UserPostLoading />
            </Stack>
          ) : (
            <Stack component={TransitionGroup} spacing={2}>
              {recentSubmissions?.map((post) => (
                <Collapse key={post._id}>
                  <Post {...post} />
                </Collapse>
              ))}
            </Stack>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} md={2} lg={3}></Grid>
    </Grid>
  );
};

export default RecentSubmissions;
