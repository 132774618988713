import {
  MoreHoriz as MoreHorizIcon,
  Replay as ReplayIcon,
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import PopupState, { bindTrigger } from "material-ui-popup-state";
import React, { FC, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";

import { CommentsType } from "api/comments/types";
import UserAvatar from "components/UserAvatar";

import CommentReply from "./CommentReply";
import CommentPopoverOwner from "./components/CommentPopoverOwner";
import PostCommentReply from "./components/PostCommentReply";
import { useComment } from "./useComment";

dayjs.extend(relativeTime);

interface IComment extends CommentsType {}

const Comment: FC<IComment> = (comment) => {
  const [openReply, setOpenReply] = useState<boolean>(false);
  const { _id, user: commentUser, content, replies, createdAt } = comment;
  const { isMyComment } = useComment(comment);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        sx={{ position: "relative", mt: 1 }}
      >
        <Box
          component={RouterLink}
          to={`/user/${commentUser?._id}`}
          sx={{ textDecoration: "none" }}
        >
          <UserAvatar
            name={commentUser.name}
            size={42}
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              // right: isMyComment ? "calc(90% - 15px)" : "calc(95% - 15px)",
              right: "calc(95% - 15px)",
              width: "42px",
              height: "42px",
              textAlign: "center",
              mx: "auto",
              border: "2px solid #fff",
              backgroundColor: (theme) => theme.palette.primary.main,
            }}
            variant="circular"
          />
        </Box>
        <Box
          sx={{
            // width: isMyComment ? "90%" : "95%",
            width: "95%",
            backgroundColor: isMyComment ? "#EBEFF6" : "#EEEEEE",

            pl: 3,
            pr: 2,
            py: 1.5,
            borderRadius: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
            <Box>
              <Link
                component={RouterLink}
                to={`/user/${commentUser?._id}`}
                variant="body2"
                sx={{
                  color: "#373636",
                  fontWeight: "bold",
                  mb: 0.5,
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {commentUser.name}
              </Link>
              <Typography variant="body2">{content}</Typography>
            </Box>
            <PopupState variant="popover" popupId="sort-by-popover">
              {(popupState) => (
                <Box sx={{ ml: 3 }}>
                  <IconButton
                    sx={{ backgroundColor: "#DDDBDB" }}
                    {...bindTrigger(popupState)}
                  >
                    <MoreHorizIcon
                      sx={{ color: "#A9A9A9", fontSize: "18px" }}
                    />
                  </IconButton>
                  {isMyComment && (
                    <CommentPopoverOwner postId={_id} popupState={popupState} />
                  )}
                </Box>
              )}
            </PopupState>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Stack
              direction="row"
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenReply((p) => !p)}
            >
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  mr: 1,
                  backgroundColor: "#C4C4C4",
                }}
              >
                <ReplayIcon sx={{ fontSize: "14px", color: "#fff" }} />
              </Stack>

              <Typography variant="body2">Reply</Typography>
            </Stack>
            <Box>
              <Typography variant="body2">
                {dayjs(createdAt).fromNow()}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Stack>

      <Stack component={TransitionGroup}>
        {replies?.map((reply) => (
          <Collapse key={reply._id}>
            <CommentReply {...reply} />
          </Collapse>
        ))}
      </Stack>

      {/* Reply */}
      <PostCommentReply commentId={_id} open={openReply} />
    </>
  );
};

export default Comment;
