import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";

import UserAvatar from "components/UserAvatar";
dayjs.extend(relativeTime);

const UserPostLoading = () => {
  return (
    <Box>
      <Paper sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Box sx={{ textDecoration: "none" }}>
              <UserAvatar
                name={""}
                size={40}
                sx={{
                  textAlign: "center",
                  backgroundColor: (theme) => theme.palette.primary.main,
                }}
              />
            </Box>

            <Stack direction="column">
              <Skeleton width="100px" height="20px" />

              <Typography
                variant="body2"
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  lineHeight: 1.2,
                }}
              >
                <Skeleton />
              </Typography>

              <Typography variant="body2" sx={{ lineHeight: 1.2 }}>
                <Skeleton />
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack sx={{ mt: 2 }} spacing={2}>
          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#000",
                fontWeight: "bold",
                mb: 1,
              }}
            >
              What happend?
            </Typography>
            <Typography variant="body2">
              <Skeleton />
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              Details of incident
            </Typography>
            <Box
              sx={{
                fontSize: "14px",
                lineHeight: 1.4,
                fontFamily:
                  '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
                color: "#696F79",
                img: {
                  maxWidth: "100%",
                },
                wordWrap: "break-word",
                // "p:first-child": {
                //   mt: 0,
                // },
              }}
            >
              <Skeleton />
            </Box>
          </Box>
        </Stack>

        <Stack direction="row" justifyContent="space-between" sx={{ mt: 4 }}>
          <Stack direction="row" alignItems="center">
            <Skeleton width="100px" />
          </Stack>

          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography variant="body2" sx={{ mr: 0.5 }}>
              <Skeleton width="100px" />
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default UserPostLoading;
