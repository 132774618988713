import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import {
  downvotePost as downvotePostAPI,
  upvotePost as upvotePostAPI,
} from "api/posts";
import { postsKeys } from "api/posts/queries";
import { PostsType } from "api/posts/types";
import { useUserStore } from "components/stores/UserStore";

export const usePost = ({ _id, votes, user: postUser }: PostsType) => {
  const queryClient = useQueryClient();
  const [user] = useUserStore((s) => [s.user]);

  const { mutateAsync: upvotePost } = useMutation(async (data: string) => {
    const res = await upvotePostAPI(data);
    await queryClient.invalidateQueries(postsKeys.list());
    return res.data;
  });
  const handleUpvotePost = async () => {
    await upvotePost(_id);
  };

  const { mutateAsync: downvotePost } = useMutation(async (data: string) => {
    const res = await downvotePostAPI(data);
    await queryClient.invalidateQueries(postsKeys.list());
    return res.data;
  });
  const handleDownvotePost = async () => {
    await downvotePost(_id);
  };

  const upvoted = useMemo(() => {
    return votes.up.includes(user?._id ?? "");
  }, [votes, user]);
  const downvoted = useMemo(() => {
    return votes.down.includes(user?._id ?? "");
  }, [votes, user]);

  const isMyPost = useMemo(() => {
    return user?._id === postUser._id;
  }, [postUser._id, user?._id]);

  return {
    handleUpvotePost,
    handleDownvotePost,
    upvoted,
    downvoted,
    isMyPost,
  };
};
