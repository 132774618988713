import { Description as DescriptionIcon } from "@mui/icons-material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import TableHeader from "components/Table/components/TableHeader";

// UI LOGIC:
// import { Button } from "@mui/material";
// import { PartnersType } from "api/partners/types";
// import { useModal } from "utils/hooks/useModal";
// import NewPartnerModal from "../PostsTable/components/NewPartnerModal";
// import { hasPermissions } from "components/stores/UserStore";

const StorageHeader: FC = () => {
  // const { isOpen, handleClose, handleOpen } = useModal<PartnersType>();

  return (
    <>
      <TableHeader
        icon={DescriptionIcon}
        label={<FormattedMessage id="ADMIN_STORAGE.TABLE.TITLE" />}
      ></TableHeader>
    </>
  );
};

export default StorageHeader;
