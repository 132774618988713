import { Search as SearchIcon } from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import { getPosts } from "api/posts";
import { postsKeys } from "api/posts/queries";
import { PostsType } from "api/posts/types";
import Table from "components/Table";
import { dateTimeFormatter } from "components/Table/formatters/dateTimeFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import { PostCategoriesLabels } from "containers/dashboard/components/CreatePost/useCreatePost";

import { usePostsFilter } from "./usePostsFilter";

const PostsTable: FC = () => {
  const intl = useIntl();

  // const {
  //   isOpen: isOpenDelete,
  //   handleClose: handleCloseDelete,
  //   handleOpen: handleOpenDelete,
  //   context: deleteContext,
  // } = useModal<PostsType>();
  // const {
  //   isOpen: isEditOpen,
  //   handleClose: handleCloseEdit,
  //   handleOpen: handleOpenEdit,
  //   context: editContext,
  // } = useModal<PostsType>();

  const { filters, searchField } = usePostsFilter();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    postsKeys.list(),
    getPosts,
    filters
  );

  const columns: Column<PostsType>[] = [
    {
      accessor: "user",
      Header: intl.formatMessage({ id: "ADMIN_POSTS.TABLE.CREATOR" }),
      Cell: ({ value }: CellProps<PostsType>) => <>{value?.name}</>,
    },
    {
      accessor: "category",
      Header: intl.formatMessage({ id: "ADMIN_POSTS.TABLE.CATEGORY" }),
      Cell: ({ value }: CellProps<PostsType>) => (
        <>
          {/* @ts-expect-error */}
          {PostCategoriesLabels[value]}
        </>
      ),
    },
    {
      accessor: "createdAt",
      Header: intl.formatMessage({ id: "ADMIN_POSTS.TABLE.PUBLISHED_AT" }),
      Cell: ({ value }: CellProps<PostsType>) => (
        <>{dateTimeFormatter(value)}</>
      ),
    },

    // {
    //   Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
    //   Cell: (cell: CellProps<PostsType>) => (
    //     <PostsActionsFormatter
    //       {...cell}
    //       handleOpenEdit={handleOpenEdit}
    //       handleOpenDelete={handleOpenDelete}
    //     />
    //   ),
    //   align: "right",
    // },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="ADMIN_POSTS.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
      </Grid>

      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />

      {/* {hasPermissions("delete:partners") && (
        <>
          <DeletePartnerModal
            isOpen={isOpenDelete}
            handleClose={handleCloseDelete}
            partner={deleteContext}
          />
        </>
      )}
      {hasPermissions("update:partners") && (
        <>
          <EditPartnerModal
            isOpen={isEditOpen}
            handleClose={handleCloseEdit}
            partner={editContext}
          />
        </>
      )} */}
    </>
  );
};

export default PostsTable;
