import { Avatar, AvatarProps } from "@mui/material";
import React, { FC } from "react";

interface IUserAvatar extends AvatarProps {
  name: string;
  size: number;
}

const UserAvatar: FC<IUserAvatar> = ({ name, size, ...rest }) => {
  const userInitials =
    name
      ?.split(" ")
      .map((chunk: string) => chunk.charAt(0))
      .join("") || "";

  const [from, to] = [size * 0.6, size * 0.875];

  return (
    <Avatar
      {...rest}
      sx={{
        ...rest.sx,
        width: `${size}px`,
        height: `${size}px`,
        fontSize: Math.min(from, to / userInitials.length),
      }}
    >
      {userInitials.toUpperCase()}
    </Avatar>
  );
};

export default UserAvatar;
