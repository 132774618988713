import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { useField } from "formik";
import React, { ReactNode } from "react";

import FieldErrorFeedbackFormatter from "../FieldErrorFeedbackFormatter";

interface FormikSelectFieldProps extends SelectProps {
  name: string;
  helperText?: TextFieldProps["helperText"];
  options: { value: string; label: string }[];
  renderOption?: (option: string) => string | JSX.Element;
  labelTitle?: ReactNode | string;
}

const FormikSelectField = ({
  name,
  options,
  label,
  size,
  helperText,
  labelTitle,
  ...rest
}: FormikSelectFieldProps) => {
  const [field, meta] = useField<string | null>(name);

  return (
    <>
      {labelTitle && <Typography sx={{ mb: 1.5 }}>{labelTitle}</Typography>}
      <FormControl fullWidth>
        <Select
          fullWidth
          size={size}
          error={meta.touched && !!meta.error}
          displayEmpty
          renderValue={(selected: any) => {
            if (!selected) {
              return (
                <Typography sx={{ opacity: 0.8 }}>
                  {rest.placeholder}
                </Typography>
              );
            }
            return options.find((option) => option.value === selected)?.label;
          }}
          {...field}
          {...rest}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {rest.renderOption
                ? rest.renderOption(option.value)
                : option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {meta.touched && meta.error ? (
        <Typography
          variant="body2"
          sx={{
            mb: 2,
            color: (theme) => theme.palette.error.main,
          }}
        >
          <FieldErrorFeedbackFormatter error={meta.error} />
        </Typography>
      ) : (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </>
  );
};

export default FormikSelectField;
