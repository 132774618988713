import { Grid, Paper } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import UserProfile from "../UserProfile";
import UserProfileLoading from "../UserProfileLoading";
import { useUser } from "../useUser";
import CompanySettingsForm from "./components/CompanySettingsForm";
import SettingsTabs from "./SettingsTabs";

const CompanySettings = () => {
  const { userId } = useParams<{ userId: string }>();
  const { isMyProfile, user, isLoading } = useUser(userId);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* Users about */}
        {isLoading ? (
          <UserProfileLoading />
        ) : (
          user && (
            <UserProfile
              activeTab="settings"
              {...user}
              isMyProfile={isMyProfile}
            />
          )
        )}
      </Grid>

      <Grid item xs={12} md={4} lg={3}>
        <SettingsTabs
          activeTab="company-settings"
          userId={user?._id || ""}
          isBusinessUser={true}
        />
      </Grid>

      <Grid item xs={12} md={8} lg={9}>
        <Paper sx={{ py: 2, px: 2 }}>
          <CompanySettingsForm
            initialValues={{
              industry: user?.industry || null,
              companyType: user?.companyType || null,
              companyAdministrator: user?.companyAdministrator,
            }}
            handleSubmit={() => {}}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CompanySettings;
