import { Box, Collapse, OutlinedInput as Input } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Formik, Form as FormikForm, useField } from "formik";
import React from "react";
import * as yup from "yup";

import { addCommentReply as addCommentReplyAPI } from "api/comments";
import { NewReplyPayloadType } from "api/comments/types";
import { postsKeys } from "api/posts/queries";

const CommentReplyInputField = () => {
  const [field] = useField<unknown>("content");
  return (
    <Input
      placeholder="Be first to write a comment"
      sx={{
        width: "100%",
        backgroundColor: "#EEE",
        border: "none",
        borderRadius: 2,
        "&.Mui-focused": {
          boxShadow: "none",
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
        ".MuiInputBase-input": {
          border: "none",
          py: "10px",
        },
      }}
      {...field}
    />
  );
};

const PostCommentReply = ({
  open,
  commentId,
}: {
  open: boolean;
  commentId: string;
}) => {
  const queryClient = useQueryClient();
  const initialValues = {
    content: "",
  };
  const schema = yup.object({
    content: yup.string().required(),
  });

  const { mutateAsync: addCommentReply } = useMutation(
    async (data: NewReplyPayloadType) => {
      const res = await addCommentReplyAPI(commentId, data);
      await queryClient.invalidateQueries(postsKeys.list());
      return res.data;
    }
  );

  const handleSubmit = async (
    values: { content: string },
    { resetForm }: any
  ) => {
    await addCommentReply(
      { ...values },
      {
        onSuccess: () => resetForm(),
      }
    );
  };

  return (
    <Collapse in={open} unmountOnExit>
      <Box sx={{ ml: 7, mt: 1 }}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          <FormikForm style={{ width: "100%" }}>
            <CommentReplyInputField />
          </FormikForm>
        </Formik>
      </Box>
    </Collapse>
  );
};

export default PostCommentReply;
