import { Box, Collapse, Grid, Stack } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";

import AdsBanner from "containers/dashboard/components/AdsBanner";
import Post from "containers/dashboard/components/Post/Post";
import UserPostLoading from "containers/dashboard/components/Post/PostLoading";

import UserProfile from "./UserProfile";
import UserProfileLoading from "./UserProfileLoading";
import UserStats from "./UserStats";
import UserStatsLoading from "./UserStatsLoading";
import { useUser } from "./useUser";

const User = () => {
  const { userId } = useParams<{ userId: string }>();
  const { isMyProfile, user, posts, isLoading } = useUser(userId);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* Users about */}
        {isLoading ? (
          <UserProfileLoading />
        ) : (
          user && (
            <UserProfile
              activeTab="posts"
              {...user}
              isMyProfile={isMyProfile}
            />
          )
        )}
      </Grid>

      <Grid item xs={12} md={4} lg={3}>
        {isLoading ? <UserStatsLoading /> : user && <UserStats {...user} />}
      </Grid>

      <Grid item xs={12} md={8} lg={6}>
        {isLoading ? (
          <Stack spacing={2}>
            <UserPostLoading />
            <UserPostLoading />
          </Stack>
        ) : (
          <Stack component={TransitionGroup} spacing={2}>
            {posts?.map((post) => (
              <Collapse key={post._id}>
                <Post {...post} />
              </Collapse>
            ))}
          </Stack>
        )}
      </Grid>

      <Grid item xs={12} lg={3}>
        <Box
          sx={{
            overflow: "hidden",
            position: "sticky",
            top: "16px",
            zIndex: "5",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={12}>
              <AdsBanner />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default User;
