import { Box, Grid, Paper, Skeleton, Typography } from "@mui/material";
import React from "react";

const UserGalleryLoading = () => {
  return (
    <Paper
      sx={{
        overflow: "hidden",
        p: 2,
      }}
    >
      <Box>
        <Typography variant="body2">Gallery</Typography>
      </Box>

      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item sx={{ height: "80px" }} xs={6}>
          <Skeleton width="100%" height="100%" sx={{ transform: "scale(1)" }} />
        </Grid>
        <Grid item sx={{ height: "80px" }} xs={6}>
          <Skeleton width="100%" height="100%" sx={{ transform: "scale(1)" }} />
        </Grid>
        <Grid item sx={{ height: "80px" }} xs={6}>
          <Skeleton width="100%" height="100%" sx={{ transform: "scale(1)" }} />
        </Grid>
        <Grid item sx={{ height: "80px" }} xs={6}>
          <Skeleton width="100%" height="100%" sx={{ transform: "scale(1)" }} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UserGalleryLoading;
