import { AttachmentsType } from "api/storage/types";
import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import { UsersType } from "./types";

export const getUsers = (
  queryParams?: QueryParamsType<UsersType>
): APICall<Paginated<UsersType[]>> =>
  request({
    url: `/user${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const getSingleUser = (userId: string): APICall<{ data: UsersType }> =>
  request({
    url: `/user/${userId}`,
    method: "GET",
  });

export const followUser = (userId: string): APICall<null> =>
  request({
    url: `/user/follow/${userId}`,
    method: "POST",
  });

export const getSingleUserFollowing = (
  userId: string
): APICall<{ data: UsersType[] }> =>
  request({
    url: `/user/following/${userId}`,
    method: "GET",
  });

export const getSingleUserFollowers = (
  userId: string
): APICall<{ data: UsersType[] }> =>
  request({
    url: `/user/followers/${userId}`,
    method: "GET",
  });

export const getSingleUserGallery = (
  userId: string
): APICall<{ data: AttachmentsType[] }> =>
  request({
    url: `/user/gallery/${userId}`,
    method: "GET",
  });
