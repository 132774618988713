import { Box, Stack, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React, { ChangeEvent, Dispatch, FC, SetStateAction } from "react";

import { FileWithPreview } from "utils/media/renderMedia";
interface ICreatePostAttachemnt {
  title: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  setFiles: Dispatch<SetStateAction<FileWithPreview[]>>;
  accept?: string;
}

const CreatePostAttachment: FC<ICreatePostAttachemnt> = ({
  title,
  icon: Icon,
  setFiles,
  accept,
}) => {
  const inputName = `file-${title.toLowerCase()}`;
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let files: FileWithPreview[] = Array.prototype.slice.call(
      event.target.files
    );

    files = files.map((file) => {
      file.preview = URL.createObjectURL(file);

      return file;
    });

    setFiles((p) => [...p, ...files]);
  };

  return (
    <Box>
      <input
        id={inputName}
        name={inputName}
        type="file"
        hidden
        onChange={handleChange}
        accept={accept}
        multiple
      />
      <label htmlFor={inputName}>
        <Stack
          sx={{
            width: "50px",
            height: "50px",
            borderRadius: "4px",
            backgroundColor: "#F2f2f2",
            cursor: "pointer",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Icon
            sx={{
              height: "20px",
              mt: 0.5,
              mb: 0.2,
              color: "#696F79",
            }}
          />
          <Typography sx={{ fontSize: "11px" }}>{title}</Typography>
        </Stack>
      </label>
    </Box>
  );
};

export default CreatePostAttachment;
