import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { postStripeCheckout } from "api/stripe";

import LoginLayout from "../login/components/LoginLayout";
import CompleteProfileForm from "./components/CompleteProfileForm";
import PaymentForm from "./components/PaymentForm";
import RegisterBusinessForm from "./components/RegisterBusinessForm";
import RegisterStepper from "./components/RegisterStepper";
import SubscriptionPlanForm from "./components/SubscriptionPlanForm";

const RegisterBusinessPage = () => {
  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  const stepQuery = query.get("step");
  const successQuery = query.get("success");

  const [activeStep, setActiveStep] = useState<number>(0);

  useEffect(() => {
    if (stepQuery === "checkout" && localStorage.getItem("JWToken_business")) {
      if (successQuery === "true") {
        setActiveStep(3);
      } else {
        setActiveStep(2);
      }
    }
  }, [stepQuery, successQuery]);

  const handlePreviousStep = () => {
    setActiveStep((p) => p - 1);
  };
  const handleNextStep = () => {
    setActiveStep((p) => p + 1);
  };

  const { mutateAsync: handleSelectSubscriptionPlan, isLoading } = useMutation(
    async (choosenPlan: "monthly" | "yearly") => {
      const { data: res } = await postStripeCheckout(choosenPlan);

      if (res.data.url) {
        handleNextStep();
        window.location.href = res.data.url;
        return res.data.url;
      }
    }
  );

  const handleLogin = () => {
    const tokenBusiness = localStorage.getItem("JWToken_business");
    if (tokenBusiness) {
      localStorage.setItem("JWToken", tokenBusiness);
      localStorage.removeItem("JWToken_business");
      history.push("/");
    }
  };

  const currentDisplay = [
    <RegisterBusinessForm handleNextStep={handleNextStep} />,
    <CompleteProfileForm handleNextStep={handleNextStep} />,
    <SubscriptionPlanForm
      handleSelectSubscriptionPlan={handleSelectSubscriptionPlan}
      isLoading={isLoading}
    />,
    <PaymentForm
      handleLogin={handleLogin}
      handlePreviousStep={handlePreviousStep}
    />,
  ];

  return (
    <LoginLayout
      title={
        <Box sx={{ maxWidth: "480px", width: "100%" }} mx="auto">
          <RegisterStepper activeStep={activeStep} />
        </Box>
      }
    >
      {currentDisplay[activeStep]}
    </LoginLayout>
  );
};

export default RegisterBusinessPage;
