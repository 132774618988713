import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useField } from "formik";
import React, { ReactElement } from "react";

import { MakeRequired } from "utils/types";

import FieldErrorFeedbackFormatter from "../FieldErrorFeedbackFormatter";

interface FormikCheckboxFieldProps extends MakeRequired<CheckboxProps, "name"> {
  label: string | ReactElement;
  helperText?: string | ReactElement;
  controlProps?: Omit<Omit<FormControlLabelProps, "control">, "label">;
}

const FormikCheckboxField = ({
  name,
  label,
  helperText,
  controlProps,
  ...rest
}: FormikCheckboxFieldProps) => {
  const [field, meta] = useField<unknown>(name);

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(field.value)}
            sx={{
              color: (theme) =>
                meta.touched && meta.error ? theme.palette.error.main : null,
            }}
            {...field}
            {...rest}
          />
        }
        label={label}
        sx={{ mb: 0 }}
        {...controlProps}
      />

      {meta.touched && meta.error ? (
        <Typography
          variant="body2"
          sx={{
            mb: 2,
            color: (theme) => theme.palette.error.main,
          }}
        >
          <FieldErrorFeedbackFormatter error={meta.error} />
        </Typography>
      ) : (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </>
  );
};

export default FormikCheckboxField;
