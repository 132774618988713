import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { FC } from "react";

interface ITermsConditionsModal {
  open: boolean;
  handleClose: () => void;
}

const TermsConditionsModal: FC<ITermsConditionsModal> = ({
  open,
  handleClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
    >
      <DialogTitle variant="h2">Terms & Conditions</DialogTitle>
      <DialogContent>
        <Box sx={{ maxHeight: "450px", overflow: "scroll" }}>
          <Typography>
            Vestibulum eu quam nec neque pellentesque efficitur id eget nisl.
            Proin porta est convallis lacus blandit pretium sed non enim.
            Maecenas lacinia non orci at aliquam. Donec finibus, urna bibendum
            ultricies laoreet, augue eros luctus sapien, ut euismod leo tortor
            ac enim. In hac habitasse platea dictumst. Sed cursus venenatis
            tellus, non lobortis diam volutpat sit amet. Sed tellus augue,
            hendrerit eu rutrum in, porttitor at metus. Mauris ac hendrerit
            metus. Phasellus mattis lectus commodo felis egestas, id accumsan
            justo ultrices. Phasellus aliquet, sem a placerat dapibus, enim
            purus dictum lacus, nec ultrices ante dui ac ante. Phasellus
            placerat, urna.Vestibulum eu quam nec neque pellentesque efficitur
            id eget nisl. Proin porta est convallis lacus blandit pretium sed
            non enim. Maecenas lacinia non orci at aliquam. Donec finibus, urna
            bibendum ultricies laoreet, augue eros luctus sapien, ut euismod leo
            tortor ac enim. In hac habitasse platea dictumst. Sed cursus
            venenatis tellus, non lobortis diam volutpat sit amet. Sed tellus
            augue, hendrerit eu rutrum in, porttitor at metus. Mauris ac
            hendrerit metus. Phasellus mattis lectus commodo felis egestas, id
            accumsan justo ultrices. Phasellus aliquet, sem a placerat dapibus,
            enim purus dictum lacus, nec ultrices ante dui ac ante. Phasellus
            placerat, urna.Vestibulum eu quam nec neque pellentesque efficitur
            id eget nisl. Proin porta est convallis lacus blandit pretium sed
            non enim. Maecenas lacinia non orci at aliquam. Donec finibus, urna
            bibendum ultricies laoreet, augue eros luctus sapien, ut euismod leo
            tortor ac enim. In hac habitasse platea dictumst. Sed cursus
            venenatis tellus, non lobortis diam volutpat sit amet. Sed tellus
            augue, hendrerit eu rutrum in, porttitor at metus. Mauris ac
            hendrerit metus. Phasellus mattis lectus commodo felis egestas, id
            accumsan justo ultrices. Phasellus aliquet, sem a placerat dapibus,
            enim purus dictum lacus, nec ultrices ante dui ac ante. Phasellus
            placerat, urna.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsConditionsModal;
