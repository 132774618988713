import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Formik, Form as FormikForm } from "formik";
import { FC } from "react";
import * as yup from "yup";

import FormikCheckboxField from "components/forms/FormikCheckboxField";

type NotificationSettingsFormValues = {};

interface INotificationSettingsForm {
  initialValues?: NotificationSettingsFormValues;
  submitStatus?: MutationStatus;
  handleSubmit: (values: NotificationSettingsFormValues) => void;
}

const NotificationSettingsForm: FC<INotificationSettingsForm> = ({
  initialValues = {
    comments: true,
    followers: true,
  },
  submitStatus,
  handleSubmit,
}) => {
  const schema = yup.object({});

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        <FormikForm>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <FormikCheckboxField label="Comments" name="comments" />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikCheckboxField label="Followers" name="followers" />
            </Grid>
          </Grid>
          <LoadingButton
            sx={{ py: 1, px: 4, mt: 3 }}
            type="submit"
            variant="contained"
            loading={false}
          >
            Save
          </LoadingButton>
        </FormikForm>
      </Formik>
    </>
  );
};

export default NotificationSettingsForm;
