import { useQuery } from "@tanstack/react-query";

import { getSingleUserFollowing } from "api/users";
import { usersKeys } from "api/users/queries";

export const useFollowing = (userId: string) => {
  const { data: followingData, isLoading } = useQuery(
    usersKeys.following(userId),
    async () => {
      const { data: res } = await getSingleUserFollowing(userId);
      return res;
    }
  );

  return {
    following: followingData?.data,
    isLoading,
  };
};
