import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { bindPopover } from "material-ui-popup-state";
import { PopupState as IPopupState } from "material-ui-popup-state/core";
import React, { FC } from "react";

import { postPinPost as postPinPostAPI } from "api/posts";
import { postsKeys } from "api/posts/queries";
import { userKeys } from "api/user/queries";
import { useUserStore } from "components/stores/UserStore";
dayjs.extend(relativeTime);

interface IPostPopoverUser {
  postId: string;
  popupState: IPopupState;
}

const PostPopoverUser: FC<IPostPopoverUser> = ({ postId, popupState }) => {
  const queryClient = useQueryClient();
  const [me] = useUserStore((s) => [s.user]);

  const { mutateAsync: pinPost } = useMutation(async (data: string) => {
    const res = await postPinPostAPI(data);
    await queryClient.invalidateQueries(postsKeys.list());
    await queryClient.invalidateQueries(userKeys.me());
    return res.data;
  });

  const handlePinPost = async () => {
    await pinPost(postId, {
      onSuccess: () => popupState.close(),
    });
  };

  return (
    <Popover
      BackdropProps={{
        invisible: true,
      }}
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        ".MuiPaper-root": {
          boxShadow: "0px 2px 20px 0px #00000020",
        },
      }}
    >
      <List sx={{ py: 0.5 }}>
        <ListItem
          disablePadding
          onClick={() => {
            // setSortBy("recent");
            popupState.close();
          }}
          sx={{ minWidth: "80px" }}
        >
          <ListItemButton sx={{ py: 0.5 }} onClick={handlePinPost}>
            <ListItemText>
              <Typography variant="body2">
                {me?.pinnedPosts?.includes(postId) ? "Unpin" : "Pin"}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Popover>
  );
};

export default PostPopoverUser;
