import { useQuery } from "@tanstack/react-query";

import { getSingleUserFollowers } from "api/users";
import { usersKeys } from "api/users/queries";

export const useFollowers = (userId: string) => {
  const { data: followersData, isLoading } = useQuery(
    usersKeys.followers(userId),
    async () => {
      const { data: res } = await getSingleUserFollowers(userId);
      return res;
    }
  );

  return {
    followers: followersData?.data,
    isLoading,
  };
};
