import {
  ArrowCircleDown as ArrowCircleDownIcon,
  ArrowCircleUp as ArrowCircleUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
  MoreHoriz as MoreHorizIcon,
  Star,
  StarOutline,
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  OutlinedInput as Input,
  Link,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Markup } from "interweave";
import PopupState, { bindTrigger } from "material-ui-popup-state";
import React, { FC, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";

import { PostsType } from "api/posts/types";
import UserAvatar from "components/UserAvatar";
import { renderAttachment } from "utils/media/renderMedia";

import Comment from "../Comment/Comment";
import PostPopoverOwner from "./components/PostPopoverOwner";
import PostPopoverUser from "./components/PostPopoverUser";
import { usePost } from "./usePost";
import WriteComment from "./WriteComment/WriteComment";

dayjs.extend(relativeTime);

interface IUserPost extends PostsType {}

const UserPost: FC<IUserPost> = (post) => {
  const theme = useTheme();
  const {
    _id,
    user: postUser,
    category,
    interaction,
    participant,
    content,
    recommend,
    comments,
    votes,
    attachments,
    createdAt,
    address,
  } = post;
  const { handleUpvotePost, handleDownvotePost, upvoted, downvoted, isMyPost } =
    usePost(post);

  const votesScore = votes.up.length - votes.down.length;

  const [commentsOpened, setCommentsOpened] = useState(true);

  const interactionColor = {
    "positive-interaction": theme.palette.success.light,
    "negative-interaction": theme.palette.error.light,
    "neutral-interaction": theme.palette.grey[400],
  };

  return (
    <Box>
      <Paper>
        {interaction && (
          <Box
            //@ts-expect-error
            sx={{ bgcolor: (t) => interactionColor[interaction] }}
          >
            <Typography
              sx={{ fontSize: "12px", pl: 1, py: 0.5 }}
              fontWeight="bold"
              color="#fff"
              textTransform="uppercase"
            >
              {interaction === "positive-interaction" && "Positive "}
              {interaction === "neutral-interaction" && "Neutral "}
              {interaction === "negative-interaction" && "Negative "}
              Interaction
            </Typography>
          </Box>
        )}
        <Box sx={{ p: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1.5}>
              <Box
                component={RouterLink}
                to={`/user/${postUser?._id}`}
                sx={{ textDecoration: "none" }}
              >
                <UserAvatar
                  name={postUser.name}
                  size={40}
                  sx={{
                    textAlign: "center",
                    backgroundColor: (theme) => theme.palette.primary.main,
                  }}
                />
              </Box>

              <Stack direction="column">
                <Link
                  component={RouterLink}
                  to={`/user/${postUser?._id}`}
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    color: "#000",
                    lineHeight: 1.4,
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                    // mb: 0.5,
                  }}
                >
                  {postUser?.name}
                </Link>

                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    lineHeight: 1.2,
                  }}
                >
                  {address.formatted}
                </Typography>

                <Typography variant="body2" sx={{ lineHeight: 1.2 }}>
                  {dayjs(createdAt).fromNow()}
                </Typography>
              </Stack>
            </Stack>

            <PopupState variant="popover" popupId="sort-by-popover">
              {(popupState) => (
                <Box>
                  <IconButton {...bindTrigger(popupState)}>
                    <MoreHorizIcon />
                  </IconButton>
                  {isMyPost ? (
                    <PostPopoverOwner postId={_id} popupState={popupState} />
                  ) : (
                    <PostPopoverUser postId={_id} popupState={popupState} />
                  )}
                </Box>
              )}
            </PopupState>
          </Stack>

          <Stack sx={{ mt: 2 }} spacing={2}>
            {(category === "customer-interaction" ||
              category === "employee-interaction") && (
              <>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#000",
                      fontWeight: "bold",
                      mb: 1,
                    }}
                  >
                    {category === "customer-interaction" && "Customer "}
                    {category === "employee-interaction" && "Emplyoee "}
                    Information:
                  </Typography>
                  <Typography variant="body2">
                    <Grid container spacing={1}>
                      <Grid item xs={12} lg={12}>
                        <Input
                          fullWidth
                          value={participant?.name}
                          sx={{
                            py: 0,
                            width: "100%",
                            backgroundColor: "#EEE",
                            border: "none",
                            borderRadius: 2,
                            "&.Mui-focused": {
                              boxShadow: "none",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "transparent",
                            },
                            ".MuiInputBase-input": {
                              border: "none",
                              py: "10px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Input
                          fullWidth
                          value={participant?.address}
                          sx={{
                            py: 0,
                            width: "100%",
                            backgroundColor: "#EEE",
                            border: "none",
                            borderRadius: 2,
                            "&.Mui-focused": {
                              boxShadow: "none",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "transparent",
                            },
                            ".MuiInputBase-input": {
                              border: "none",
                              py: "10px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Input
                          fullWidth
                          value={participant?.city}
                          sx={{
                            py: 0,
                            width: "100%",
                            backgroundColor: "#EEE",
                            border: "none",
                            borderRadius: 2,
                            "&.Mui-focused": {
                              boxShadow: "none",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "transparent",
                            },
                            ".MuiInputBase-input": {
                              border: "none",
                              py: "10px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Input
                          fullWidth
                          value={participant?.state}
                          sx={{
                            py: 0,
                            width: "100%",
                            backgroundColor: "#EEE",
                            border: "none",
                            borderRadius: 2,
                            "&.Mui-focused": {
                              boxShadow: "none",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "transparent",
                            },
                            ".MuiInputBase-input": {
                              border: "none",
                              py: "10px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Input
                          fullWidth
                          value={participant?.zip}
                          sx={{
                            py: 0,
                            width: "100%",
                            backgroundColor: "#EEE",
                            border: "none",
                            borderRadius: 2,
                            "&.Mui-focused": {
                              boxShadow: "none",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "transparent",
                            },
                            ".MuiInputBase-input": {
                              border: "none",
                              py: "10px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mt={1}
                        >
                          {recommend ? (
                            <>
                              <Star
                                sx={{ color: theme.palette.warning.light }}
                              />
                              <Typography fontSize="14px">
                                Would Recommend
                              </Typography>
                            </>
                          ) : (
                            <>
                              <StarOutline
                                sx={{ color: theme.palette.warning.light }}
                              />
                              <Typography fontSize="14px">
                                Wouldn't Recommend
                              </Typography>
                            </>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Typography>
                </Box>

                <Divider />
              </>
            )}

            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                Details
              </Typography>
              <Box
                sx={{
                  fontSize: "14px",
                  lineHeight: 1.4,
                  fontFamily:
                    '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
                  color: "#696F79",
                  img: {
                    maxWidth: "100%",
                  },
                  wordWrap: "break-word",
                  // "p:first-child": {
                  //   mt: 0,
                  // },
                }}
              >
                <Markup content={content} />
              </Box>
            </Box>

            {attachments && (
              <ImageList
                variant="masonry"
                cols={Math.min(attachments.length, 3)}
                gap={5}
              >
                {attachments.map((attachment) => (
                  <ImageListItem
                    key={attachment.name}
                    sx={{ border: "2px solid #EDEDED" }}
                  >
                    {renderAttachment(attachment)}
                  </ImageListItem>
                ))}
              </ImageList>
            )}
          </Stack>

          <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              onClick={() => setCommentsOpened((p) => !p)}
              sx={{ cursor: "pointer" }}
            >
              {comments.length ? (
                <Typography variant="body2">
                  {comments.length} comments
                </Typography>
              ) : (
                <Typography variant="body2">No comments</Typography>
              )}
              <ArrowDropDownIcon
                sx={{
                  transition: "300ms transform ease",
                  transform: commentsOpened ? "rotate(180deg)" : "none",
                }}
              />
            </Stack>

            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography variant="body2" sx={{ mr: 0.5 }}>
                ({votesScore}) Vote
              </Typography>
              <IconButton sx={{ p: 0 }} onClick={handleUpvotePost}>
                <ArrowCircleUpIcon
                  sx={{
                    fontSize: "26px",
                    color: (theme) =>
                      upvoted ? theme.palette.primary.main : null,
                  }}
                />
              </IconButton>
              <IconButton sx={{ p: 0 }} onClick={handleDownvotePost}>
                <ArrowCircleDownIcon
                  sx={{
                    fontSize: "26px",
                    color: (theme) =>
                      downvoted ? theme.palette.primary.main : null,
                  }}
                />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
      </Paper>

      <WriteComment postId={_id} />

      <Paper
        sx={{
          px: 2,
          pb: comments.length && commentsOpened ? 1 : 0,
          maxHeight: commentsOpened ? "1000px" : "0px",
          transition: "max-height 300ms ease, padding-bottom 300ms ease",
          overflor: "hidden",
        }}
      >
        <Collapse in={comments.length > 3} unmountOnExit>
          <Typography
            variant="body2"
            sx={{
              textAlign: "center",
              mt: 1,
              color: (theme) => theme.palette.primary.main,
            }}
          >
            See all {comments.length} comments
          </Typography>
        </Collapse>
        <Stack component={TransitionGroup}>
          {comments.slice(-3).map((comment) => (
            <Collapse key={comment._id}>
              <Comment {...comment} />
            </Collapse>
          ))}
        </Stack>
      </Paper>
    </Box>
  );
};

export default UserPost;
