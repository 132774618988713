import { Box, BoxProps } from "@mui/material";
import React, { FC } from "react";
import {
  Circle,
  Marker,
  GoogleMap as ReactGoogleMap,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";

const MapComponent = withScriptjs(
  withGoogleMap(
    ({
      center,
      marker,
    }: {
      center: { lat: number; lng: number };
      marker?: { lat: number; lng: number; radius?: number };
    }) => {
      return (
        <ReactGoogleMap
          defaultZoom={13}
          defaultCenter={center}
          center={marker ? { lat: marker?.lat, lng: marker?.lng } : center}
        >
          {marker && (
            <>
              <Marker position={{ lat: marker?.lat, lng: marker?.lng }} />
              {marker.radius && (
                <Circle
                  center={{ lat: marker.lat, lng: marker.lng }}
                  radius={marker.radius}
                  options={{ strokeColor: "#1565D8", fillColor: "#1565D8" }}
                />
              )}
            </>
          )}
        </ReactGoogleMap>
      );
    }
  )
);

interface IGoogleMap extends BoxProps {
  center?: { lat: number; lng: number };
  marker?: {
    lat: number;
    lng: number;
    radius?: number;
  };
}

const GoogleMap: FC<IGoogleMap> = ({
  center: overrideCenter,
  marker,
  ...rest
}) => {
  const center = overrideCenter
    ? overrideCenter
    : { lat: 40.7128, lng: -74.006 };

  return (
    <Box {...rest}>
      <MapComponent
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
        loadingElement={<div style={{ height: "100%" }} />}
        containerElement={<div style={{ height: "100%" }} />}
        mapElement={<div style={{ height: "100%" }} />}
        center={center}
        marker={marker}
      />
    </Box>
  );
};

export default GoogleMap;
