import { NotificationsType } from "api/notifications/types";

export const generateComment = (notification: NotificationsType) => {
  switch (notification.type) {
    case "comment":
      return (
        <span>
          <b>{notification.target.name}</b> commented on your post
        </span>
      );
    default:
      return `New notification from ${notification.target.name}`;
  }
};
