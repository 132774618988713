import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { render } from "react-dom";

// COMPONENTS:
import App from "./App";

// STYLES:
import "./index.scss";

const container = document.getElementById("root");
render(<App />, container);
