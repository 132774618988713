import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React, { FC, useMemo, useState } from "react";

import { getSearchLocation } from "api/location";
import { locationKeys } from "api/location/queries";
import FormikAutocompleteField from "components/forms/FormikAutocompleteField";
import GoogleMap from "components/map/GoogleMap";
import { useDebounce } from "utils/hooks/useDebounce";

interface ISearchLocationModal {
  open: boolean;
  handleClose: () => void;
  setLocation: (point: {
    address: string;
    location: { lat: string; lng: string };
  }) => void;
}

const SearchLocationModal: FC<ISearchLocationModal> = ({
  open,
  handleClose,
  setLocation,
}) => {
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 500);
  const [options, setOptions] = useState<any[]>([]);

  const closeResetModal = () => {
    setOptions([]);
    setSearch("");
    resetForm();
    handleClose();
  };

  const handleSubmit = (values: any) => {
    if (values.q) {
      const option = options.find((target) => target.value === values.q);

      setLocation({
        address: option.label,
        location: {
          lat,
          lng,
        },
      });

      closeResetModal();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      q: "",
    },
    onSubmit: handleSubmit,
  });
  const { values, resetForm } = formik;

  const { isLoading: loading } = useQuery(
    locationKeys.search(debouncedSearch),
    async () => {
      const { data: res } = await getSearchLocation(debouncedSearch);

      setOptions(
        res.data?.map((target) => ({
          label: target.address,
          value: `location:${target.location.lat},${target.location.lng}`,
        })) ?? []
      );

      return res;
    },
    {
      enabled:
        !Boolean(values.q) && Boolean(debouncedSearch) && Boolean(search),
    }
  );

  const [lat, lng] = values.q?.replace("location:", "").split(",") ?? [
    null,
    null,
  ];

  const marker = useMemo(() => {
    if (lat && lng && search) {
      return { lat: parseFloat(lat), lng: parseFloat(lng), radius: 2500 };
    }
    return undefined;
  }, [lat, lng, search]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        closeResetModal();
      }}
      maxWidth="md"
    >
      <FormikProvider value={formik}>
        <FormikForm style={{ width: "100%" }}>
          <DialogContent>
            <Box sx={{ mb: 1 }}>
              <FormikAutocompleteField
                label="Search"
                name="q"
                onInputChange={(event, value) => {
                  setSearch(value);
                }}
                options={debouncedSearch ? options : []}
                sx={{
                  fontSize: "16px",
                  ".MuiInputBase-input": {
                    fontSize: "16px",
                  },
                }}
                loading={loading}
                filterOptions={(options) => options}
              />
            </Box>

            <GoogleMap
              marker={marker}
              sx={{
                height: "320px",
              }}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={closeResetModal}>Close</Button>
            <Button
              variant="contained"
              disabled={!Boolean(values.q)}
              type="submit"
            >
              Save
            </Button>
          </DialogActions>
        </FormikForm>
      </FormikProvider>
    </Dialog>
  );
};

export default SearchLocationModal;
