import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";
import { PostCategoriesType } from "containers/dashboard/components/CreatePost/useCreatePost";

import request, { APICall } from "../request";
import { NewPostPayloadType, PostsType } from "./types";

export const getPosts = (
  queryParams?: QueryParamsType<PostsType>
): APICall<Paginated<PostsType[]>> =>
  request({
    url: `/post${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const postSearchPosts = (
  q: string,
  category: PostCategoriesType
): APICall<Paginated<PostsType[]>> =>
  request({
    url: `/post/search`,
    method: "POST",
    data: {
      q,
      category,
    },
  });

export const addPost = (post: NewPostPayloadType | FormData): APICall<null> =>
  request({
    data: post,
    url: `/post`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      type: "formData",
    },
  });

export const deletePost = (postId: string): APICall<null> =>
  request({
    url: `/post/${postId}`,
    method: "DELETE",
  });

export const getPostsCategoryCount = (): APICall<{
  data: { [category: string]: number };
}> =>
  request({
    url: `/post/category/count`,
    method: "GET",
  });

export const upvotePost = (postId: string): APICall<null> =>
  request({
    url: `/post/upvote/${postId}`,
    method: "POST",
  });

export const downvotePost = (postId: string): APICall<null> =>
  request({
    url: `/post/downvote/${postId}`,
    method: "POST",
  });

export const getPinnedPosts = (): APICall<Paginated<PostsType[]>> =>
  request({
    url: `/post/pinned`,
    method: "GET",
  });

export const postPinPost = (postId: string): APICall<null> =>
  request({
    url: `/post/pin/${postId}`,
    method: "POST",
  });
