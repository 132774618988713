import {
  ArrowRightAlt as ArrowRightAltIcon,
  SettingsBackupRestore as SettingsBackupRestoreIcon,
} from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Divider,
  Grow,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Markup } from "interweave";
import React, { FC, Fragment, useState } from "react";
import { useHistory } from "react-router-dom";

import UserAvatar from "components/UserAvatar";

import { SearchTypes, useSearchSuggestion } from "./useSearchSuggestion";

interface ISearchSuggestionPopover {
  q: string;
  display: boolean;
}

const searchSuggestions = [
  {
    label: "Photo Studio",
    createdAt: "2022-07-24T23:32:37.095Z",
  },
  {
    label: "Photography",
    createdAt: "2022-07-24T23:32:37.095Z",
  },
];

const SearchSuggestionPopover: FC<ISearchSuggestionPopover> = ({
  q,
  display: focusDisplay,
}) => {
  const history = useHistory();
  const [hovered, setHovered] = useState<boolean>(false);
  const display = focusDisplay || hovered;

  const { posts, searchType, setSearchType } = useSearchSuggestion(q, display);

  return (
    <Grow in={display} unmountOnExit>
      <Paper
        sx={{
          position: "absolute",
          width: "400px",
          mt: "3px",
          boxShadow: "0px 0px 6px 0px #00000040",
          zIndex: 2,
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => {
          //@ts-expect-error
          document.querySelector("#search")?.focus();
        }}
      >
        <Box sx={{ backgroundColor: "#F2F2F2", px: 2, py: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            Recent Search
          </Typography>
        </Box>

        <Stack direction="column">
          {searchSuggestions.map((suggestion) => (
            <Stack
              key={suggestion.label}
              component={ButtonBase}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ py: 1, px: 2, cursor: "pointer" }}
              onClick={(e: { stopPropagation: () => void }) => {
                e.stopPropagation();
                setHovered(false);
                history.push(`/search?q=${q}&type=posts`);
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <SettingsBackupRestoreIcon
                  sx={{ color: "#828282", fontSize: "16px" }}
                />
                <Typography variant="body2" sx={{ color: "#4F4F4F" }}>
                  {suggestion.label}
                </Typography>
              </Stack>
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                10h ago
              </Typography>
            </Stack>
          ))}
        </Stack>

        <Divider />
        <Box sx={{ backgroundColor: "#F2F2F2", px: 2, py: 1 }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", color: "#333333" }}
          >
            Suggestions
          </Typography>
        </Box>

        <Stack sx={{ px: 2, my: 2 }} direction="row" spacing={1}>
          {Object.values(SearchTypes).map((type, index) => (
            <ButtonBase
              key={index}
              sx={{
                py: 0.8,
                px: 2,
                borderRadius: 10,
                fontWeight: "bold",
                fontSize: "12px",
                minWidth: "80px",
                backgroundColor: (theme) =>
                  searchType === type ? theme.palette.primary.main : "#E0E0E0",
                color: searchType === type ? "#fff" : "#828282",
                textTransform: "capitalize",
              }}
              onClick={() => setSearchType(type)}
            >
              {type}
            </ButtonBase>
          ))}
        </Stack>

        <Box>
          {posts?.map((post) => (
            <Fragment key={post._id}>
              <Divider />
              <Stack
                sx={{ py: 0.5, px: 2, cursor: "pointer" }}
                direction="row"
                spacing={1.5}
                // onClick={(e: { stopPropagation: () => void }) => {
                //   e.stopPropagation();
                //   setHovered(false);
                //   history.push(`/search?q=${post.content}&type=posts`);
                // }}
              >
                <UserAvatar
                  name={post.user.name}
                  size={36}
                  sx={{
                    textAlign: "center",
                    backgroundColor: (theme) => theme.palette.primary.main,
                  }}
                />
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "12px",
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    {post.user.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      "*": {
                        padding: 0,
                        margin: 0,
                        display: "inline",
                      },
                    }}
                  >
                    "<Markup content={post.content} />"
                  </Typography>
                </Box>
              </Stack>
            </Fragment>
          ))}
        </Box>

        <Divider />
        <ButtonBase
          sx={{ backgroundColor: "#F2F2F2", px: 2, py: 1, width: "100%" }}
          onClick={(e) => {
            e.stopPropagation();
            setHovered(false);
            history.push(`/search?q=${q}&type=${searchType}`);
          }}
        >
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2" sx={{ color: "#333333" }}>
              View all results
            </Typography>
            <ArrowRightAltIcon sx={{ color: "#828282" }} />
          </Stack>
        </ButtonBase>
      </Paper>
    </Grow>
  );
};

export default SearchSuggestionPopover;
