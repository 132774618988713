import {
  Box,
  ImageList,
  ImageListItem,
  Paper,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";

import { getSingleUserGallery } from "api/users";
import { usersKeys } from "api/users/queries";
import { useUserStore } from "components/stores/UserStore";
import { renderAttachment } from "utils/media/renderMedia";

import UserGalleryLoading from "./UserGalleryLoading";

const UserGallery = () => {
  const [me] = useUserStore((s) => [s.user]);

  const { data: galleryData, isLoading } = useQuery(
    usersKeys.gallery(me?._id || ""),
    async () => {
      const { data: res } = await getSingleUserGallery(me?._id || "");
      return res;
    },
    { enabled: Boolean(me?._id) }
  );

  const gallery = galleryData?.data;

  if (isLoading) {
    return <UserGalleryLoading />;
  }

  return (
    <Paper
      sx={{
        overflow: "hidden",
        p: 2,
      }}
    >
      <Box>
        <Typography variant="body2">Gallery</Typography>
      </Box>

      {gallery?.length ? (
        <ImageList variant="masonry" cols={2} gap={5}>
          {gallery.slice(0, 6).map((attachment) => (
            <ImageListItem
              sx={{ border: "2px solid #EDEDED" }}
              key={attachment.name}
            >
              {renderAttachment(attachment)}
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <Box>
          <Typography variant="body2" sx={{ mt: 1.5, opacity: 0.6 }}>
            No photos uploaded
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default UserGallery;
