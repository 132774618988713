import { Box, Collapse, Grid, Stack } from "@mui/material";
import React from "react";
import { TransitionGroup } from "react-transition-group";

import AdsBanner from "./components/AdsBanner";
import CreatePost from "./components/CreatePost/CreatePost";
import Post from "./components/Post/Post";
import PostLoading from "./components/Post/PostLoading";
import UserGallery from "./components/UserGallery";
import UserPostFilter from "./components/UserPostFilter";
import UserProfile from "./components/UserProfile";
import { useDashboard } from "./useDashboard";

const Dashboard = () => {
  const { sortBy, setSortBy, filterBy, setFilterBy, posts, isLoading } =
    useDashboard();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} lg={3}>
        <Box
          sx={{
            overflow: "hidden",
            position: "sticky",
            top: "16px",
            zIndex: "5",
          }}
        >
          <UserProfile />
          <UserGallery />
        </Box>
      </Grid>
      <Grid item xs={12} md={8} lg={6}>
        <Stack spacing={2}>
          <CreatePost />
          <UserPostFilter
            sortBy={sortBy}
            setSortBy={setSortBy}
            filterBy={filterBy}
            setFilterBy={setFilterBy}
          />
          {isLoading ? (
            <Stack spacing={2}>
              <PostLoading />
              <PostLoading />
            </Stack>
          ) : (
            <Stack component={TransitionGroup} spacing={2}>
              {posts?.map((post) => (
                <Collapse key={post._id}>
                  <Post {...post} />
                </Collapse>
              ))}
            </Stack>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Box
          sx={{
            overflow: "hidden",
            position: "sticky",
            top: "16px",
            zIndex: "5",
          }}
        >
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={6} lg={12}></Grid> */}
            <Grid item xs={12} md={6} lg={12}>
              <AdsBanner />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
