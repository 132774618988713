import { useMemo } from "react";

import { CommentsType } from "api/comments/types";
import { useUserStore } from "components/stores/UserStore";

export const useComment = ({ _id, user: commentUser }: CommentsType) => {
  const [user] = useUserStore((s) => [s.user]);

  const isMyComment = useMemo(() => {
    return user?._id === commentUser._id;
  }, [commentUser._id, user?._id]);

  return {
    user,
    isMyComment,
  };
};
