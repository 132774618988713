import { AxiosRequestConfig } from "axios";

import request, { APICall } from "../request";
import {
  CurrentUserType,
  EditUserType,
  LoginPayloadType,
  LoginType,
  RegisterPayloadType,
  RegisterVerificationPayloadType,
  ResetPasswordPayloadType,
  ResetPasswordVerificationPayloadType,
} from "./types";

export const getCurrentUser = (): APICall<{
  data: CurrentUserType;
}> =>
  request({
    url: `/auth/me`,
    method: "GET",
  });

export const postLogin = (body: LoginPayloadType): APICall<LoginType> =>
  request({
    data: body,
    url: `/auth/login`,
    method: "POST",
  });

export const postRegisterVerification = (
  body: RegisterVerificationPayloadType
): APICall<null> =>
  request({
    data: body,
    url: `/auth/register-verification`,
    method: "POST",
  });

export const postRegister = (body: RegisterPayloadType): APICall<LoginType> =>
  request({
    data: body,
    url: `/auth/register`,
    method: "POST",
  });

export const postResetPasswordVerification = (
  body: ResetPasswordVerificationPayloadType
): APICall<null> =>
  request({
    data: body,
    url: `/auth/reset-password-verification`,
    method: "POST",
  });

export const postResetPassword = (
  body: ResetPasswordPayloadType
): APICall<null> =>
  request({
    data: body,
    url: `/auth/reset-password`,
    method: "POST",
  });

export const updateProfile = (
  body: EditUserType,
  config?: AxiosRequestConfig
): APICall<null> =>
  request({
    data: body,
    url: `/auth/me`,
    method: "PUT",
    ...config,
  });
