import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";

import theme from "theme";
import { renderAttachment } from "utils/media/renderMedia";

import { useStorageMedia } from "./useStorageMedia";

const StorageMedia = () => {
  const { storage } = useStorageMedia();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const xl = useMediaQuery(theme.breakpoints.down("xl"));

  const calculateCols = () => {
    if (sm) {
      return 2;
    } else if (md) {
      return 3;
    } else if (lg) {
      return 4;
    } else if (xl) {
      return 6;
    }
    return 8;
  };

  return (
    <Box sx={{ mx: 2 }}>
      {storage?.length ? (
        <ImageList variant="masonry" gap={5} cols={calculateCols()}>
          {storage?.map((attachment) => (
            <ImageListItem
              sx={{ border: "2px solid #EDEDED" }}
              key={attachment.name}
            >
              {renderAttachment(attachment)}
              <ImageListItemBar
                position="bottom"
                title={attachment.name}
                subtitle={attachment.type}
                sx={{
                  alignItems: "center",
                  backgroundColor: "#ededed",
                  color: "black",
                  ".MuiImageListItemBar-title": {
                    color: "#000",
                  },
                  ".MuiImageListItemBar-subtitle": {
                    lineHeight: 1.2,
                    color: (theme) => theme.palette.primary.main,
                  },
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <Box>
          <Typography sx={{ my: 2, opacity: 0.6 }}>Storage is empty</Typography>
        </Box>
      )}
    </Box>
  );
};

export default StorageMedia;
