import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { ElementType, FC, useEffect, useState } from "react";
import { Route, RouteProps, match, useHistory } from "react-router-dom";

import { axiosInstance } from "api/request";
import { getCurrentUser } from "api/user";
import { userKeys } from "api/user/queries";
import { UserOrgTypeRoleType } from "api/user/types";
import DefaultLayout from "components/layouts/DefaultLayout";
import { LayoutSplashScreen } from "components/providers/SplashScreenProvider";
// UTILS:
import {
  hasPermissions,
  hasRole,
  useUserStore,
} from "components/stores/UserStore";
import { PermissionsType } from "components/stores/UserStore/permissions";

interface Props extends RouteProps {
  computedMatch?: match;
  requiredPermissions?: PermissionsType[];
  requiredRole?: UserOrgTypeRoleType[];
  layout?: ElementType;
}

const PrivateRoute: FC<Props> = ({
  requiredPermissions = [],
  requiredRole = [],
  layout: Layout = DefaultLayout,
  ...rest
}) => {
  const history = useHistory();
  const [hasToken, setHasToken] = useState<boolean>(false);
  const [user, setToken, setUser] = useUserStore((s) => [
    s.user,
    s.setToken,
    s.setUser,
  ]);

  useQuery(
    userKeys.me(),
    async () => {
      const { data: currentUser } = await getCurrentUser();
      const user = currentUser?.data;

      setUser(user);
      return user;
    },
    {
      enabled: hasToken,
    }
  );

  useEffect(() => {
    const getTokenAndInjectItInHeader = async () => {
      try {
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("JWToken")}`;

        setHasToken(true);
      } catch (err) {
        console.error(err);
        localStorage.removeItem("JWToken");
        history.push("/login");
      }
    };

    if (localStorage.getItem("JWToken")) {
      getTokenAndInjectItInHeader();
    } else history.push("/login");
  }, [history, setToken, setUser]);

  if (!!user) {
    if (hasPermissions(requiredPermissions) && hasRole(requiredRole)) {
      return (
        <Layout>
          <Route {...rest} />
        </Layout>
      );
    } else {
      return (
        <Layout>
          <Box>You don't have access to this page</Box>
        </Layout>
      );
    }
  }

  return <LayoutSplashScreen />;
};

export default PrivateRoute;
