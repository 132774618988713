import {
  PersonAddAlt1 as PersonAddAlt1Icon,
  PersonRemove as PersonRemoveIcon,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { userKeys } from "api/user/queries";
import { followUser as followUserAPI } from "api/users";
import { usersKeys } from "api/users/queries";
import { UsersType } from "api/users/types";
import { useUserStore } from "components/stores/UserStore";
import UserAvatar from "components/UserAvatar";
import { getUserRole } from "utils/user/userRole";

interface IUserProfile extends UsersType {
  isMyProfile: boolean;
  activeTab: "posts" | "settings";
}

const UserProfile: FC<IUserProfile> = ({
  isMyProfile,
  activeTab,
  ...user
}: IUserProfile) => {
  const history = useHistory();
  const [me] = useUserStore((s) => [s.user]);
  const { _id, name, followers } = user;
  const queryClient = useQueryClient();

  const following = useMemo(() => {
    return followers.includes(me?._id ?? "");
  }, [followers, me]);

  const { mutateAsync: followUser, isLoading: isFollowLoading } = useMutation(
    async (data: string) => {
      const res = await followUserAPI(data);
      await queryClient.invalidateQueries(usersKeys.details(_id));
      await queryClient.invalidateQueries(userKeys.me());
      return res.data;
    }
  );
  const handleFollowUser = async () => {
    await followUser(_id);
  };

  return (
    <>
      <Paper sx={{ position: "relative", px: 5, py: 3 }}>
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            height: "50%",
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            backgroundSize: "cover",
            backgroundImage: "url('/media/login-info-bg.png')",
          }}
        ></Box>

        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="end" spacing={1.5}>
            <UserAvatar
              name={name}
              size={120}
              sx={{
                textAlign: "center",
                backgroundColor: (theme) => theme.palette.primary.main,
                border: "2px solid #fff",
              }}
            />
            <Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {name}
              </Typography>
              <Typography sx={{ mb: 0.5, lineHeight: 1.2 }}>
                {getUserRole(user)}
              </Typography>
            </Box>
          </Stack>

          {!isMyProfile && (
            <Stack direction="row" alignItems="end">
              {following ? (
                <LoadingButton
                  variant="contained"
                  size="medium"
                  sx={{
                    mb: 1,
                    width: "150px",
                    color: "#000",
                    backgroundColor: "#EEE",
                    "&:hover": {
                      backgroundColor: "#e6e6e6",
                    },
                  }}
                  onClick={handleFollowUser}
                  loading={isFollowLoading}
                >
                  <PersonRemoveIcon sx={{ mr: 1 }} />
                  Unfollow
                </LoadingButton>
              ) : (
                <LoadingButton
                  variant="contained"
                  size="medium"
                  sx={{ mb: 1, width: "150px" }}
                  onClick={handleFollowUser}
                  loading={isFollowLoading}
                >
                  <PersonAddAlt1Icon sx={{ mr: 1 }} />
                  Follow
                </LoadingButton>
              )}
            </Stack>
          )}
        </Stack>
      </Paper>

      {isMyProfile && (
        <>
          <Divider />
          <Paper>
            <Stack>
              <Tabs value={activeTab} onChange={() => {}}>
                <Tab
                  label="Posts"
                  value="posts"
                  onClick={() => history.push(`/user/${user._id}`)}
                  sx={{
                    fontSize: "16px",
                    mr: 1,
                    "&.Mui-selected": {
                      color: "#000",
                    },
                  }}
                />
                <Tab
                  label="Settings"
                  value="settings"
                  onClick={() => history.push(`/user/${user._id}/settings`)}
                  sx={{
                    fontSize: "16px",
                    "&.Mui-selected": {
                      color: "#000",
                    },
                  }}
                />
              </Tabs>
            </Stack>
          </Paper>
        </>
      )}
    </>
  );
};

export default UserProfile;
