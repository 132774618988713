import { LoadingButton } from "@mui/lab";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink, useHistory } from "react-router-dom";
import * as yup from "yup";

import { postResetPassword, postResetPasswordVerification } from "api/user";
import {
  ResetPasswordPayloadType,
  ResetPasswordVerificationPayloadType,
} from "api/user/types";
import FormikInputField from "components/forms/FormikInputField";
import EmailCodeModal from "containers/register-participant/components/EmailCodeModal";
import { useModal } from "utils/hooks/useModal";

const ForgotPasswordForm = () => {
  const initialValues = {
    email: "",
    password: "",
    repeatPassword: "",
  };
  const schema = yup.object({
    email: yup.string().required().email(),
    password: yup.string().required().min(4),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const intl = useIntl();
  const history = useHistory();

  const { mutateAsync: resetPasswordVerification, isLoading } = useMutation(
    async (values: ResetPasswordVerificationPayloadType) => {
      await postResetPasswordVerification(values);
    }
  );

  const handleSubmitVerification = async (
    values: ResetPasswordVerificationPayloadType
  ) => {
    await resetPasswordVerification(values, {
      onSuccess: () => handleOpenCodeModal(),
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleSubmitVerification,
    enableReinitialize: true,
  });

  const { values: formikValues } = formik;

  const {
    isOpen: isOpenCodeModal,
    handleClose: handleCloseCodeModal,
    handleOpen: handleOpenCodeModal,
  } = useModal();

  const { mutateAsync: resetPassword } = useMutation(
    async (values: ResetPasswordPayloadType) => {
      const { data: registerData } = await postResetPassword(values);

      return registerData;
    }
  );

  const handleSubmit = async (code: string) => {
    await resetPassword(
      { ...formikValues, code },
      {
        onSuccess: () => history.push("/login"),
      }
    );
  };

  return (
    <>
      <EmailCodeModal
        email={formikValues.email}
        open={isOpenCodeModal}
        handleClose={handleCloseCodeModal}
        onSubmit={handleSubmit}
        isResendLoading={isLoading}
        resend={() => handleSubmitVerification(formikValues)}
      />
      <Box
        sx={{
          maxWidth: "420px",
          width: "100%",
          minHeight: "600px",
        }}
      >
        <FormikProvider value={formik}>
          <FormikForm data-cy="forgot-password-form" style={{ height: "100%" }}>
            <Stack
              direction="column"
              justifyContent="space-between"
              sx={{ height: "100%" }}
            >
              <Box>
                <Box>
                  <Typography variant="h1" sx={{ fontSize: "30px", mb: 2 }}>
                    <FormattedMessage id="FORGOT_PASSWORD.FORM.TITLE" />
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    <FormattedMessage id="FORGOT_PASSWORD.FORM.DESCRIPTION" />
                  </Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                  <Box sx={{ mb: 1.5 }}>
                    <FormikInputField
                      name="email"
                      type="email"
                      labelTitle={
                        intl.formatMessage({
                          id: "FORGOT_PASSWORD.FORM.EMAIL",
                        }) + "*"
                      }
                      placeholder={intl.formatMessage({
                        id: "FORGOT_PASSWORD.FORM.EMAIL.PLACEHOLDER",
                      })}
                      data-cy="email-input"
                    />
                  </Box>
                  <Box sx={{ mb: 1.5 }}>
                    <FormikInputField
                      name="password"
                      type="password"
                      labelTitle={
                        intl.formatMessage({
                          id: "FORGOT_PASSWORD.FORM.NEW_PASSWORD",
                        }) + "*"
                      }
                      placeholder={intl.formatMessage({
                        id: "FORGOT_PASSWORD.FORM.NEW_PASSWORD.PLACEHOLDER",
                      })}
                      data-cy="password-input"
                    />
                  </Box>
                  <Box sx={{ mb: 1.5 }}>
                    <FormikInputField
                      name="repeatPassword"
                      type="password"
                      labelTitle={
                        intl.formatMessage({
                          id: "FORGOT_PASSWORD.FORM.REPEAT_PASSWORD",
                        }) + "*"
                      }
                      placeholder={intl.formatMessage({
                        id: "FORGOT_PASSWORD.FORM.REPEAT_PASSWORD.PLACEHOLDER",
                      })}
                      data-cy="new-password-input"
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <LoadingButton
                  sx={{ mt: 4.5, py: 2 }}
                  type="submit"
                  variant="contained"
                  fullWidth
                  loading={isLoading}
                >
                  <FormattedMessage id="FORGOT_PASSWORD.FORM.BUTTON" />
                </LoadingButton>
                <Box sx={{ mt: 1, textAlign: "center" }}>
                  <Link
                    component={RouterLink}
                    to={`/login`}
                    sx={{ textDecoration: "none" }}
                  >
                    <FormattedMessage id="FORGOT_PASSWORD.FORM.BACK_TO_LOGIN" />
                  </Link>
                </Box>
              </Box>
            </Stack>
          </FormikForm>
        </FormikProvider>
      </Box>
    </>
  );
};

export default ForgotPasswordForm;
