import { useQuery } from "@tanstack/react-query";

import { getPinnedPosts } from "api/posts";
import { postsKeys } from "api/posts/queries";

export const usePinnedPosts = () => {
  const { data: pinnedPostsData, isLoading } = useQuery(
    postsKeys.pinnedPosts(),
    async () => {
      const { data: res } = await getPinnedPosts();
      return res;
    }
  );

  return {
    pinnedPosts: pinnedPostsData?.data,
    isLoading,
  };
};
