import { useQuery } from "@tanstack/react-query";

import { getStorage } from "api/storage";
import { storageKeys } from "api/storage/queries";

export const useStorageMedia = () => {
  const { data: storageData } = useQuery(storageKeys.list(), async () => {
    const { data: res } = await getStorage();
    return res;
  });
  const storage = storageData?.data;

  return {
    storage,
  };
};
