import { alpha } from "@material-ui/core";
import { AttachFile as AttachFileIcon } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";

import { AttachmentsType } from "api/storage/types";

export type FileWithPreview = File & {
  preview?: string;
};

export const renderAttachment = (attachment: AttachmentsType) => {
  let element = null;

  const path = `${process.env.REACT_APP_API_URL?.replace("api", "")}${
    attachment.path
  }`;

  if (attachment.type.includes("image/")) {
    element = (
      <img
        src={path}
        alt={attachment.name}
        loading="lazy"
        style={{ width: "100%", maxWidth: "100%" }}
      />
    );
  } else if (attachment.type.includes("video/")) {
    element = (
      <video src={path} controls style={{ width: "100%", maxWidth: "100%" }} />
    );
  } else if (attachment.type.includes("audio/")) {
    element = (
      <audio src={path} controls style={{ width: "100%", maxWidth: "100%" }} />
    );
  } else {
    element = (
      <Stack
        sx={{
          py: 2,
          maxWidth: "100%",
          boxSizing: "border-box",
          px: 2,
          cursor: "pointer",
          bgcolor: "#fff",
          transition: "background-color 150ms ease-out",

          "&:hover": {
            bgcolor: (t) => alpha(t.palette.primary.main, 0.1),
          },
        }}
        direction="row"
        alignItems="center"
        onClick={() => {
          window.open(path, "_blank");
        }}
      >
        <AttachFileIcon sx={{ mr: 1.5 }} />
        <Typography
          variant="body2"
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {attachment.name}
        </Typography>
      </Stack>
    );
  }

  return <Box sx={{ maxWidth: "100%" }}>{element}</Box>;
};

export const renderMedia = (file: FileWithPreview) => {
  let element = null;

  if (file.type.includes("image/")) {
    element = (
      <img
        src={file.preview}
        alt={file.name}
        loading="lazy"
        style={{ width: "100%", maxWidth: "100%" }}
      />
    );
  } else if (file.type.includes("video/")) {
    element = (
      <video
        src={file.preview}
        controls
        style={{ width: "100%", maxWidth: "100%" }}
      />
    );
  } else if (file.type.includes("audio/")) {
    element = (
      <audio
        src={file.preview}
        controls
        style={{ width: "100%", maxWidth: "100%" }}
      />
    );
  } else {
    element = (
      <Stack
        sx={{
          py: 2,
          maxWidth: "100%",
          boxSizing: "border-box",
          px: 2,
        }}
        direction="row"
        alignItems="center"
      >
        <AttachFileIcon sx={{ mr: 1.5 }} />
        <Typography variant="body2">{file.name}</Typography>
      </Stack>
    );
  }

  return <Box sx={{ maxWidth: "100%" }}>{element}</Box>;
};
