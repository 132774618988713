import {
  AccountCircleTwoTone as AccountCircleTwoToneIcon,
  DescriptionTwoTone as DescriptionTwoToneIcon,
  EmojiEventsTwoTone as EmojiEventsTwoToneIcon,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import React from "react";

import AdminDashboardStats from "./components/AdminDashboardStats";

const AdminDashboard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <AdminDashboardStats
          title="Total Users"
          total={1658}
          icon={AccountCircleTwoToneIcon}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <AdminDashboardStats
          title="Published Posts"
          total={4230}
          icon={DescriptionTwoToneIcon}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <AdminDashboardStats
          title="Monthly Visitors"
          total={13456}
          icon={EmojiEventsTwoToneIcon}
        />
      </Grid>
    </Grid>
  );
};

export default AdminDashboard;
