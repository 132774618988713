import { useState } from "react";

import { getCordinatesAddress } from "api/location";
import { useModal } from "utils/hooks/useModal";

import { SearchLocationType } from "./Search";

export const useSearchLocation = () => {
  const {
    isOpen: isOpenLocationSearch,
    handleClose: handleCloseLocationSearch,
    handleOpen: handleOpenLocationSearch,
  } = useModal();

  const [searchLocation, setSearchLocation] = useState<SearchLocationType>({
    type: null,
    location: null,
    radius: 20,
  });

  const resetSearchLocation = () => {
    setSearchLocation((p) => ({
      type: null,
      location: null,
      radius: p.radius,
    }));
  };

  const getMyLocation = async () => {
    if (navigator.geolocation) {
      const getPosition: () => Promise<{ lat: number; lng: number }> = () => {
        return new Promise(function (resolve, reject) {
          navigator.geolocation.getCurrentPosition(function ({ coords }) {
            resolve({ lat: coords.latitude, lng: coords.longitude });
          });
        });
      };
      const { lat, lng } = await getPosition();
      const { data: addressData } = await getCordinatesAddress(lat, lng);

      await setSearchLocation((p) => ({
        type: "my-location",
        location: addressData?.data ?? "",
        radius: p.radius,
      }));
    }
  };

  const getDifferentLocation = () => {
    handleOpenLocationSearch();

    // setSearchLocation((p) => ({
    //   type: "different-location",
    //   location: `Vojvode Stepe 12`,
    //   radius: p.radius,
    // }));
  };

  const setSearchRadius = (radius: number) => {
    setSearchLocation((p) => ({ ...p, radius }));
  };

  return {
    searchLocation,
    setSearchLocation,
    resetSearchLocation,
    getMyLocation,
    getDifferentLocation,
    setSearchRadius,

    isOpenLocationSearch,
    handleCloseLocationSearch,
  };
};
