import { Chip } from "@mui/material";
import React from "react";

import { User as UserGenerated } from "generated-types";

const roleFormatter = (role: UserGenerated.role) => {
  switch (role) {
    case UserGenerated.role.ADMIN:
      return <Chip size="small" label="Admin" color="error" />;
    case UserGenerated.role.BUSINESS:
      return <Chip size="small" label="Business" color="primary" />;
    // case UserGenerated.role.PARTICIPANT:
    //   return <Chip size="small" label="Participant" color="default" />;
    default:
      return null;
  }
};

export { roleFormatter };
