import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { postSearchPosts as postSearchPostsAPI } from "api/posts";
import { postsKeys } from "api/posts/queries";
import { PostCategoriesType } from "containers/dashboard/components/CreatePost/useCreatePost";

export enum SearchTypes {
  EMPLOYES = "employees",
  CUSTOMERS = "customers",
  GENERAL = "general",
}

export const SearchTypesCategories = {
  [SearchTypes.GENERAL]: PostCategoriesType.GENERAL_CONVERSATION,
  [SearchTypes.EMPLOYES]: PostCategoriesType.EMPLOYEE_INTERACTION,
  [SearchTypes.CUSTOMERS]: PostCategoriesType.CUSTOMER_INTERACTION,
};

export const useSearchSuggestion = (q: string, display: boolean) => {
  const [searchType, setSearchType] = useState<SearchTypes>(
    SearchTypes.EMPLOYES
  );

  const { data: postsData } = useQuery(
    postsKeys.saerch(q, SearchTypesCategories[searchType]),
    async () => {
      const { data: res } = await postSearchPostsAPI(
        q,
        SearchTypesCategories[searchType]
      );
      return res;
    },
    {
      enabled: display,
    }
  );
  const posts = postsData?.data?.slice(0, 3);

  return {
    posts,
    searchType,
    setSearchType,
  };
};
