import { PostCategoriesType } from "containers/dashboard/components/CreatePost/useCreatePost";

const postsKeys = {
  all: ["posts"] as const,
  list: () => [...postsKeys.all, "list"],
  listFiltered: (filters: Record<string, unknown>) =>
    [...postsKeys.all, "list", filters] as const,
  categoryCount: () => [...postsKeys.all, "category-count"],
  pinnedPosts: () => [...postsKeys.all, "pinned"],
  statistics: () => [...postsKeys.all, "statistics"],
  saerch: (q: string, category: PostCategoriesType) =>
    [...postsKeys.all, "search", q, category] as const,
};

export { postsKeys };
