import {
  Box,
  ButtonBase,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

import UserAvatar from "components/UserAvatar";

const NotificationLoading = () => {
  return (
    <Box width="100%">
      <Stack
        component={ButtonBase}
        direction="row"
        alignItems="start"
        sx={{
          px: 3,
          pt: 2,
          pb: 1.5,
          textAlign: "left",
        }}
        spacing={1}
        width="100%"
        justifyContent="flex-start"
      >
        <UserAvatar
          name={""}
          size={40}
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            mr: 1,
          }}
        />
        <Box>
          <Typography sx={{ fontSize: "16px", lineHeight: 1.2, mb: 0.5 }}>
            <Skeleton width="200px" />
          </Typography>
          <Typography
            sx={{
              lineHeight: 1.2,
            }}
            variant="body2"
          >
            <Skeleton width="200px" />
          </Typography>
        </Box>
      </Stack>
      <Divider sx={{ mx: 3 }} />
    </Box>
  );
};

export default NotificationLoading;
