import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Formik, Form as FormikForm } from "formik";
import { FC } from "react";
import * as yup from "yup";

import FormikInputField from "components/forms/FormikInputField";
import FormikPhoneField from "components/forms/FormikPhoneField";
import FormikSelectField from "components/forms/FormikSelectField";

export type GeneralSettingsFormValues = {
  name: string;
  email: string;
  role: "admin" | "business" | null;
  phone: string;
  line: string | undefined;
  state: string | undefined;
  zip: string | undefined;
  lat: string | undefined;
  lng: string | undefined;
};

interface IGeneralSettingsForm {
  initialValues?: GeneralSettingsFormValues;
  submitStatus?: MutationStatus;
  handleSubmit: (values: GeneralSettingsFormValues) => void;
}

const GeneralSettingsForm: FC<IGeneralSettingsForm> = ({
  initialValues = {
    name: "",
    email: "",
    role: "business",
    phone: "",
    line: "",
    state: "",
    zip: "",
    lat: "",
    lng: "",
  },
  submitStatus,
  handleSubmit,
}) => {
  const schema = yup.object({
    name: yup.string().required(),
    email: yup.string().required(),
    phone: yup.string().optional(),
  });

  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "business", label: "Business" },
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        <FormikForm>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item md={6} xs={12}>
              <FormikInputField
                labelTitle={
                  initialValues.role === "business"
                    ? "Legal Business Name"
                    : "Full Name"
                }
                placeholder="John Doe"
                name="name"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikInputField
                labelTitle="Email"
                placeholder="johndoe@example.com"
                name="email"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikSelectField
                labelTitle="Role"
                name="role"
                options={roleOptions}
                disabled
                sx={{
                  mt: "-8px",
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikPhoneField labelTitle="Phone" name="phone" />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikInputField
                labelTitle="Address"
                placeholder="Enter Address"
                name="line"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikInputField
                labelTitle="State"
                placeholder="Enter State"
                name="state"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikInputField
                labelTitle="ZIP"
                placeholder="Enter ZIP"
                name="zip"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <FormikInputField labelTitle="Latitude" name="lat" disabled />
            </Grid>
            <Grid item md={3} xs={12}>
              <FormikInputField labelTitle="Longitude" name="lng" disabled />
            </Grid>
          </Grid>
          <LoadingButton
            sx={{ py: 1, px: 4, mt: 3 }}
            type="submit"
            variant="contained"
            loading={submitStatus === "loading"}
          >
            Save
          </LoadingButton>
        </FormikForm>
      </Formik>
    </>
  );
};

export default GeneralSettingsForm;
