import { LoadingButton } from "@mui/lab";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Formik, Form as FormikForm } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink, useHistory } from "react-router-dom";
import * as yup from "yup";

import { axiosInstance } from "api/request";
import { postLogin } from "api/user";
import { LoginPayloadType } from "api/user/types";
import FormikInputField from "components/forms/FormikInputField";

const LoginForm = () => {
  const initialValues = {
    email: "",
    password: "",
  };
  const schema = yup.object({
    email: yup.string().required().email(),
    password: yup.string().required(),
  });

  const intl = useIntl();

  const history = useHistory();

  const { mutateAsync: login, status } = useMutation(
    async (values: LoginPayloadType) => {
      const { data: loginData } = await postLogin(values);

      localStorage.setItem("JWToken", loginData?.token);
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${loginData?.token}`;
    }
  );

  const handleSubmit = async (values: LoginPayloadType) => {
    await login(values, {
      onSuccess: () => history.push("/"),
    });
  };

  return (
    <Box
      sx={{
        maxWidth: "420px",
        width: "100%",
        minHeight: "600px",
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <FormikForm data-cy="login-form" style={{ height: "100%" }}>
          <Stack
            direction="column"
            justifyContent="space-between"
            sx={{ height: "100%" }}
          >
            <Box>
              <Box>
                <Typography variant="h1" sx={{ fontSize: "30px", mb: 2 }}>
                  <FormattedMessage id="LOGIN.FORM.TITLE" />
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>
                  <FormattedMessage id="LOGIN.FORM.DESCRIPTION" />
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box>
                <Box sx={{ mb: 1.5 }}>
                  <FormikInputField
                    name="email"
                    type="email"
                    labelTitle="Email address*"
                    placeholder={intl.formatMessage({
                      id: "LOGIN.FORM.EMAIL.PLACEHOLDER",
                    })}
                    data-cy="email-input"
                  />
                </Box>
                <Box sx={{ mb: 1.5 }}>
                  <FormikInputField
                    name="password"
                    type="password"
                    labelTitle="Password*"
                    placeholder={intl.formatMessage({
                      id: "LOGIN.FORM.PASSWORD.PLACEHOLDER",
                    })}
                    data-cy="password-input"
                  />
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography component="span">
                    <FormattedMessage id="LOGIN.FORM.FORGOT_PASS" />{" "}
                  </Typography>
                  <Link
                    component={RouterLink}
                    to={`/forgot-password`}
                    sx={{ textDecoration: "none" }}
                    data-cy="forgot-password-button"
                  >
                    <FormattedMessage id="LOGIN.FORM.PASSWORD_RECOVERY" />
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box>
              <LoadingButton
                sx={{ mt: 4.5, py: 2 }}
                type="submit"
                variant="contained"
                fullWidth
                loading={status === "loading"}
                data-cy="login-button"
              >
                <FormattedMessage id="LOGIN.FORM.BUTTON" />
              </LoadingButton>
              <Box sx={{ mt: 1, textAlign: "center" }}>
                <Link
                  component={RouterLink}
                  to={`/register`}
                  sx={{ textDecoration: "none" }}
                >
                  <FormattedMessage id="LOGIN.FORM.SIGN_UP" />
                </Link>
              </Box>
            </Box>
          </Stack>
        </FormikForm>
      </Formik>
    </Box>
  );
};

export default LoginForm;
