import {
  Box,
  ButtonBase,
  Divider,
  Link,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { bindPopover } from "material-ui-popup-state";
import { PopupState as IPopupState } from "material-ui-popup-state/core";
import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

import { NotificationsType } from "api/notifications/types";
import UserAvatar from "components/UserAvatar";

import { generateComment } from "./generateComment";
dayjs.extend(relativeTime);

interface INotificationsPopover {
  popupState: IPopupState;
  notifications: NotificationsType[] | undefined;
}
const NotificationsPopover: FC<INotificationsPopover> = ({
  popupState,
  notifications,
}) => {
  return (
    <Popover
      BackdropProps={{
        invisible: true,
      }}
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        ".MuiPaper-root": {
          boxShadow: "0px 2px 20px 0px #00000020",
        },
      }}
    >
      <Box sx={{ py: 2, width: "300px" }}>
        <Stack
          direction="row"
          sx={{ mb: 2, px: 2 }}
          justifyContent="space-between"
        >
          <Typography variant="body2">Notifications</Typography>

          <Link
            component={RouterLink}
            to="/notifications"
            sx={{
              fontSize: "14px",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => popupState.close()}
          >
            see all
          </Link>
        </Stack>

        {notifications?.length ? (
          <Divider sx={{ mx: 2 }} />
        ) : (
          <Box>
            <Typography variant="body2" sx={{ mx: 2, opacity: 0.6 }}>
              No new notifications
            </Typography>
          </Box>
        )}
        <Stack>
          {notifications?.map((notification) => (
            <Box key={notification._id}>
              <Stack
                component={ButtonBase}
                direction="row"
                alignItems="start"
                sx={{
                  px: 2,
                  pt: 1,
                  pb: 0.5,
                  textAlign: "left",
                  backgroundColor: notification.opened ? "#FFF" : "#E7F3FE",
                }}
                spacing={1}
              >
                <UserAvatar
                  name={notification.target.name}
                  size={32}
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                  }}
                />
                <Box>
                  <Typography
                    sx={{ fontSize: "14px", lineHeight: 1.2, mb: 0.5 }}
                  >
                    {generateComment(notification)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      lineHeight: 1.2,
                      textAlign: "right",
                    }}
                  >
                    {dayjs(notification.createdAt).fromNow()}
                  </Typography>
                </Box>
              </Stack>
              <Divider sx={{ mx: 2 }} />
            </Box>
          ))}
        </Stack>
      </Box>
    </Popover>
  );
};

export default NotificationsPopover;
