import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import { WithChildren } from "utils/types";

interface ILoginLayout extends WithChildren {
  title?: ReactNode | string;
}

const LoginLayout: FC<ILoginLayout> = ({ title, children }) => {
  return (
    <Grid minHeight="100vh" container>
      <Grid
        item
        lg={5}
        xs={12}
        order={{ lg: 1, xs: 2 }}
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          backgroundSize: "cover",
          backgroundImage: "url('/media/login-info-bg.png')",
        }}
      >
        <Stack
          direction="column"
          py={5}
          px={3}
          height="100%"
          width="100%"
          justifyContent="space-between"
          mx="auto"
        >
          <Box
            sx={{
              maxWidth: { lg: "80%", xs: "100%" },
              width: "100%",
              mb: { lg: 0, xs: 5 },
            }}
            mx="auto"
          >
            <Link
              component={RouterLink}
              to="/login"
              color="#fff"
              sx={{ fontWeight: "bold", textDecoration: "none" }}
            >
              KAREN
            </Link>
          </Box>
          {title ? (
            title
          ) : (
            <Box sx={{ maxWidth: "340px", width: "100%" }} mx="auto">
              <Typography variant="h1" color="#fff">
                <FormattedMessage id="LOGIN.TITLE" />
              </Typography>
            </Box>
          )}
          <Box sx={{ mt: 2 }}></Box>
        </Stack>
      </Grid>
      <Grid item lg={7} xs={12} order={{ lg: 2, xs: 1 }}>
        <Stack
          direction="column"
          py={3}
          px={3}
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          {children}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default LoginLayout;
