import { Search as SearchIcon } from "@mui/icons-material";
import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Cell, Column } from "react-table";

import { getUsers } from "api/users";
import { usersKeys } from "api/users/queries";
import { UsersType } from "api/users/types";
import Table from "components/Table";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";

// import { hasPermissions } from "components/stores/UserStore";
// import UsersActionsFormatter from "./formatters/UsersActionsFormatter";
// import DeletePartnerModal from "./components/DeletePartnerModal";
// import { useModal } from "utils/hooks/useModal";
// import EditPartnerModal from "./components/EditPartnerModal";

import { confirmationFormatter } from "./components/formatters/confirmationFormatter";
import { roleFormatter } from "./components/formatters/roleFormatter";
import { useUsersFilter } from "./useUsersFilter";

const UsersTable: FC = () => {
  const intl = useIntl();

  // const {
  //   isOpen: isOpenDelete,
  //   handleClose: handleCloseDelete,
  //   handleOpen: handleOpenDelete,
  //   context: deleteContext,
  // } = useModal<UsersType>();
  // const {
  //   isOpen: isEditOpen,
  //   handleClose: handleCloseEdit,
  //   handleOpen: handleOpenEdit,
  //   context: editContext,
  // } = useModal<UsersType>();

  const { filters, searchField } = useUsersFilter();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    usersKeys.list(),
    getUsers,
    filters
  );

  const columns: Column<UsersType>[] = [
    {
      accessor: "role",
      Header: intl.formatMessage({ id: "ADMIN_USERS.TABLE.ROLE" }),
      Cell: ({ value }: Cell<UsersType, UsersType["role"]>) =>
        roleFormatter(value),
    },
    {
      accessor: "name",
      Header: intl.formatMessage({ id: "ADMIN_USERS.TABLE.NAME" }),
    },
    {
      accessor: "email",
      Header: intl.formatMessage({ id: "ADMIN_USERS.TABLE.EMAIL" }),
    },
    {
      accessor: "confirmed",
      Header: intl.formatMessage({ id: "ADMIN_USERS.TABLE.CONFIRMED" }),
      Cell: ({ value }: Cell<UsersType, UsersType["confirmed"]>) =>
        confirmationFormatter(value),
    },

    // {
    //   Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
    //   Cell: (cell: CellProps<UsersType>) => (
    //     <UsersActionsFormatter
    //       {...cell}
    //       handleOpenEdit={handleOpenEdit}
    //       handleOpenDelete={handleOpenDelete}
    //     />
    //   ),
    //   align: "right",
    // },
  ];

  return (
    <>
      <Grid container sx={{ pt: 1, px: 2 }} spacing={2}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="ADMIN_USERS.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          {/* <Select
            fullWidth
            label={<FormattedMessage id="ADMIN_USERS.TABLE.SEARCH_ROLE" />}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <SearchIcon />
            //     </InputAdornment>
            //   ),
            // }}
            // helperText={<FormattedMessage id="ADMIN_USERS.TABLE.SEARCH_BY" />}
            // {...searchField}
          /> */}
        </Grid>
      </Grid>

      <Table
        data={data}
        columns={columns}
        sort={sortOptions}
        pagination={paginationOptions}
        status={status}
      />

      {/* {hasPermissions("delete:partners") && (
        <>
          <DeletePartnerModal
            isOpen={isOpenDelete}
            handleClose={handleCloseDelete}
            partner={deleteContext}
          />
        </>
      )}
      {hasPermissions("update:partners") && (
        <>
          <EditPartnerModal
            isOpen={isEditOpen}
            handleClose={handleCloseEdit}
            partner={editContext}
          />
        </>
      )} */}
    </>
  );
};

export default UsersTable;
