import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Formik, Form as FormikForm } from "formik";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";

import FormikInputField from "components/forms/FormikInputField";
import FormikSelectField from "components/forms/FormikSelectField";

export type CompanySettingsFormValues = {
  industry: string | null;
  companyType: string | null;
  companyAdministrator: string | undefined;
};

interface ICompanySettingsForm {
  initialValues?: CompanySettingsFormValues;
  submitStatus?: MutationStatus;
  handleSubmit: (values: CompanySettingsFormValues) => void;
}

const CompanySettingsForm: FC<ICompanySettingsForm> = ({
  initialValues = {
    industry: "",
    companyType: "",
    companyAdministrator: "",
  },
  submitStatus,
  handleSubmit,
}) => {
  const intl = useIntl();

  const schema = yup.object({
    industry: yup.string().required(),
    companyType: yup.string().required(),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        <FormikForm>
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item md={6} xs={12}>
              <FormikInputField
                name="companyAdministrator"
                labelTitle={
                  <FormattedMessage id="REGISTER_BUSINESS.BUSINESS_FORM.COMPANY_ADMINISTRATOR" />
                }
                placeholder={intl.formatMessage({
                  id: "REGISTER_BUSINESS.BUSINESS_FORM.FULLNAME.PLACEHOLDER",
                })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikSelectField
                name="industry"
                labelTitle={
                  <FormattedMessage id="REGISTER_BUSINESS.BUSINESS_FORM.INDUSTRY" />
                }
                options={[
                  { value: "aerospace", label: "Aerospace" },
                  { value: "agriculture", label: "Agriculture" },
                  { value: "computer", label: "Computer" },
                  { value: "construction", label: "Construction" },
                  { value: "education", label: "Education" },
                  { value: "electronics", label: "Electronics" },
                  { value: "energy", label: "Energy" },
                  { value: "entertainment", label: "Entertainment" },
                  { value: "food", label: "Food" },
                  { value: "healthcare", label: "Healthcare" },
                  { value: "hospitality", label: "Hospitality" },
                  { value: "manufacturing", label: "Manufacturing" },
                  { value: "mining", label: "Mining" },
                  { value: "music", label: "Music" },
                  { value: "news-media", label: "News Media" },
                  { value: "pharmaceutical", label: "Pharmaceutical" },
                  {
                    value: "telecommunication",
                    label: "Telecommunication",
                  },
                  { value: "transport", label: "Transport" },
                  { value: "www", label: "World Wide Web" },
                ]}
                placeholder={intl.formatMessage({
                  id: "REGISTER_BUSINESS.BUSINESS_FORM.INDUSTRY.PLACEHOLDER",
                })}
                sx={{
                  mt: "-8px",
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikSelectField
                name="companyType"
                labelTitle={
                  <FormattedMessage id="REGISTER_BUSINESS.BUSINESS_FORM.COMPANY_TYPE" />
                }
                options={[
                  { value: "retail", label: "Retail" },
                  { value: "service", label: "Service" },
                  { value: "manufacturing", label: "Manufacturing" },
                  { value: "wholesale", label: "Wholesale" },
                ]}
                placeholder={intl.formatMessage({
                  id: "REGISTER_BUSINESS.BUSINESS_FORM.COMPANY_TYPE.PLACEHOLDER",
                })}
                sx={{
                  mt: "-8px",
                }}
              />
            </Grid>
          </Grid>
          <LoadingButton
            sx={{ py: 1, px: 4, mt: 3 }}
            type="submit"
            variant="contained"
            loading={submitStatus === "loading"}
          >
            Save
          </LoadingButton>
        </FormikForm>
      </Formik>
    </>
  );
};

export default CompanySettingsForm;
