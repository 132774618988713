import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { getPosts } from "api/posts";
import { postsKeys } from "api/posts/queries";
import { PostsType } from "api/posts/types";
import { QueryParamsType } from "api/utils";

import { PostCategoriesType } from "./components/CreatePost/useCreatePost";

const sortOptions: { [key: string]: QueryParamsType<PostsType>["sort"] } = {
  recent: {
    field: "createdAt",
    order: "desc",
  },
  oldest: {
    field: "createdAt",
    order: "asc",
  },
};

export const useDashboard = () => {
  const [sortBy, setSortBy] = useState<"recent" | "oldest">("recent");
  const [filterBy, setFilterBy] = useState<PostCategoriesType | null>(null);

  let postsFilter: QueryParamsType<PostsType> = {
    sort: sortOptions[sortBy],
  };

  if (filterBy) {
    postsFilter = {
      ...postsFilter,
      filter: [
        {
          field: "category",
          operator: "eq",
          value: filterBy,
        },
      ],
    };
  }

  const { data: postsData, isLoading } = useQuery(
    postsKeys.listFiltered(postsFilter),
    async () => {
      const { data: res } = await getPosts(postsFilter);
      return res;
    }
  );

  return {
    sortBy,
    setSortBy,
    filterBy,
    setFilterBy,
    posts: postsData?.data,
    isLoading,
  };
};
