import {
  BookmarkBorder as BookmarkBorderIcon,
  BookmarksOutlined as BookmarksOutlinedIcon,
} from "@mui/icons-material";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";

import BackButton from "containers/register/components/BackButton";
import RegisterLinkIcon from "containers/register/components/RegisterLinkIcon";

interface ISubscriptionTabPanel {
  choosenPlan: "monthly" | "yearly";
  handleSelectSubscriptionPlan: (choosenPlan: "monthly" | "yearly") => void;
  isLoading: boolean;
}

const SubscriptionTabPanel: FC<ISubscriptionTabPanel> = ({
  choosenPlan,
  handleSelectSubscriptionPlan,
  isLoading,
}) => {
  if (choosenPlan === "monthly") {
    return (
      <>
        <RegisterLinkIcon
          onClick={() => !isLoading && handleSelectSubscriptionPlan("monthly")}
          title={"$12.00  / Monhtly"}
          description={
            isLoading
              ? "Payment form loading..."
              : "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet."
          }
          icon={BookmarkBorderIcon}
        />
      </>
    );
  } else {
    return (
      <>
        <RegisterLinkIcon
          onClick={() => !isLoading && handleSelectSubscriptionPlan("yearly")}
          title={"$100.00 / Yearly"}
          description={
            isLoading
              ? "Payment form loading..."
              : "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet."
          }
          icon={BookmarksOutlinedIcon}
        />
      </>
    );
  }
};

interface ISubscriptionPlanForm {
  handleSelectSubscriptionPlan: (choosenPlan: "monthly" | "yearly") => void;
  isLoading: boolean;
}

const SubscriptionPlanForm: FC<ISubscriptionPlanForm> = ({
  handleSelectSubscriptionPlan,
  isLoading,
}) => {
  const [tabValue, setTabValue] = useState<"monthly" | "yearly">("monthly");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue as "monthly" | "yearly");
  };
  return (
    <>
      <Box sx={{ maxWidth: "680px", width: "100%", mb: 4 }}>
        <BackButton />
      </Box>
      <Box
        sx={{
          maxWidth: "420px",
          width: "100%",
          minHeight: "600px",
        }}
      >
        <Stack direction="column" sx={{ height: "100%" }}>
          <Box>
            <Box>
              <Typography variant="h1" sx={{ fontSize: "30px", mb: 2 }}>
                <FormattedMessage id="REGISTER_BUSINESS.SUBSCRIPTION_PLAN.TITLE" />
              </Typography>
              <Typography sx={{ fontSize: "18px" }}>
                <FormattedMessage id="REGISTER_BUSINESS.SUBSCRIPTION_PLAN.DESCRIPTION" />
              </Typography>
            </Box>

            <Box>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={{ my: 2, mb: 3 }}
              >
                <Tab
                  label="Monthly Subscription"
                  value="monthly"
                  sx={{
                    fontSize: "18px",
                    px: 1,
                    py: 2,
                    mr: 1,
                    "&.Mui-selected": {
                      color: "#000",
                    },
                  }}
                />
                <Tab
                  label="Yearly Subscription"
                  value="yearly"
                  sx={{
                    fontSize: "18px",
                    px: 1,
                    py: 2,
                    "&.Mui-selected": {
                      color: "#000",
                    },
                  }}
                />
              </Tabs>
            </Box>

            <Box>
              <SubscriptionTabPanel
                isLoading={isLoading}
                choosenPlan={tabValue}
                handleSelectSubscriptionPlan={handleSelectSubscriptionPlan}
              />
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default SubscriptionPlanForm;
