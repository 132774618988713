import React, { FC } from "react";
import { IntlProvider } from "react-intl";

import enMessages from "translations/en.json";
import fallbackTranslations from "utils/translations/fallbackTranslations";
import { WithChildren } from "utils/types";

import { useLang } from "./I18nContextProvider";
import "components/forms/yupErrorMessages";

export const allMessages = {
  en: enMessages,
};

const I18nProvider: FC<WithChildren> = ({ children }) => {
  const locale = useLang();

  // in case there is untranslated message, fallback it to english
  const messages = fallbackTranslations(allMessages[locale], enMessages);

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default I18nProvider;
