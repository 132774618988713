/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { User } from "./User";

export type Notification = {
  /**
   * Notification ID.
   * @example 507f191e810c19729de860ea
   */
  _id: string;
  /**
   * Notification Reciever.
   */
  user: User;
  /**
   * Notification Target.
   */
  target: User;
  /**
   * Notification Type.
   */
  type?: Notification.type;
  /**
   * Notification Opened.
   */
  opened: boolean;
  /**
   * Notification Creation Date.
   */
  createdAt: string;
  /**
   * Notification Latest Update Date.
   */
  updatedAt: string;
};

export namespace Notification {
  /**
   * Notification Type.
   */
  export enum type {
    COMMENT = "comment",
  }
}
