import { LoadingButton } from "@mui/lab";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Formik, Form as FormikForm } from "formik";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";

import { updateProfile as updateProfileAPI } from "api/user";
import FormikInputField from "components/forms/FormikInputField";
import FormikPhoneField from "components/forms/FormikPhoneField";
import BackButton from "containers/register/components/BackButton";

interface ICompleteProfileForm {
  handleNextStep: () => void;
}
const CompleteProfileForm: FC<ICompleteProfileForm> = ({ handleNextStep }) => {
  const initialValues = {
    line: "",
    state: "",
    zip: "",
    phone: "",
  };

  const schema = yup.object({
    line: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().required(),
    phone: yup.string().required(),
  });

  const intl = useIntl();

  const { mutateAsync: updateProfile, isLoading } = useMutation(
    async (values: any) => {
      const { data: profileData } = await updateProfileAPI(values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("JWToken_business")}`,
        },
      });

      return profileData;
    }
  );

  const handleSubmit = async (values: any) => {
    await updateProfile(
      { ...values },
      {
        onSuccess: () => handleNextStep(),
      }
    );
  };

  return (
    <>
      <Box sx={{ maxWidth: "680px", width: "100%", mb: 4 }}>
        <BackButton />
      </Box>
      <Box
        sx={{
          maxWidth: "420px",
          width: "100%",
          minHeight: "600px",
        }}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          <FormikForm data-cy="login-form" style={{ height: "100%" }}>
            <Stack
              direction="column"
              justifyContent="space-between"
              sx={{ height: "100%" }}
            >
              <Box>
                <Box>
                  <Typography variant="h1" sx={{ fontSize: "30px", mb: 2 }}>
                    <FormattedMessage id="REGISTER_BUSINESS.COMPLETE_PROFILE.TITLE" />
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    <FormattedMessage id="REGISTER_BUSINESS.COMPLETE_PROFILE.DESCRIPTION" />
                  </Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                  <Box sx={{ mb: 1.5 }}>
                    <FormikInputField
                      name="line"
                      labelTitle={
                        <FormattedMessage id="REGISTER_BUSINESS.COMPLETE_PROFILE.ADDRESS" />
                      }
                      placeholder={intl.formatMessage({
                        id: "REGISTER_BUSINESS.COMPLETE_PROFILE.ADDRESS.PLACEHOLDER",
                      })}
                    />
                  </Box>
                  <Box sx={{ mb: 1.5 }}>
                    <FormikInputField
                      name="state"
                      labelTitle={
                        <FormattedMessage id="REGISTER_BUSINESS.COMPLETE_PROFILE.STATE" />
                      }
                      placeholder={intl.formatMessage({
                        id: "REGISTER_BUSINESS.COMPLETE_PROFILE.STATE.PLACEHOLDER",
                      })}
                    />
                  </Box>
                  <Box sx={{ mb: 1.5 }}>
                    <FormikInputField
                      name="zip"
                      labelTitle={
                        <FormattedMessage id="REGISTER_BUSINESS.COMPLETE_PROFILE.ZIP" />
                      }
                      placeholder={intl.formatMessage({
                        id: "REGISTER_BUSINESS.COMPLETE_PROFILE.ZIP.PLACEHOLDER",
                      })}
                    />
                  </Box>
                  <FormikPhoneField
                    name="phone"
                    labelTitle={
                      <FormattedMessage id="REGISTER_BUSINESS.COMPLETE_PROFILE.PHONE" />
                    }
                  />
                </Box>
              </Box>
              <Box>
                <LoadingButton
                  sx={{ mt: 4.5, py: 2 }}
                  type="submit"
                  variant="contained"
                  fullWidth
                  loading={isLoading}
                >
                  <FormattedMessage id="REGISTER_BUSINESS.COMPLETE_PROFILE.BUTTON" />
                </LoadingButton>
                <Box sx={{ mt: 1, textAlign: "center" }}>
                  <Typography
                    component="span"
                    onClick={handleNextStep}
                    sx={{
                      color: "#000",
                      cursor: "pointer",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <FormattedMessage id="REGISTER_BUSINESS.COMPLETE_PROFILE.COMPLETE_LATER" />
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </FormikForm>
        </Formik>
      </Box>
    </>
  );
};

export default CompleteProfileForm;
