import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { postSearchPosts as postSearchPostsAPI } from "api/posts";
import { postsKeys } from "api/posts/queries";
import {
  SearchTypes,
  SearchTypesCategories,
} from "components/layouts/DefaultLayout/Topbar/Search/useSearchSuggestion";

export const useSearch = (q: string, type: SearchTypes | null) => {
  const [searchType, setSearchType] = useState<SearchTypes>(
    type ?? SearchTypes.EMPLOYES
  );

  const { data: postsData } = useQuery(
    postsKeys.saerch(q, SearchTypesCategories[searchType]),
    async () => {
      const { data: res } = await postSearchPostsAPI(
        q,
        SearchTypesCategories[searchType]
      );
      return res;
    }
  );

  const posts = postsData?.data;

  return {
    posts,
    searchType,
    setSearchType,
  };
};
