import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import { UsersType } from "api/users/types";

import { useFollowers } from "./useFollowers";
import User from "./User";
import UserLoading from "./UserLoading";

const Followers = () => {
  const { userId } = useParams<{ userId: string }>();
  const { followers, isLoading } = useFollowers(userId);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2} lg={3}></Grid>
      <Grid item xs={12} md={8} lg={6}>
        <Paper sx={{ py: 3 }}>
          <Typography variant="h2" sx={{ mx: 3 }}>
            Followers
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Divider sx={{ mx: 3 }} />
            {isLoading
              ? [1, 2, 3].map((el) => <UserLoading key={el} />)
              : followers?.map((follower: UsersType) => (
                  <User key={follower._id} {...follower} />
                ))}
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={2} lg={3}></Grid>
    </Grid>
  );
};

export default Followers;
