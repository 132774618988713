import {
  Description as DescriptionIcon,
  FilePresent as FilePresentIcon,
  People as PeopleIcon,
  Speed as SpeedIcon,
} from "@mui/icons-material";
import { Box, Divider, MenuList, Typography } from "@mui/material";
import React from "react";

import { UserOrgTypeRoleType } from "api/user/types";
import { hasPermissions, hasRole } from "components/stores/UserStore";
import { PermissionsType } from "components/stores/UserStore/permissions";
// import { FormattedMessage } from "react-intl";

// COMPONENTS:
import SidebarMenuDropdown from "./SidebarMenuDropdown";
import SidebarMenuItem, { SidebarMenuItemsProps } from "./SidebarMenuItem";

// ICONS:

export type SidebarMenuListChild = Array<
  SidebarMenuItemsProps & {
    requiredPermissions?: PermissionsType[];
    requiredRole?: UserOrgTypeRoleType[];
    dropdown?: Array<
      Omit<
        SidebarMenuItemsProps & { requiredRole?: UserOrgTypeRoleType[] },
        "icon"
      >
    >;
  }
>;

type SidebarMenuList = Array<{
  id: string;
  text: string | JSX.Element;
  list: SidebarMenuListChild;
}>;

const sidebarMenuListAdmin: SidebarMenuListChild = [
  {
    icon: <SpeedIcon />,
    // text: <FormattedMessage id="" />,
    text: "Dashboard",
    link: "/admin/dashboard",
  },
  {
    icon: <PeopleIcon />,
    // text: <FormattedMessage id="" />,
    text: "Users",
    requiredPermissions: ["read:users"],
    link: "/admin/users",
  },
  {
    icon: <DescriptionIcon />,
    // text: <FormattedMessage id="" />,
    text: "Posts",
    requiredPermissions: ["read:posts"],
    link: "/admin/posts",
  },
  {
    icon: <FilePresentIcon />,
    // text: <FormattedMessage id="" />,
    text: "Storage",
    // requiredPermissions: ["read:posts"],
    link: "/admin/storage",
  },
];

const sidebarMenuList: SidebarMenuList = [
  {
    id: "admin",
    // text: <FormattedMessage id="HEADER.ADMIN" />,
    text: "Admin",
    list: sidebarMenuListAdmin,
  },
];

interface Props {
  onClose?: () => void;
}

const SidebarMenu = ({ onClose }: Props) => {
  return (
    <MenuList
      sx={{
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "calc(100% - 45px)",
        pt: 0,
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {sidebarMenuList.map(({ id, list, text }) => {
        const filteredList = list.filter(
          ({ requiredRole, requiredPermissions }) =>
            hasPermissions(requiredPermissions) && hasRole(requiredRole)
        );

        if (!filteredList.length) {
          return null;
        }

        return (
          <Box key={id}>
            <Typography
              variant="body2"
              sx={{
                ml: 1,
                my: 1.5,
                width: "80%",
                overflowX: "hidden",
                textOverflow: "ellipsis",
                fontWeight: (t) => t.typography.fontWeightBold,
              }}
            >
              {text}
            </Typography>
            {filteredList.map(({ icon, text, link, dropdown }) => {
              if (dropdown) {
                return (
                  <SidebarMenuDropdown
                    key={link}
                    icon={icon}
                    text={text}
                    link={link}
                    list={dropdown}
                    onClick={onClose}
                  />
                );
              }

              return (
                <SidebarMenuItem
                  key={link}
                  icon={icon}
                  text={text}
                  link={link}
                  onClick={onClose}
                />
              );
            })}
            <Divider sx={{ mx: 1 }} />
          </Box>
        );
      })}
    </MenuList>
  );
};

export default SidebarMenu;
