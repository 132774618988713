import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

import { UsersType } from "api/users/types";

interface IUserStats extends UsersType {}

const UserStats: FC<IUserStats> = ({ _id, followers, following }) => {
  return (
    <Paper>
      <Box sx={{ px: 2, py: 1.5 }}>
        <Stack
          component={RouterLink}
          to={`/user/${_id}/followers`}
          direction="row"
          justifyContent="space-between"
          sx={{ textDecoration: "none" }}
        >
          <Typography variant="body2">Followers</Typography>
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {followers.length}
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box sx={{ px: 2, py: 1.5 }}>
        <Stack
          component={RouterLink}
          to={`/user/${_id}/following`}
          direction="row"
          justifyContent="space-between"
          sx={{ textDecoration: "none" }}
        >
          <Typography variant="body2">Following</Typography>
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {following.length}
          </Typography>
        </Stack>
      </Box>
    </Paper>
  );
};

export default UserStats;
