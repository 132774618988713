import { useMutation, useQueryClient } from "@tanstack/react-query";
import { serialize } from "object-to-formdata";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import * as yup from "yup";

import { addPost as addPostAPI } from "api/posts";
import { postsKeys } from "api/posts/queries";
import { NewPostPayloadType } from "api/posts/types";
import { userKeys } from "api/user/queries";
import { usersKeys } from "api/users/queries";
import { useUserStore } from "components/stores/UserStore";
import { useModal } from "utils/hooks/useModal";
import { FileWithPreview } from "utils/media/renderMedia";

// Categories
export enum PostCategoriesType {
  GENERAL_CONVERSATION = "general-conversation",
  EMPLOYEE_INTERACTION = "employee-interaction",
  CUSTOMER_INTERACTION = "customer-interaction",
}

export const PostCategoriesLabels = {
  "general-conversation": "General Conversation",
  "employee-interaction": "Employee Interaction",
  "customer-interaction": "Customer Interaction",
};

// Interactions
export enum PostInteractionsType {
  POSITIVE_INTERACTION = "positive-interaction",
  NEGATIVE_INTERACTION = "negative-interaction",
  NEUTRAL_INTERACTION = "neutral-interaction",
}

export const PostInteractionsLabels = {
  "positive-interaction": "Positive Interaction",
  "neutral-interaction": "Neutral Interaction",
  "negative-interaction": "Negative Interaction",
};

export const useCreatePost = (
  setVariant: Dispatch<SetStateAction<"default" | "new-post">>
) => {
  const queryClient = useQueryClient();
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [location, setLocation] = useState<{
    address: string;
    location: { lat: string; lng: string };
  }>({
    address: "",
    location: { lat: "", lng: "" },
  });

  // Options
  const categoriesOptions = Object.keys(PostCategoriesType).map(
    (categoryKey) => ({
      //@ts-expect-error
      value: PostCategoriesType[categoryKey],
      //@ts-expect-error
      label: PostCategoriesLabels[PostCategoriesType[categoryKey]],
    })
  );
  const interactionsOptions = Object.keys(PostInteractionsType).map(
    (interactionKey) => ({
      //@ts-expect-error
      value: PostInteractionsType[interactionKey],
      //@ts-expect-error
      label: PostInteractionsLabels[PostInteractionsType[interactionKey]],
    })
  );

  const [user] = useUserStore((s) => [s.user]);

  const initialValues = {
    category: null,
    interaction: undefined,
    participant: undefined,
    recommend: undefined,
    content: "",
  };
  const schema = yup.object({
    category: yup.string().nullable().required(),
    content: yup.string().required(),
  });

  const { mutateAsync: addPost } = useMutation(
    async (data: NewPostPayloadType) => {
      const formData: FormData = serialize(data);
      files.forEach((file) => formData.append("files", file));

      const res = await addPostAPI(formData);
      await queryClient.invalidateQueries(postsKeys.list());
      await queryClient.invalidateQueries(postsKeys.categoryCount());
      await queryClient.invalidateQueries(usersKeys.gallery(user?._id ?? ""));
      await queryClient.invalidateQueries(userKeys.me());
      return res.data;
    }
  );

  const handleSubmit = async (values: NewPostPayloadType) => {
    let content = values.content;
    // Find urls
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    content = content.replace(urlRegex, function (url) {
      return '<a href="' + url + '">' + url + "</a>";
    });
    // Split rows
    content = content
      .split("\n")
      .map((paragraph) => `<p>${paragraph}</p>`)
      .join("");

    await addPost(
      { ...values, ...location, content },
      {
        onSuccess: () => setVariant("default"),
      }
    );
  };

  const {
    isOpen: isOpenLocationSearch,
    handleClose: handleCloseLocationSearch,
    handleOpen: handleOpenLocationSearch,
  } = useModal();

  useEffect(() => {
    //@ts-expect-error
    if (user?.address?.location?.lat && user?.address?.location?.lng) {
      setLocation({
        address: `${user.name} - ${user.address.line}`,
        //@ts-expect-error
        location: user.address.location,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isOpenLocationSearch,
    handleCloseLocationSearch,
    handleOpenLocationSearch,
    categoriesOptions,
    interactionsOptions,
    user,
    initialValues,
    schema,
    handleSubmit,
    location,
    setLocation,
    files,
    setFiles,
  };
};
