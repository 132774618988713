import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { PopupState as IPopupState } from "material-ui-popup-state/core";
import React, { Dispatch, FC, Fragment, SetStateAction } from "react";

import {
  PostCategoriesLabels,
  PostCategoriesType,
} from "./CreatePost/useCreatePost";

interface IUserPostFilterPopover {
  popupState: IPopupState;
  setFilterBy: IUserPostFilter["setFilterBy"];
}
const UserPostFilterPopover: FC<IUserPostFilterPopover> = ({
  popupState,
  setFilterBy,
}) => {
  return (
    <Popover
      BackdropProps={{
        invisible: true,
      }}
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        ".MuiPaper-root": {
          boxShadow: "0px 2px 20px 0px #00000020",
        },
      }}
    >
      <List sx={{ py: 0.5 }}>
        <ListItem
          disablePadding
          onClick={() => {
            setFilterBy(null);
            popupState.close();
          }}
          sx={{ minWidth: "100px" }}
        >
          <ListItemButton sx={{ py: 0.5 }}>
            <ListItemText>
              <Typography variant="body2">All</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>

        {Object.values(PostCategoriesType).map((category) => (
          <Fragment key={category}>
            <Divider sx={{ mx: 1 }} />
            <ListItem
              disablePadding
              onClick={() => {
                setFilterBy(category);
                popupState.close();
              }}
              sx={{ minWidth: "100px" }}
            >
              <ListItemButton sx={{ py: 0.5 }}>
                <ListItemText>
                  <Typography variant="body2">
                    {PostCategoriesLabels[category]}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </Fragment>
        ))}
      </List>
    </Popover>
  );
};

interface IUserPostSortPopover {
  popupState: IPopupState;
  setSortBy: IUserPostFilter["setSortBy"];
}
const UserPostSortPopover: FC<IUserPostSortPopover> = ({
  popupState,
  setSortBy,
}) => {
  return (
    <Popover
      BackdropProps={{
        invisible: true,
      }}
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        ".MuiPaper-root": {
          boxShadow: "0px 2px 20px 0px #00000020",
        },
      }}
    >
      <List sx={{ py: 0.5 }}>
        <ListItem
          disablePadding
          onClick={() => {
            setSortBy("recent");
            popupState.close();
          }}
          sx={{ minWidth: "100px" }}
        >
          <ListItemButton sx={{ py: 0.5 }}>
            <ListItemText>
              <Typography variant="body2">Recent</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider sx={{ mx: 1 }} />
        <ListItem
          disablePadding
          onClick={() => {
            setSortBy("oldest");
            popupState.close();
          }}
          sx={{ minWidth: "120px" }}
        >
          <ListItemButton sx={{ py: 0.5 }}>
            <ListItemText>
              <Typography variant="body2">Oldest</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Popover>
  );
};

interface IUserPostFilter {
  sortBy: "recent" | "oldest";
  setSortBy: Dispatch<SetStateAction<"recent" | "oldest">>;
  filterBy: PostCategoriesType | null;
  setFilterBy: Dispatch<SetStateAction<PostCategoriesType | null>>;
}

const sortByLabels = {
  recent: "Recent",
  oldest: "Oldest",
};

const UserPostFilter: FC<IUserPostFilter> = ({
  sortBy,
  setSortBy,
  filterBy,
  setFilterBy,
}) => {
  return (
    <Stack direction="row" alignItems="center">
      <Box sx={{ width: "100%" }}>
        <Divider sx={{ width: "100%" }} />
      </Box>

      <Stack direction="row" alignItems="center" justifyContent="end">
        <PopupState variant="popover" popupId="filter-by-popover">
          {(popupState) => (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ pl: 2, whiteSpace: "nowrap" }}
            >
              <Typography variant="body2" sx={{ mr: 0.5 }}>
                Filter by:
              </Typography>
              <Button sx={{ color: "#000" }} {...bindTrigger(popupState)}>
                <Typography variant="body2" sx={{ color: "#000" }}>
                  {filterBy ? PostCategoriesLabels[filterBy] : "Category"}
                </Typography>
                <ArrowDropDownIcon />
              </Button>

              <UserPostFilterPopover
                popupState={popupState}
                setFilterBy={setFilterBy}
              />
            </Stack>
          )}
        </PopupState>

        <PopupState variant="popover" popupId="sort-by-popover">
          {(popupState) => (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ pl: 2, whiteSpace: "nowrap" }}
            >
              <Typography variant="body2" sx={{ mr: 0.5 }}>
                Sort by:
              </Typography>
              <Button sx={{ color: "#000" }} {...bindTrigger(popupState)}>
                <Typography variant="body2" sx={{ color: "#000" }}>
                  {sortByLabels[sortBy]}
                </Typography>
                <ArrowDropDownIcon />
              </Button>

              <UserPostSortPopover
                popupState={popupState}
                setSortBy={setSortBy}
              />
            </Stack>
          )}
        </PopupState>
      </Stack>
    </Stack>
  );
};

export default UserPostFilter;
