import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import {
  CommentsType,
  NewCommentPayloadType,
  NewReplyPayloadType,
} from "./types";

export const getComments = (
  queryParams?: QueryParamsType<CommentsType>
): APICall<Paginated<CommentsType[]>> =>
  request({
    url: `/comment${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const addComment = (comment: NewCommentPayloadType): APICall<null> =>
  request({
    data: comment,
    url: `/comment`,
    method: "POST",
  });

export const addCommentReply = (
  commentId: string,
  data: NewReplyPayloadType
): APICall<null> =>
  request({
    data: data,
    url: `/comment/${commentId}/reply`,
    method: "POST",
  });

export const deleteComment = (commentId: string): APICall<null> =>
  request({
    url: `/comment/${commentId}`,
    method: "DELETE",
  });
