import { OutlinedInput as Input, Paper, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Formik, Form as FormikForm, useField } from "formik";
import React, { FC } from "react";
import * as yup from "yup";

import { addComment as addCommentAPI } from "api/comments";
import { NewCommentPayloadType } from "api/comments/types";
import { postsKeys } from "api/posts/queries";
import { useUserStore } from "components/stores/UserStore";
import UserAvatar from "components/UserAvatar";

const WriteCommentInputField = () => {
  const [field] = useField<unknown>("content");
  return (
    <Input
      placeholder="Be first to write a comment"
      sx={{
        width: "100%",
        backgroundColor: "#EEE",
        border: "none",
        borderRadius: 2,
        "&.Mui-focused": {
          boxShadow: "none",
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
        ".MuiInputBase-input": {
          border: "none",
          py: "10px",
        },
      }}
      {...field}
    />
  );
};

interface IWriteComment {
  postId: string;
}

const WriteComment: FC<IWriteComment> = ({ postId }) => {
  const queryClient = useQueryClient();
  const initialValues = {
    content: "",
  };
  const schema = yup.object({
    content: yup.string().required(),
  });

  const [user] = useUserStore((s) => [s.user]);
  const { mutateAsync: addComment } = useMutation(
    async (data: NewCommentPayloadType) => {
      const res = await addCommentAPI(data);
      await queryClient.invalidateQueries(postsKeys.all);
      return res.data;
    }
  );

  const handleSubmit = async (
    values: { content: string },
    { resetForm }: any
  ) => {
    await addComment(
      { post: postId, ...values },
      {
        onSuccess: () => resetForm(),
      }
    );
  };

  return (
    <Paper sx={{ backgroundColor: "#D4DAE3" }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ py: 1, px: 2 }}
        spacing={2}
      >
        <UserAvatar
          name={user?.name ?? ""}
          size={40}
          sx={{
            textAlign: "center",
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          <FormikForm style={{ width: "100%" }}>
            <WriteCommentInputField />
          </FormikForm>
        </Formik>
      </Stack>
    </Paper>
  );
};

export default WriteComment;
