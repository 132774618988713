import { Paginated } from "api/utils";

import request, { APICall } from "../request";
import { AttachmentsType } from "./types";

export const getStorage = (): APICall<Paginated<AttachmentsType[]>> =>
  request({
    url: `/storage`,
    method: "GET",
  });
