import {
  AccountCircle as AccountCircleIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Dashboard as DashboardIcon,
  Logout as LogoutIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  SvgIconTypeMap,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useQueryClient } from "@tanstack/react-query";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { UserOrgTypeRoleType } from "api/user/types";
import { hasRole, useUserStore } from "components/stores/UserStore";
import UserAvatar from "components/UserAvatar";

import UserDropdownItem from "./UserDropdownItem";

type UserDropdownLinks = Array<{
  title: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  link: string;
  hasRole?: UserOrgTypeRoleType | UserOrgTypeRoleType[];
  isAdminPageLink?: boolean;
}>;

const userDropdownLinks = (userId: string): UserDropdownLinks => [
  {
    title: "My Profile",
    icon: AccountCircleIcon,
    link: `/user/${userId}`,
  },
  {
    title: "Settings",
    icon: SettingsIcon,
    link: `/user/${userId}/settings`,
  },
];

const adminDropdownLinks = (userId: string): UserDropdownLinks => [
  {
    title: "Back to Dashboard",
    icon: DashboardIcon,
    link: "/",
    hasRole: "admin",
    isAdminPageLink: true,
  },
  {
    title: "Admin",
    icon: AdminPanelSettingsIcon,
    link: "/admin/dashboard",
    hasRole: "admin",
    isAdminPageLink: false,
  },
];

interface IUserDropdown {
  dark?: boolean;
}

const UserDropdown: FC<IUserDropdown> = ({ dark }) => {
  const [user] = useUserStore((s) => [s.user]);
  const history = useHistory();
  const queryClient = useQueryClient();

  const handleLogout = async () => {
    localStorage.removeItem("JWToken");
    history.push("/login");
    await queryClient.removeQueries();
  };

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md")
  );

  const userLinks = userDropdownLinks(user?._id ?? "").filter((link) =>
    link.hasRole ? hasRole(link.hasRole) : true
  );
  const adminLinks = adminDropdownLinks(user?._id ?? "").filter((link) =>
    link.hasRole ? hasRole(link.hasRole) : true
  );

  return (
    <PopupState variant="popover" popupId="language-switcher-popover">
      {(popupState) => (
        <Box>
          <Button
            sx={{
              boxShadow: "none",
              textTransform: "none",
              pl: 2,
              py: 1,
              ":hover": {
                backgroundColor: "#0000000a",
              },
            }}
            data-cy="user-drawer-button"
            {...bindTrigger(popupState)}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              {!isMobile && (
                <Typography
                  variant="body2"
                  sx={{
                    color: dark ? "#373636" : "#fff",
                    whiteSpace: "nowrap",
                  }}
                >
                  {user?.name}
                </Typography>
              )}
              <UserAvatar
                name={user?.name ?? ""}
                size={40}
                sx={{
                  border: "1px solid #fff",
                  backgroundColor: (theme) => theme.palette.primary.main,
                }}
              />
              <ArrowDropDownIcon sx={{ color: dark ? "#373636" : "#fff" }} />
            </Stack>
          </Button>
          <Popover
            BackdropProps={{
              invisible: true,
            }}
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              ".MuiPaper-root": {
                boxShadow: "0px 2px 20px 0px #00000020",
              },
            }}
          >
            <List sx={{ py: 0.5, width: "250px" }}>
              {userLinks.map((link) => (
                <UserDropdownItem
                  key={link.title}
                  popupState={popupState}
                  {...link}
                />
              ))}
              {userLinks.length ? <Divider sx={{ my: 0.5, mx: 1 }} /> : null}

              {adminLinks.map((link) => (
                <UserDropdownItem
                  key={link.title}
                  popupState={popupState}
                  {...link}
                />
              ))}
              {adminLinks.length ? <Divider sx={{ my: 0.5, mx: 1 }} /> : null}

              <ListItem disablePadding onClick={handleLogout}>
                <ListItemButton>
                  <ListItemIcon
                    sx={{
                      minWidth: "40px",
                      color: (t) => t.palette.error.main,
                    }}
                  >
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        color: (t) => t.palette.error.main,
                      }}
                    >
                      Logout
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Popover>
        </Box>
      )}
    </PopupState>
  );
};

export default UserDropdown;
