import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { getPosts } from "api/posts";
import { postsKeys } from "api/posts/queries";
import { PostsType } from "api/posts/types";
import { getSingleUser } from "api/users";
import { usersKeys } from "api/users/queries";
import { QueryParamsType } from "api/utils";
import { useUserStore } from "components/stores/UserStore";

export const useUser = (userId: string) => {
  const [me] = useUserStore((s) => [s.user]);

  const isMyProfile = useMemo(() => {
    return me?._id === userId;
  }, [me, userId]);

  const { data: usersData, isLoading } = useQuery(
    usersKeys.details(userId),
    async () => {
      const { data: res } = await getSingleUser(userId);
      return res;
    }
  );

  const postsFilter: QueryParamsType<PostsType> = {
    filter: [
      {
        field: "user",
        operator: "is",
        value: userId,
      },
    ],
    sort: {
      field: "createdAt",
      order: "desc",
    },
  };

  const { data: postsData } = useQuery(
    postsKeys.listFiltered(postsFilter),
    async () => {
      const { data: res } = await getPosts(postsFilter);
      return res;
    }
  );

  return {
    isMyProfile,
    user: usersData?.data,
    posts: postsData?.data,
    isLoading,
  };
};
