import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { Box, IconButton, Link, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import PopupState, { bindTrigger } from "material-ui-popup-state";
import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

import { CommentsType } from "api/comments/types";
import UserAvatar from "components/UserAvatar";

import CommentPopoverOwner from "./components/CommentPopoverOwner";
import { useComment } from "./useComment";

dayjs.extend(relativeTime);

interface ICommentReply extends CommentsType {}

const CommentReply: FC<ICommentReply> = (comment) => {
  const { _id, user: commentUser, content, createdAt } = comment;
  const { isMyComment } = useComment(comment);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="end"
      sx={{ position: "relative", mt: 1 }}
    >
      <Box
        component={RouterLink}
        to={`/user/${commentUser?._id}`}
        sx={{ textDecoration: "none" }}
      >
        <UserAvatar
          name={commentUser.name}
          size={42}
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: "calc(90% - 15px)",
            width: "42px",
            height: "42px",
            textAlign: "center",
            mx: "auto",
            border: "2px solid #fff",
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
          variant="circular"
        />
      </Box>
      <Box
        sx={{
          width: "90%",
          backgroundColor: isMyComment ? "#EBEFF6" : "#EEEEEE",

          pl: 3,
          pr: 2,
          py: 1.5,
          borderRadius: 2,
        }}
      >
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <Box>
            <Link
              component={RouterLink}
              to={`/user/${commentUser?._id}`}
              variant="body2"
              sx={{
                color: "#373636",
                fontWeight: "bold",
                mb: 0.5,
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {commentUser.name}
            </Link>
            <Typography variant="body2">{content}</Typography>
          </Box>
          <PopupState variant="popover" popupId="sort-by-popover">
            {(popupState) => (
              <Box sx={{ ml: 3 }}>
                <IconButton
                  sx={{ backgroundColor: "#DDDBDB" }}
                  {...bindTrigger(popupState)}
                >
                  <MoreHorizIcon sx={{ color: "#A9A9A9", fontSize: "18px" }} />
                </IconButton>
                {isMyComment && (
                  <CommentPopoverOwner postId={_id} popupState={popupState} />
                )}
              </Box>
            )}
          </PopupState>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box></Box>
          <Box>
            <Typography variant="body2">
              {dayjs(createdAt).fromNow()}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};

export default CommentReply;
