import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";

import Notification from "./Notification";
import NotificationLoading from "./NotificationLoading";
import { useNotifications } from "./useNotifications";

const Notifications = () => {
  const { notifications, isLoading } = useNotifications();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2} lg={3}></Grid>
      <Grid item xs={12} md={8} lg={6}>
        <Paper sx={{ py: 3 }}>
          <Typography variant="h2" sx={{ mx: 3 }}>
            Notifications
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Divider sx={{ mx: 3 }} />
            {isLoading
              ? [1, 2, 3].map((item) => <NotificationLoading key={item} />)
              : notifications?.map((notification) => (
                  <Notification key={notification._id} {...notification} />
                ))}
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={2} lg={3}></Grid>
    </Grid>
  );
};

export default Notifications;
