import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import React, { FC } from "react";

import VerificationCodeField from "components/forms/VerificationCodeField";

interface IEmailCodeModal {
  email: string;
  open: boolean;
  handleClose: () => void;
  isResendLoading: boolean;
  resend: () => void;
  onSubmit: (code: string) => any;
}

const EmailCodeModal: FC<IEmailCodeModal> = ({
  email,
  open,
  handleClose,
  isResendLoading,
  resend,
  onSubmit,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogContent>
        <Typography sx={{ textAlign: "center", mb: 0.5 }} fontWeight="bold">
          {email}
        </Typography>
        <Typography sx={{ textAlign: "center", mb: 3 }}>
          We've sent a 4 digit verification code to your email address, please
          enter that into the input below and press the enter key
        </Typography>
        <Box
          textAlign="center"
          sx={{ ".verification-code": { margin: "0 auto" } }}
        >
          <VerificationCodeField onComplete={(val) => onSubmit(val)} />
        </Box>

        <Box textAlign="center" sx={{ mt: 3 }}>
          <LoadingButton
            loading={isResendLoading}
            variant="contained"
            onClick={resend}
          >
            Resend
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EmailCodeModal;
