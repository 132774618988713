import { Warning as WarningIcon } from "@mui/icons-material";
import {
  FormHelperText,
  OutlinedInput as Input,
  InputAdornment,
  InputProps,
  Typography,
} from "@mui/material";
import { useField } from "formik";
import React, { ReactNode } from "react";

import { MakeRequired } from "utils/types";

import FieldErrorFeedbackFormatter from "../FieldErrorFeedbackFormatter";

type Props = MakeRequired<InputProps, "name"> & {
  labelTitle?: ReactNode | string;
  helperText?: ReactNode | string;
};

const FormikInputField = ({
  name,
  labelTitle,
  helperText = null,
  ...rest
}: Props) => {
  const [field, meta] = useField<unknown>(name);

  return (
    <>
      {labelTitle && <Typography sx={{ mb: 1.5 }}>{labelTitle}</Typography>}
      <Input
        fullWidth
        endAdornment={
          meta.touched &&
          !!meta.error && (
            <InputAdornment position="end">
              <WarningIcon
                sx={{ color: (theme) => theme.palette.error.main }}
              />
            </InputAdornment>
          )
        }
        error={meta.touched && !!meta.error}
        {...field}
        {...rest}
      />

      {meta.touched && meta.error ? (
        <Typography
          variant="body2"
          sx={{
            mt: 0.5,
            mb: 2,
            color: (theme) => theme.palette.error.main,
          }}
        >
          <FieldErrorFeedbackFormatter error={meta.error} />
        </Typography>
      ) : (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </>
  );
};

export default FormikInputField;
