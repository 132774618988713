import { Box, Paper, Stack, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React, { FC } from "react";

interface IAdminDashboardStats {
  title: string;
  total: number;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
}

const AdminDashboardStats: FC<IAdminDashboardStats> = ({
  title,
  total,
  icon: Icon,
}) => {
  return (
    <Paper
      sx={{
        position: "relative",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundSize: "cover",
        backgroundImage: "url('/media/login-info-bg.png')",
        borderRadius: 1,
        border: "none",
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ py: 2 }}
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: "18px", color: "#fff" }}
        >
          {total}
        </Typography>
        <Typography sx={{ fontSize: "16px", color: "#fff" }}>
          {title}
        </Typography>
      </Stack>
      <Box
        sx={{
          position: "absolute",
          transform: "rotate(20deg)",
          bottom: "-20px",
          left: "-20px",
        }}
      >
        <Icon sx={{ color: "#fff", fontSize: "90px", opacity: 0.8 }} />
      </Box>
    </Paper>
  );
};

export default AdminDashboardStats;
