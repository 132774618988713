import {
  AppBar,
  Box,
  Container,
  Divider,
  LinearProgress,
  Stack,
  Toolbar,
} from "@mui/material";
import { useIsFetching } from "@tanstack/react-query";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

// COMPONENTS:
import Notifications from "./Notifications/Notifications";
import Search from "./Search/Search";
import UserDropdown from "./UserDropdown";

interface TopbarProps {
  isMobile: boolean;
}

const Topbar = ({ isMobile }: TopbarProps) => {
  const isFetching = useIsFetching();

  return (
    <AppBar
      color="inherit"
      position={isMobile ? "fixed" : "sticky"}
      elevation={0}
      sx={{
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        backgroundColor: (theme) => theme.palette.primary.main,
        overflow: "visible",
      }}
    >
      <Container>
        <Toolbar disableGutters>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Stack direction="row" alignItems="center" width="100%">
              <Stack
                component={RouterLink}
                to="/"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  textDecoration: "none",
                  color: "#FFF",
                  fontWeight: "bold",
                  mr: 4,
                }}
              >
                KARENAPP
              </Stack>
              <Search />
            </Stack>

            <Stack direction="row" alignItems="center">
              <Notifications />

              <Divider
                orientation="vertical"
                sx={{ height: "34px", backgroundColor: "#fff", mx: 1 }}
              />
              <Box>
                <UserDropdown />
              </Box>
            </Stack>
          </Stack>
        </Toolbar>
      </Container>
      {isFetching ? (
        <LinearProgress color="primary" />
      ) : (
        <Box height="4px" sx={{ background: "#f5f5f5" }} />
      )}
    </AppBar>
  );
};

export default Topbar;
