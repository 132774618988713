import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { bindPopover } from "material-ui-popup-state";
import { PopupState as IPopupState } from "material-ui-popup-state/core";
import React, { FC } from "react";

import { deleteComment as deleteCommentAPI } from "api/comments";
import { postsKeys } from "api/posts/queries";

interface ICommentPopoverOwner {
  postId: string;
  popupState: IPopupState;
}

const CommentPopoverOwner: FC<ICommentPopoverOwner> = ({
  postId,
  popupState,
}) => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteComment } = useMutation(async (data: string) => {
    const res = await deleteCommentAPI(data);
    await queryClient.invalidateQueries(postsKeys.list());
    return res.data;
  });

  const handleDeleteComment = async () => {
    await deleteComment(postId, {
      onSuccess: () => popupState.close(),
    });
  };

  return (
    <Popover
      BackdropProps={{
        invisible: true,
      }}
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        ".MuiPaper-root": {
          boxShadow: "0px 2px 20px 0px #00000020",
        },
      }}
    >
      <List sx={{ py: 0.5 }}>
        <ListItem
          disablePadding
          onClick={() => {
            // setSortBy("recent");
            popupState.close();
          }}
          sx={{ minWidth: "80px" }}
        >
          <ListItemButton sx={{ py: 0.5 }} onClick={handleDeleteComment}>
            <ListItemText>
              <Typography variant="body2">Delete</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Popover>
  );
};

export default CommentPopoverOwner;
