import { createBrowserHistory } from "history";
import React from "react";
import { Redirect, Switch } from "react-router-dom";

// ROUTES:

// ADMIN:
import AdminRoute from "components/routes/AdminRoute";
import AdminDashboardPage from "containers/admin-dashboard";
import AdminPostsPage from "containers/admin-posts";
import AdminStoragePage from "containers/admin-storage";
import AdminUsersPage from "containers/admin-users";
// PRIVATE:
import DashboardPage from "containers/dashboard";
import FollowersPage from "containers/followers";
import FollowingPage from "containers/following";
import ForgotPasswordPage from "containers/forgot-password";
import LoginPage from "containers/login";
import NotificationsPage from "containers/notifications";
import PinnedPostsPage from "containers/pinned-posts";
import RecentSubmissionsPage from "containers/recent-submissions";
import RegisterBusinessPage from "containers/register-business";
// import RegisterParticipantPage from "containers/register-participant";
// import RegisterPage from "containers/register";
import SearchPage from "containers/search";
import UserPage from "containers/user";
import UserSettingsPage from "containers/user/settings";
import CompanySettings from "containers/user/settings/CompanySettings";
import NotificationSettings from "containers/user/settings/NotificationSettings";
import PrivacySettings from "containers/user/settings/PrivacySettings";

import AuthRoute from "./components/routes/AuthRoute";
import PrivateRoute from "./components/routes/PrivateRoute";
import Providers from "./Providers";

export const history = createBrowserHistory();

export default function App() {
  return (
    <Providers history={history}>
      <Switch>
        {/* Auth Routes */}
        <AuthRoute exact component={LoginPage} path="/login" />
        <AuthRoute
          exact
          component={ForgotPasswordPage}
          path="/forgot-password"
        />
        {/* <AuthRoute exact component={RegisterPage} path="/register" /> */}
        {/* <AuthRoute
          exact
          component={RegisterParticipantPage}
          path="/register/participant"
        /> */}
        <AuthRoute exact component={RegisterBusinessPage} path="/register" />

        {/* Private Routes */}
        <PrivateRoute exact path="/" component={DashboardPage} />
        <PrivateRoute exact path="/search" component={SearchPage} />
        <PrivateRoute exact path="/user/:userId" component={UserPage} />
        <PrivateRoute
          exact
          path="/user/:userId/settings"
          component={UserSettingsPage}
        />
        <PrivateRoute
          exact
          path="/user/:userId/settings/privacy-settings"
          component={PrivacySettings}
        />
        <PrivateRoute
          exact
          path="/user/:userId/settings/notification-settings"
          component={NotificationSettings}
        />
        <PrivateRoute
          exact
          path="/user/:userId/settings/company-settings"
          component={CompanySettings}
        />
        <PrivateRoute
          exact
          path="/user/:userId/followers"
          component={FollowersPage}
        />
        <PrivateRoute
          exact
          path="/user/:userId/following"
          component={FollowingPage}
        />
        <PrivateRoute
          exact
          path="/notifications"
          component={NotificationsPage}
        />
        <PrivateRoute exact path="/pinned-posts" component={PinnedPostsPage} />
        <PrivateRoute
          exact
          path="/recent-submissions"
          component={RecentSubmissionsPage}
        />

        {/* Admin Routes */}
        <AdminRoute
          exact
          path="/admin/dashboard"
          component={AdminDashboardPage}
        />
        <AdminRoute exact path="/admin/users" component={AdminUsersPage} />
        <AdminRoute exact path="/admin/posts" component={AdminPostsPage} />
        <AdminRoute exact path="/admin/storage" component={AdminStoragePage} />

        <Redirect to="/login" />
      </Switch>
    </Providers>
  );
}
