import { Paper } from "@mui/material";
import React from "react";

import PostsHeader from "./PostsHeader/PostsHeader";
import PostsTable from "./PostsTable";

const AdminPosts = () => {
  return (
    <Paper>
      <PostsHeader />
      <PostsTable />
    </Paper>
  );
};

export default AdminPosts;
