import { GpsFixed } from "@mui/icons-material";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import React, { FC } from "react";

interface ILocationBadge extends LoadingButtonProps {
  label: string;
}
export const LocationBadge: FC<ILocationBadge> = ({ label, ...rest }) => {
  return (
    <LoadingButton sx={{ borderRadius: "20px" }} {...rest}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        sx={{
          boxShadow: "0px 0px 6px 0px #00000040",
          px: "4px",
          py: "3px",
          borderRadius: "20px",
          cursor: "pointer",
          backgroundColor: "#fff",
          minWidth: "50px",
          height: "23px",
        }}
      >
        {!rest.loading && (
          <>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", fontWeight: 600, ml: 1.5, mr: 1 }}
            >
              {label}
            </Typography>
            <GpsFixed sx={{ color: "#828282", fontSize: "20px" }} />
          </>
        )}
      </Stack>
    </LoadingButton>
  );
};

interface IStateBadge extends LoadingButtonProps {
  label: string;
}
export const StateBadge: FC<IStateBadge> = ({ label, ...rest }) => {
  return (
    <LoadingButton sx={{ borderRadius: "20px" }} {...rest}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        sx={{
          boxShadow: "0px 0px 6px 0px #00000040",
          px: 2,
          py: "3px",
          borderRadius: "20px",
          cursor: "pointer",
          background: (theme) => theme.palette.primary.main,
          minWidth: "50px",
          height: "23px",
        }}
      >
        {!rest.loading && (
          <Typography
            variant="body2"
            sx={{
              fontSize: "12px",
              fontWeight: 600,
              color: "#fff",
            }}
          >
            {label}
          </Typography>
        )}
      </Stack>
    </LoadingButton>
  );
};

interface IRemoveBadge extends LoadingButtonProps {}
export const RemoveBadge: FC<IRemoveBadge> = ({ ...rest }) => {
  return (
    <LoadingButton sx={{ borderRadius: "20px" }} {...rest}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        sx={{
          boxShadow: "0px 0px 6px 0px #00000040",
          px: 2,
          py: "3px",
          borderRadius: "20px",
          cursor: "pointer",
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: "12px",
            fontWeight: 600,
            color: (theme) => theme.palette.error.main,
          }}
        >
          Remove
        </Typography>
      </Stack>
    </LoadingButton>
  );
};
