import { Paper } from "@mui/material";
import React from "react";

import UsersHeader from "./UsersHeader/UsersHeader";
import UsersTable from "./UsersTable";

const AdminUsers = () => {
  return (
    <Paper>
      <UsersHeader />
      <UsersTable />
    </Paper>
  );
};

export default AdminUsers;
