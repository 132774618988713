import { Box, Container, Theme, useMediaQuery } from "@mui/material";
import React from "react";

import ScrollTopProvider from "components/providers/ScrollTopProvider";
// LOGIC:
import { WithChildren } from "utils/types";

// COMPONENTS:
import Topbar from "./Topbar";

interface DefaultLayoutProps {
  showBreadcrumbs: boolean;
}

const DefaultLayout = ({ children }: WithChildren<DefaultLayoutProps>) => {
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <ScrollTopProvider>
      <Box
        display="grid"
        gridTemplateAreas="
        'sidebar topbar' 
        'sidebar content'
      "
        gridTemplateColumns="min-content 1fr"
        gridTemplateRows="min-content 1fr"
        height="100vh"
        width="100vw"
      >
        <Box gridArea="topbar">
          <Topbar isMobile={isMobile} />
        </Box>

        <Box
          gridArea="content"
          minHeight="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          sx={{ overflow: "auto", paddingTop: isMobile ? "56px" : 0 }}
          className="content"
        >
          <Box
            component="main"
            sx={{
              position: "relative",
              flexGrow: 1,
              py: isMobile ? 4 : 2,
              backgroundColor: "#f5f5f5",
            }}
          >
            <Container>{children}</Container>
          </Box>
        </Box>
      </Box>
    </ScrollTopProvider>
  );
};

export default DefaultLayout;
