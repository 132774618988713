import { Notifications as NotificationsIcon } from "@mui/icons-material";
import { Badge, IconButton } from "@mui/material";
// ICONS:
import PopupState, { bindTrigger } from "material-ui-popup-state";
import React from "react";

import NotificationsPopover from "./NotificationsPopover";
import { useNotifications } from "./useNotifications";

const Notifications = () => {
  const { notifications, notificationsCount } = useNotifications();

  return (
    <PopupState variant="popover" popupId="notifications-popover">
      {(popupState) => (
        <>
          <IconButton
            aria-label="notifications"
            sx={{
              mr: 2,
              borderColor: "transparent",
            }}
            {...bindTrigger(popupState)}
          >
            <Badge badgeContent={notificationsCount} color="error">
              <NotificationsIcon sx={{ color: "#fff" }} />
            </Badge>
          </IconButton>
          <NotificationsPopover
            popupState={popupState}
            notifications={notifications}
          />
        </>
      )}
    </PopupState>
  );
};

export default Notifications;
