const usersKeys = {
  all: ["users"] as const,
  list: () => [...usersKeys.all, "list"],
  listFiltered: (filters: Record<string, unknown>) =>
    [...usersKeys.all, "list", filters] as const,
  details: (id: string) => [...usersKeys.all, "detail", id] as const,
  followers: (id: string) => [...usersKeys.all, "followers", id] as const,
  following: (id: string) => [...usersKeys.all, "following", id] as const,
  gallery: (id: string) => [...usersKeys.all, "gallery", id] as const,
  statistics: () => [...usersKeys.all, "statistics"],
};

export { usersKeys };
