import { Warning as WarningIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

const confirmationFormatter = (isConfirmed: boolean) => {
  return (
    <Stack direction="row">
      {!isConfirmed && (
        <WarningIcon fontSize="small" color="warning" sx={{ mr: 0.5 }} />
      )}
      <Typography variant="body2" component="span" sx={{ color: "#000" }}>
        {isConfirmed ? (
          <FormattedMessage id="GLOBAL.YES" />
        ) : (
          <FormattedMessage id="GLOBAL.NO" />
        )}
      </Typography>
    </Stack>
  );
};

export { confirmationFormatter };
