import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import { bindPopover } from "material-ui-popup-state";
import { PopupState as IPopupState } from "material-ui-popup-state/core";
import React, { FC, useState } from "react";

import { SearchLocationType } from "./Search";
import { LocationBadge, RemoveBadge } from "./SearchBadges";

interface ISearchLocationPopover {
  anchorEl: Element;
  popupState: IPopupState;
  searchLocation: SearchLocationType;
  resetSearchLocation: () => void;
  getMyLocation: () => Promise<void>;
  getDifferentLocation: () => void;
  setSearchRadius: (raidus: number) => void;
}
const SearchLocationPopover: FC<ISearchLocationPopover> = ({
  anchorEl,
  popupState,
  searchLocation,
  resetSearchLocation,
  getMyLocation,
  getDifferentLocation,
  setSearchRadius,
}) => {
  const [loading, setLoading] = useState<
    "my-location" | "different-location" | null
  >(null);

  const handleGetMyLocation = async () => {
    setLoading("my-location");
    if (searchLocation.type === "my-location") {
      resetSearchLocation();
    } else {
      await getMyLocation();
    }
    setLoading(null);
  };

  const handleGetDifferentLocation = () => {
    setLoading("different-location");
    if (searchLocation.type === "different-location") {
      resetSearchLocation();
    } else {
      getDifferentLocation();
    }
    setLoading(null);
  };

  return (
    <Popover
      BackdropProps={{
        invisible: true,
      }}
      {...bindPopover(popupState)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        mt: "3px",
        ".MuiPaper-root": {
          boxShadow: "0px 0px 6px 0px #00000040",
        },
      }}
      disableEnforceFocus
    >
      <List sx={{ p: 0, width: "400px" }}>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ height: "48px" }}
            onClick={handleGetMyLocation}
            disabled={
              !!searchLocation.type && searchLocation.type !== "my-location"
            }
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box>
                <Typography sx={{ fontSize: "13px" }}>
                  Use my current location
                </Typography>
                {searchLocation.type === "my-location" && (
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#2F80ED",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "block",
                      maxWidth: "250px",
                    }}
                  >
                    {searchLocation.location}
                  </Typography>
                )}
              </Box>
              {searchLocation.type === "my-location" ? (
                <RemoveBadge
                  loading={loading === "my-location"}
                  disableRipple
                />
              ) : (
                <LocationBadge
                  loading={loading === "my-location"}
                  label="Find Me"
                  disableRipple
                />
              )}
            </Stack>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            sx={{ height: "48px" }}
            onClick={handleGetDifferentLocation}
            disabled={
              !!searchLocation.type &&
              searchLocation.type !== "different-location"
            }
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box>
                <Typography sx={{ fontSize: "13px" }}>
                  Use different location
                </Typography>
                {searchLocation.type === "different-location" && (
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#2F80ED",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "250px",
                    }}
                  >
                    {searchLocation.location}
                  </Typography>
                )}
              </Box>

              {searchLocation.type === "different-location" ? (
                <RemoveBadge
                  loading={loading === "different-location"}
                  disableRipple
                />
              ) : (
                <LocationBadge
                  loading={loading === "different-location"}
                  label="Use Google Maps"
                  disableRipple
                />
              )}
            </Stack>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding disabled={!searchLocation.type}>
          <ListItemButton sx={{ height: "48px" }} disableRipple>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box>
                <Typography sx={{ fontSize: "13px" }}>
                  Set search radius
                </Typography>
                {!!searchLocation.type && (
                  <Typography sx={{ fontSize: "13px", color: "#2F80ED" }}>
                    {searchLocation.radius} Miles
                  </Typography>
                )}
              </Box>

              <Slider
                value={searchLocation.radius}
                min={10}
                max={50}
                valueLabelDisplay="auto"
                step={5}
                marks
                sx={{
                  maxWidth: "50%",
                  "& .MuiSlider-thumb": {
                    border: "2px solid #fff",
                  },
                  "& .MuiSlider-track": {
                    height: "1.5px",
                  },
                }}
                onChange={(event, value) => {
                  setSearchRadius(value as number);
                }}
                disabled={!searchLocation.type}
              />
            </Stack>
          </ListItemButton>
        </ListItem>
      </List>
    </Popover>
  );
};

export default SearchLocationPopover;
