import { Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  OutlinedInput as Input,
  InputAdornment,
  InputProps,
} from "@mui/material";
import {
  Form as FormikForm,
  FormikProvider,
  useField,
  useFormik,
} from "formik";
import PopupState, { bindTrigger } from "material-ui-popup-state";
import React, { FC, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import SearchLocationModal from "../SearchLocationModal";
import { LocationBadge, StateBadge } from "./SearchBadges";
import SearchLocationPopover from "./SearchLocationPopover";
import SearchSuggestionPopover from "./SearchSuggestionPopover";
import { useSearchLocation } from "./useSearchLocation";

interface ISearchInputField extends InputProps {}
const SearchInputField: FC<ISearchInputField> = ({ ...rest }) => {
  const [field] = useField<string>("search");

  return (
    <Input
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon sx={{ color: "#BDBDBD", fontSize: "20px" }} />
        </InputAdornment>
      }
      placeholder="Search"
      sx={{
        width: { sx: "100%", md: "400px" },
        "&.Mui-focused": {
          boxShadow: "none",
          backgroundColor: "#eff3fa",
        },
        ".MuiOutlinedInput-notchedOutline": {
          display: "none",
        },
        ".MuiInputBase-input": {
          py: "10px",
        },
      }}
      autoComplete="off"
      {...field}
      {...rest}
    />
  );
};

export type SearchLocationType = {
  type: null | "my-location" | "different-location";
  location: null | string;
  radius: number;
};
const Search: FC = () => {
  const history = useHistory();
  const [searchFocus, setSearchFocus] = useState<boolean>(false);
  const containerRef = useRef<HTMLElement>(null);
  const {
    searchLocation,
    setSearchLocation,
    resetSearchLocation,
    getMyLocation,
    getDifferentLocation,
    setSearchRadius,
    isOpenLocationSearch,
    handleCloseLocationSearch,
  } = useSearchLocation();

  const formik = useFormik({
    enableReinitialize: true,
    onSubmit: (values) => {
      handleCloseLocationSearch();
      history.push(`/search?q=${values.search}`);
    },
    initialValues: {
      search: "",
    },
  });

  const { values } = formik;
  return (
    <>
      <SearchLocationModal
        open={isOpenLocationSearch}
        handleClose={handleCloseLocationSearch}
        setLocation={(point) => {
          setSearchLocation((p) => ({
            type: "different-location",
            location: point.address,
            radius: p.radius,
          }));
        }}
      />

      <PopupState variant="popover" popupId="search-location-popover">
        {(popupState) => (
          <Box sx={{ display: { xs: "none", md: "block" } }} ref={containerRef}>
            <FormikProvider value={formik}>
              <FormikForm>
                <SearchInputField
                  id="search"
                  onFocus={() => setSearchFocus(true)}
                  onBlur={() => setSearchFocus(false)}
                  endAdornment={
                    <InputAdornment position="end">
                      {popupState.isOpen ? (
                        <StateBadge label="Save" />
                      ) : (
                        <LocationBadge
                          label={
                            searchLocation.type
                              ? `${searchLocation.radius} Miles`
                              : "Location"
                          }
                          {...bindTrigger(popupState)}
                        />
                      )}
                    </InputAdornment>
                  }
                />
              </FormikForm>
            </FormikProvider>

            <SearchSuggestionPopover
              q={values.search}
              display={Boolean(!popupState.isOpen && searchFocus)}
            />
            <SearchLocationPopover
              anchorEl={containerRef.current as Element}
              popupState={popupState}
              searchLocation={searchLocation}
              resetSearchLocation={resetSearchLocation}
              getMyLocation={getMyLocation}
              getDifferentLocation={getDifferentLocation}
              setSearchRadius={setSearchRadius}
            />
          </Box>
        )}
      </PopupState>
    </>
  );
};

export default Search;
