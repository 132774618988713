import { LoadingButton } from "@mui/lab";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React, { FC, MouseEvent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import { axiosInstance } from "api/request";
import { postRegister, postRegisterVerification } from "api/user";
import {
  RegisterPayloadType,
  RegisterVerificationPayloadType,
} from "api/user/types";
import FormikCheckboxField from "components/forms/FormikCheckboxField";
import FormikInputField from "components/forms/FormikInputField";
import FormikSelectField from "components/forms/FormikSelectField";
import EmailCodeModal from "containers/register-participant/components/EmailCodeModal";
import TermsConditionsModal from "containers/register-participant/components/TermsConditionsModal";
import BackButton from "containers/register/components/BackButton";
import { useModal } from "utils/hooks/useModal";

interface IRegisterBusinessForm {
  handleNextStep: () => void;
}
const RegisterBusinessForm: FC<IRegisterBusinessForm> = ({
  handleNextStep,
}) => {
  const history = useHistory();

  const initialValues = {
    role: "business",
    companyAdministrator: "",
    industry: null,
    companyType: null,
    name: "",
    email: "",
    password: "",
    termsAndConditions: "",
  };
  const schema = yup.object({
    companyAdministrator: yup.string().required(),
    industry: yup.string().nullable(),
    companyType: yup.string().nullable(),
    name: yup.string().required(),
    email: yup.string().required().email(),
    password: yup.string().required().min(4),
    termsAndConditions: yup.string().required(),
  });

  const intl = useIntl();

  const { mutateAsync: registerVerification, isLoading } = useMutation(
    async (values: RegisterVerificationPayloadType) => {
      await postRegisterVerification(values);
    }
  );

  const handleSubmitVerification = async (
    values: RegisterVerificationPayloadType
  ) => {
    await registerVerification(values, {
      onSuccess: () => handleOpenCodeModal(),
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: handleSubmitVerification,
  });
  const { values: formikValues } = formik;

  const { mutateAsync: register } = useMutation(
    async (values: RegisterPayloadType) => {
      const { data: registerData } = await postRegister(values);

      localStorage.setItem("JWToken_business", registerData?.token);
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${registerData?.token}`;
    }
  );

  const handleSubmit = async (code: string) => {
    await register(
      { ...formikValues, code },
      {
        onSuccess: () => handleNextStep(),
      }
    );
  };

  const {
    isOpen: isOpenCodeModal,
    handleClose: handleCloseCodeModal,
    handleOpen: handleOpenCodeModal,
  } = useModal();
  const {
    isOpen: isOpenTermsConditionsModal,
    handleClose: handleCloseTermsConditionsModal,
    handleOpen: handleOpenTermsConditionsModal,
  } = useModal();

  return (
    <>
      <EmailCodeModal
        email={formikValues.email}
        open={isOpenCodeModal}
        handleClose={handleCloseCodeModal}
        onSubmit={handleSubmit}
        isResendLoading={isLoading}
        resend={() => handleSubmitVerification(formikValues)}
      />
      <TermsConditionsModal
        open={isOpenTermsConditionsModal}
        handleClose={handleCloseTermsConditionsModal}
      />

      <Box sx={{ maxWidth: "680px", width: "100%", mb: 4 }}>
        <BackButton onClick={() => history.push("/login")} />
      </Box>
      <Box
        sx={{
          maxWidth: "420px",
          width: "100%",
          minHeight: "600px",
        }}
      >
        <FormikProvider value={formik}>
          <FormikForm data-cy="login-form" style={{ height: "100%" }}>
            <Stack
              direction="column"
              justifyContent="space-between"
              sx={{ height: "100%" }}
            >
              <Box>
                <Box>
                  <Typography variant="h1" sx={{ fontSize: "30px", mb: 2 }}>
                    <FormattedMessage id="REGISTER_BUSINESS.BUSINESS_FORM.TITLE" />
                  </Typography>
                  <Typography sx={{ fontSize: "18px" }}>
                    <FormattedMessage id="REGISTER_BUSINESS.BUSINESS_FORM.DESCRIPTION" />
                  </Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box>
                  <Box sx={{ mb: 1.5 }}>
                    <FormikInputField
                      name="companyAdministrator"
                      labelTitle={
                        <FormattedMessage id="REGISTER_BUSINESS.BUSINESS_FORM.FULLNAME" />
                      }
                      placeholder={intl.formatMessage({
                        id: "REGISTER_BUSINESS.BUSINESS_FORM.FULLNAME.PLACEHOLDER",
                      })}
                    />
                  </Box>
                  <Box sx={{ mb: 1.5 }}>
                    <FormikInputField
                      name="name"
                      labelTitle={
                        <FormattedMessage id="REGISTER_BUSINESS.BUSINESS_FORM.COMPANY_NAME" />
                      }
                      placeholder={intl.formatMessage({
                        id: "REGISTER_BUSINESS.BUSINESS_FORM.COMPANY_NAME.PLACEHOLDER",
                      })}
                    />
                  </Box>
                  <Box sx={{ mb: 1.5 }}>
                    <FormikSelectField
                      name="industry"
                      labelTitle={
                        <FormattedMessage id="REGISTER_BUSINESS.BUSINESS_FORM.INDUSTRY" />
                      }
                      options={[
                        { value: "aerospace", label: "Aerospace" },
                        { value: "agriculture", label: "Agriculture" },
                        { value: "computer", label: "Computer" },
                        { value: "construction", label: "Construction" },
                        { value: "education", label: "Education" },
                        { value: "electronics", label: "Electronics" },
                        { value: "energy", label: "Energy" },
                        { value: "entertainment", label: "Entertainment" },
                        { value: "food", label: "Food" },
                        { value: "healthcare", label: "Healthcare" },
                        { value: "hospitality", label: "Hospitality" },
                        { value: "manufacturing", label: "Manufacturing" },
                        { value: "mining", label: "Mining" },
                        { value: "music", label: "Music" },
                        { value: "news-media", label: "News Media" },
                        { value: "pharmaceutical", label: "Pharmaceutical" },
                        {
                          value: "telecommunication",
                          label: "Telecommunication",
                        },
                        { value: "transport", label: "Transport" },
                        { value: "www", label: "World Wide Web" },
                      ]}
                      placeholder={intl.formatMessage({
                        id: "REGISTER_BUSINESS.BUSINESS_FORM.INDUSTRY.PLACEHOLDER",
                      })}
                    />
                  </Box>
                  <Box sx={{ mb: 1.5 }}>
                    <FormikSelectField
                      name="companyType"
                      labelTitle={
                        <FormattedMessage id="REGISTER_BUSINESS.BUSINESS_FORM.COMPANY_TYPE" />
                      }
                      options={[
                        { value: "retail", label: "Retail" },
                        { value: "service", label: "Service" },
                        { value: "manufacturing", label: "Manufacturing" },
                        { value: "wholesale", label: "Wholesale" },
                      ]}
                      placeholder={intl.formatMessage({
                        id: "REGISTER_BUSINESS.BUSINESS_FORM.COMPANY_TYPE.PLACEHOLDER",
                      })}
                    />
                  </Box>
                  <Box sx={{ mb: 1.5 }}>
                    <FormikInputField
                      name="email"
                      labelTitle={
                        <FormattedMessage id="REGISTER_BUSINESS.BUSINESS_FORM.EMAIL" />
                      }
                      placeholder={intl.formatMessage({
                        id: "REGISTER_BUSINESS.BUSINESS_FORM.EMAIL.PLACEHOLDER",
                      })}
                    />
                  </Box>
                  <Box sx={{ mb: 1.5 }}>
                    <FormikInputField
                      name="password"
                      type="password"
                      labelTitle={
                        <FormattedMessage id="REGISTER_BUSINESS.BUSINESS_FORM.PASSWORD" />
                      }
                      placeholder={intl.formatMessage({
                        id: "REGISTER_BUSINESS.BUSINESS_FORM.PASSWORD.PLACEHOLDER",
                      })}
                    />
                  </Box>
                  <FormikCheckboxField
                    name="termsAndConditions"
                    label={
                      <Typography>
                        I agree to{" "}
                        <Typography
                          component="span"
                          sx={{
                            color: "#202020",
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                          onClick={(e: MouseEvent<HTMLElement>) => {
                            e.preventDefault();
                            handleOpenTermsConditionsModal();
                          }}
                        >
                          terms & conditions
                        </Typography>
                      </Typography>
                    }
                  />
                </Box>
              </Box>
              <Box>
                <LoadingButton
                  sx={{ mt: 4.5, py: 2 }}
                  type="submit"
                  variant="contained"
                  fullWidth
                  loading={isLoading}
                >
                  <FormattedMessage id="REGISTER_BUSINESS.BUSINESS_FORM.BUTTON" />
                </LoadingButton>
              </Box>
            </Stack>
          </FormikForm>
        </FormikProvider>
      </Box>
    </>
  );
};

export default RegisterBusinessForm;
