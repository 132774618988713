import { Done as DoneIcon } from "@mui/icons-material";
import { Box, Step, StepIconProps, StepLabel, Stepper } from "@mui/material";
import React, { FC } from "react";

interface IRegisterStepper {
  activeStep: number;
}

const steps = [
  {
    label: "General Information",
  },
  {
    label: "Residency Information",
  },
  {
    label: "Subscription plan",
  },
  {
    label: "Payment",
  },
];

const StepIcon: FC<StepIconProps> = ({ active, completed, className }) => {
  return (
    <Box
      className={className}
      sx={{
        width: "16px",
        height: "16px",
        borderRadius: "50%",
        backgroundColor: active ? "#FFF" : "#A1EC9F",
        visibility: !active && !completed ? "hidden" : "visible",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {completed && <DoneIcon sx={{ color: "#2C74DC", fontSize: "14px" }} />}
    </Box>
  );
};

const RegisterStepper: FC<IRegisterStepper> = ({ activeStep }) => {
  return (
    <Stepper activeStep={activeStep} orientation="vertical" connector={<></>}>
      {steps.map((step) => (
        <Step
          key={step.label}
          sx={{
            ".MuiStepIcon-root.Mui-active": {
              color: "#A1EC9F",
            },
            ".MuiStepIcon-root": {
              color: "#FFF",
            },
          }}
        >
          <StepLabel
            sx={{
              ".MuiStepLabel-label": {
                pl: 1.5,
                color: "#fff",
                fontSize: "20px",
              },
              ".MuiStepLabel-label.Mui-completed": {
                color: "#fff",
                fontSize: "20px",
              },
              ".MuiStepLabel-label.Mui-active": {
                color: "#fff",
                fontSize: "24px",
                fontWeight: "bold",
              },
            }}
            StepIconComponent={StepIcon}
          >
            {step.label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default RegisterStepper;
