import request from "api/request";
import { APICall } from "api/utils";

export const postStripeCheckout = (
  plan: "monthly" | "yearly"
): APICall<{ data: { url: string } }> =>
  request({
    data: {
      type: plan,
    },
    url: `/stripe/checkout`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWToken_business")}`,
    },
  });
