import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import React, { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";

interface ISettingsTab {
  activeTab:
    | "general-settings"
    | "privacy-settings"
    | "notification-settings"
    | "company-settings";
  userId: string;
  isBusinessUser?: boolean;
}

const SettingsTabs: FC<ISettingsTab> = ({
  activeTab,
  userId,
  isBusinessUser,
}) => {
  const history = useHistory();

  const settingTabs = useMemo(() => {
    if (isBusinessUser) {
      return [
        { value: "general-settings", label: "General Settings" },
        { value: "company-settings", label: "Company Settings" },
        { value: "privacy-settings", label: "Privacy Settings" },
        { value: "notification-settings", label: "Notification Settings" },
      ];
    } else {
      return [
        { value: "general-settings", label: "General Settings" },
        { value: "privacy-settings", label: "Privacy Settings" },
        { value: "notification-settings", label: "Notification Settings" },
      ];
    }
  }, [isBusinessUser]);

  return (
    <Paper>
      <Stack direction="column" divider={<Divider />}>
        {settingTabs.map((settingTab) => {
          const active = settingTab.value === activeTab;
          return (
            <Box
              sx={{
                p: 0.5,
                cursor: "pointer",
                justifyContent: "flex-start",
              }}
              onClick={() =>
                history.push(
                  `/user/${userId}/settings/${
                    settingTab.value !== "general-settings"
                      ? settingTab.value
                      : ""
                  }`
                )
              }
            >
              <Button sx={{ width: "100%", justifyContent: "flex-start" }}>
                <Typography
                  variant="body2"
                  fontWeight={active ? "bold" : "normal"}
                  sx={{ color: active ? "black" : "#696F79", p: 0.5 }}
                >
                  {settingTab.label}
                </Typography>
              </Button>
            </Box>
          );
        })}
      </Stack>
    </Paper>
  );
};

export default SettingsTabs;
