import { Box, ButtonBase, Divider, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { FC } from "react";

import { NotificationsType } from "api/notifications/types";
import { generateComment } from "components/layouts/DefaultLayout/Topbar/Notifications/generateComment";
import UserAvatar from "components/UserAvatar";
dayjs.extend(relativeTime);

const Notification: FC<NotificationsType> = (notification) => {
  const { target, opened, createdAt } = notification;

  return (
    <Box width="100%">
      <Stack
        component={ButtonBase}
        direction="row"
        alignItems="start"
        sx={{
          px: 3,
          pt: 2,
          pb: 1.5,
          textAlign: "left",
          backgroundColor: opened ? "#FFF" : "#E7F3FE",
        }}
        spacing={1}
        width="100%"
        justifyContent="flex-start"
      >
        <UserAvatar
          name={target.name}
          size={40}
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            mr: 1,
          }}
        />
        <Box>
          <Typography sx={{ fontSize: "16px", lineHeight: 1.2, mb: 0.5 }}>
            {generateComment(notification)}
          </Typography>
          <Typography
            sx={{
              lineHeight: 1.2,
            }}
            variant="body2"
          >
            {dayjs(createdAt).fromNow()}
          </Typography>
        </Box>
      </Stack>
      <Divider sx={{ mx: 3 }} />
    </Box>
  );
};

export default Notification;
