import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import { getPosts } from "api/posts";
import { postsKeys } from "api/posts/queries";
import { PostsType } from "api/posts/types";
import { QueryParamsType } from "api/utils";

export const useRecentSubmissions = () => {
  const postFilter: QueryParamsType<PostsType> = {
    filter: [
      {
        field: "createdAt",
        operator: "gt",
        value: `${dayjs().subtract(1, "week").toDate()}`,
      },
    ],
  };

  const { data: useRecentSubmissionsDatta, isLoading } = useQuery(
    postsKeys.listFiltered(postFilter),
    async () => {
      const { data: res } = await getPosts(postFilter);
      return res;
    }
  );

  return {
    recentSubmissions: useRecentSubmissionsDatta?.data,
    isLoading,
  };
};
