import { FormHelperText, TextFieldProps, Typography } from "@mui/material";
import { useField } from "formik";
import { MuiTelInput } from "mui-tel-input";
import React, { ReactNode } from "react";

import FieldErrorFeedbackFormatter from "components/forms/FieldErrorFeedbackFormatter";
import { MakeRequired } from "utils/types";

type Props = MakeRequired<TextFieldProps, "name"> & {
  labelTitle?: ReactNode | string;
};

export interface FormikPhoneValue {
  dialCode: string;
  number: string;
}

const FormikPhoneField = ({ name, helperText, labelTitle, ...rest }: Props) => {
  const [field, meta, helpers] = useField<string | FormikPhoneValue>(name);

  const { value } = field;
  const { setValue } = helpers;

  return (
    <>
      {labelTitle && <Typography sx={{ mb: 1.5 }}>{labelTitle}</Typography>}
      <MuiTelInput
        variant="outlined"
        fullWidth
        error={meta.touched && !!meta.error}
        {...field}
        {...rest}
        value={
          typeof value === "object"
            ? `+${value.dialCode}${value.number}`
            : value
        }
        onChange={(number, data) => {
          const noSpaceNumber = number.replace(/\s/g, "");

          if (typeof value === "object") {
            const noPlusNumber = number.replace("+", "");

            setValue({
              dialCode: data.countryCallingCode ?? "",
              number: noPlusNumber.slice(data.countryCallingCode?.length) ?? "",
            });
          } else setValue(noSpaceNumber);
        }}
        sx={{
          my: 0,
          ".MuiOutlinedInput-input": {
            padding: "16.5px 14px",
          },
        }}
      />

      {meta.touched && meta.error ? (
        <Typography
          variant="body2"
          sx={{
            mt: 0.5,
            mb: 2,
            color: (theme) => theme.palette.error.main,
          }}
        >
          <FieldErrorFeedbackFormatter error={meta.error} />
        </Typography>
      ) : (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </>
  );
};

export default FormikPhoneField;
